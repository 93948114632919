import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Alert, Box, Button, Card, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import axiosClient from 'src/axios_client'
import PageContainer from 'src/components/container/PageContainer'
import CustomFormLabel from 'src/components/theme-elements/CustomFormLabel'
import CustomTextField from 'src/components/theme-elements/CustomTextField'
import { LogoDark, LogoLight } from 'src/globals/LogoExports'
import * as yup from 'yup';

type Props = {}

const validationSchema = yup.object({
    password: yup
        .string()
        .required('New Password is required'),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Repeat Password is required'),
});
function PasswordReset({ }: Props) {

    const [sucess, setSucess] = useState('');
    const [error, setError] = useState('');
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        validationSchema,
        onSubmit: async (values) => {
            try {
                const payload = {
                    email: searchParams.get('email'),
                    token: searchParams.get('token'),
                    password: values.password,
                    password_confirmation: values.confirmPassword,
                };

                const response = await axiosClient.post(`/proceed_reset`, payload);


                setSucess(response.data.message);

                setTimeout(() => {
                    navigate('/');
                }, 2000)
                // setStatusCode(response.status);
                // refetch();
                // onClose();
            } catch (error: any) {
                setError(error.response.data.message);

                setTimeout(() => {
                    setError('');
                }, 2000)

                // if (error.response && error.response.status === 422) {
                //     setStatusData(error.response.data);
                // }
                // setStatusCode(error.response?.status || 500);
            }
        },
    });
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <PageContainer title="Password Reset" description="this is Forgot Password page">
            <Box
                sx={{
                    position: 'relative',
                    '&:before': {
                        content: '""',
                        background: 'radial-gradient(#d2f1df, #d3d7fa, #bad8f4)',
                        backgroundSize: '400% 400%',
                        animation: 'gradient 15s ease infinite',
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        opacity: '0.3',
                    },
                }}
            >
                <Grid container spacing={0} justifyContent="center" sx={{ height: '100vh' }}>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        lg={4}
                        xl={3}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Card elevation={9} sx={{ p: 4, zIndex: 1, width: '100%', maxWidth: '500px' }}>
                            <Box display="flex" alignItems="center" justifyContent="center" sx={{ marginBottom: 2 }}>
                                <LogoDark height={'auto'} />
                            </Box>
                            <Typography
                                color="textSecondary"
                                textAlign="center"
                                variant="subtitle2"
                                fontWeight="400"
                            >
                                Please enter your new password.
                            </Typography>
                            <form onSubmit={formik.handleSubmit}>
                                <Stack mt={0} spacing={1}>
                                    {sucess && (
                                        <Alert variant="filled" severity="success" sx={{ alignItems: 'center', mb: 1 }}>
                                            {sucess}
                                        </Alert>
                                    )}

                                    {error && (
                                        <Alert variant="filled" severity="error" sx={{ alignItems: 'center', mb: 1 }}>
                                            {error}
                                        </Alert>
                                    )}

                                    <CustomFormLabel htmlFor="reset-email">New Password</CustomFormLabel>
                                    <CustomTextField
                                        id="password"
                                        // type="password"
                                        variant={'outlined'}
                                        fullWidth size={'small'}
                                        type={showPassword ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {' '}
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        value={formik.values.password}
                                        error={formik.touched.password && Boolean(formik.errors.password)}
                                        helperText={formik.touched.password && formik.errors.password}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <CustomFormLabel htmlFor="reset-email">Confirm Password</CustomFormLabel>
                                    <CustomTextField
                                        id="confirmPassword"
                                        type={showPassword ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {' '}
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant={'outlined'}
                                        fullWidth size={'small'}
                                        value={formik.values.confirmPassword}
                                        error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />

                                    <Button color="primary" variant="contained" size="small" fullWidth type='submit'>
                                        Change Password
                                    </Button>
                                    <Button color="primary" size="small" fullWidth component={Link} to="/auth/login">
                                        Back to Login
                                    </Button>
                                </Stack>
                            </form>

                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </PageContainer>
    )
}

export default PasswordReset