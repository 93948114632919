import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import {
  MaterialReactTable,
  MRT_Row,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from 'material-react-table';
import { Tooltip, IconButton, Box, Button } from '@mui/material';
import { useSelector } from 'src/store/Store';
import { AppState } from 'src/store/Store';
import {
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';
import axiosClient from 'src/axios_client';
import { Download, DownloadOutlined, Email, PlayArrow, PrintSharp } from '@mui/icons-material';
import { useReactToPrint } from 'react-to-print';
import 'src/styles/reports_print.css';
import {
  Table,
  Typography as TypographyAnt,
  Space,
  Image,
  Button as ButtonAnt,
  Tooltip as TooltipAnt,
  ConfigProvider,
  Row,
  Col,
} from 'antd';
import DateSelector from 'src/components/shared/AntDateSelector';
import dayjs, { Dayjs } from 'dayjs';
import useFormattedNumber from 'src/hooks/useFormattedNumber';
import Link from 'antd/es/typography/Link';
import { useNavigate } from 'react-router';
import { useTransaction } from 'src/contexts/PurchaseTransactionContext';
import BrandLogo from 'src/assets/images/svgs/rkc_logo.svg';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/en'; // Import the English locale
import LogoDark from 'src/assets/images/logos/dark-logo.svg';
import LogoLight from 'src/assets/images/logos/light-logo.svg';
import * as XLSX from 'xlsx';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { LoadingButton } from '@mui/lab';
import brandImage from 'src/assets/images/brand/rkc.png';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Manila');

const { Title, Text } = TypographyAnt;
type SelectedProductPurchaseApiResponse = {
  product_details: {
    id: number;
    name: string;
  };
  data: Array<SelectedProductPurchase>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

type SelectedProductPurchase = {
  id: number;
  name: string;
  code: string;
  product_price: string;
  product_total_amount: string;
  reference_number: any;
  invoice_number: any;
  date_of_purchase: any;
  quantity_purchased: any;
};

type SelectedProductPurchasesTableProps = {
  supplyCenterId: number;
  productId: number;
  dateRange: any;
};

const SelectedProductPurchasesTable: React.FC<SelectedProductPurchasesTableProps> = ({
  supplyCenterId,
  productId,
  dateRange,
}) => {
  const [exporting, setExporting] = useState(false);
  const customizer = useSelector((state: AppState) => state.customizer);
  const currentTheme = customizer.activeMode;

  const [isDarkMode, setIsDarkMode] = useState(false);

  const { formatWithPrefix } = useFormattedNumber();

  const normalizedDateRange = useMemo(() => {
    return dateRange.map((date: any) => {
      return dayjs.isDayjs(date) ? date.toISOString() : date;
    }) as [string, string];
  }, [dateRange]);

  useEffect(() => {
    setIsDarkMode(currentTheme === 'dark');
  }, [currentTheme]);

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });

  const {
    data: { data = [], pagination: meta, product_details } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<SelectedProductPurchaseApiResponse>({
    queryKey: [
      'selected-product-purchase',
      productId,
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
      dateRange,
    ],
    queryFn: async () => {
      if (!productId || !dateRange[0] || !dateRange[1]) {
        throw new Error('Product ID and date range are required');
      }

      const params = {
        page: `${pagination.pageIndex + 1}`,
        per_page: `${pagination.pageSize}`,
        filters: JSON.stringify(columnFilters ?? []),
        globalFilter: globalFilter ?? '',
        sorting: JSON.stringify(sorting ?? []),
        start_date: normalizedDateRange[0],
        end_date: normalizedDateRange[1],
        supply_center: supplyCenterId,
      };

      try {
        const response = await axiosClient.get<SelectedProductPurchaseApiResponse>(
          `/product-purchase-report/${productId}`, // Relative URL path with productId in the URL
          { params }, // Pass query parameters as 'params'
        );

        return response.data;
      } catch (error) {
        console.error('Error fetching selected product purchase report:', error);
        throw error;
      }
    },

    placeholderData: keepPreviousData,
    enabled: !!productId && !!dateRange[0] && !!dateRange[1], // Only run the query if productId and dateRange are available
  });

  const navigate = useNavigate();
  const { setTransactionId } = useTransaction();

  const handleViewPurchaseEntryTransaction = (row: any) => {
    setTransactionId(row.id);
    navigate('/purchases-entry-view-transaction');
  };

  const productName = product_details?.name || '';

  const columns = useMemo<MRT_ColumnDef<SelectedProductPurchase>[]>(
    () => [
      {
        accessorKey: 'reference_number',
        header: 'Reference #',
        size: 180,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Link onClick={() => handleViewPurchaseEntryTransaction(row.original)}>
              {renderedCellValue}
            </Link>
          );
        },
      },
      {
        accessorKey: 'invoice_number',
        header: 'Invoice #',
        size: 50,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'date_of_purchase',
        header: 'Purchase Date',
        size: 50,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'quantity_purchased',
        header: 'Quantity Purchased',
        grow: false,
        size: 50,
        Cell: ({ renderedCellValue, row }) => {
          return <span>{renderedCellValue}</span>;
        },
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => row.product_price,
        id: 'product_price',
        header: 'Product Price',
        size: 100,
        Cell: ({ renderedCellValue, row }) => {
          const value = typeof renderedCellValue === 'number' ? renderedCellValue : 0;
          return <span>{formatWithPrefix(value)}</span>;
        },
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => row.product_total_amount,
        id: 'product_total_amount',
        header: 'Product Total Amount',
        size: 100,
        Cell: ({ renderedCellValue, row }) => {
          const value = typeof renderedCellValue === 'number' ? renderedCellValue : 0;
          return <span>{formatWithPrefix(value)}</span>;
        },
        enableColumnFilter: false,
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: { showColumnFilters: true, density: 'compact' },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableColumnOrdering: true,
    enableDensityToggle: false,
    enableRowSelection: true,
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
        {/* <DateSelector onDateChange={setDateRange} />

        <Tooltip arrow title="Extract Report">
          <Button
            onClick={handleRunReport}
            startIcon={
              <>
                <PlayArrow />
              </>
            }
          >
            Run Report
          </Button>
        </Tooltip> */}

        <Button onClick={handleExportData} startIcon={<DownloadOutlined />}>
          Export All Data
        </Button>

        <Button
          disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
          //only export selected rows

          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<DownloadOutlined />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
    rowCount: meta?.total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  const TEMPLATE_PATH = '/assets/etc/DocFormat.xlsx';

  const handleExportData = async () => {
    setExporting(true);
    try {
      const totalRows = meta?.total ?? 0;
      const pageSize = pagination.pageSize;
      const totalPages = Math.ceil(totalRows / pageSize);
      const allData: SelectedProductPurchase[] = [];

      // Fetch paginated data
      for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
        const response = await axiosClient.get<SelectedProductPurchaseApiResponse>(
          `/product-purchase-report/${productId}`,
          {
            params: {
              start_date: normalizedDateRange[0],
              end_date: normalizedDateRange[1],
              supply_center: supplyCenterId,
            },
          },
        );
        allData.push(...response.data.data);
      }

      // Reuse handleExportRows logic for data export
      await handleExportRows(
        allData.map((data) => ({ original: data } as MRT_Row<SelectedProductPurchase>)),
      );
    } catch (error) {
      console.error('Error exporting data with ExcelJS:', error);
      alert('Failed to export data. Please try again.');
    } finally {
      setExporting(false);
    }
  };

  const handleExportRows = async (rows: MRT_Row<SelectedProductPurchase>[]) => {
    setExporting(true);
    try {
      // Load the template file
      const workbook = new ExcelJS.Workbook();
      const response = await fetch(TEMPLATE_PATH);
      const arrayBuffer = await response.arrayBuffer();
      await workbook.xlsx.load(arrayBuffer);

      // Access the first worksheet in the template
      const worksheet = workbook.getWorksheet(1);
      if (!worksheet) {
        throw new Error('Worksheet not found in the template file.');
      }

      // Adjust column widths
      worksheet.columns = [
        { width: 20, key: 'reference_number' },
        { width: 15, key: 'invoice_number' },
        { width: 35, key: 'date_of_purchase' },
        { width: 20, key: 'quantity_purchased' },
        { width: 20, key: 'product_price' },
        { width: 20, key: 'product_total_amount' },
      ];

      // Set title
      const titleCell = worksheet.getCell('F5');
      titleCell.value = `Product Purchase Details - ${productName}`;
      titleCell.font = { bold: true, size: 14 };
      titleCell.font = { bold: true, size: 14 };

      // Add today's date dynamically
      const dateCell = worksheet.getCell('F6');
      const today = new Date();
      const formattedDate = today.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
      dateCell.value = formattedDate;
      dateCell.font = { italic: true, size: 12 };

      const rangeCell = worksheet.getCell('H6');

      // Function to format dates as "Month XX, XXXX"
      const formatDate = (isoString: any) => {
        const date = new Date(isoString);
        return date.toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        });
      };

      // Format the date range
      const startDate = formatDate(normalizedDateRange[0]);
      const endDate = formatDate(normalizedDateRange[1]);

      // Update the cell value with the formatted dates
      rangeCell.value = `Purchases from ${startDate} to ${endDate}`;
      rangeCell.font = { italic: true, size: 12 };

      // Define header and data start rows
      const headerRowIndex = 7;
      const dataStartRowIndex = 8;

      // Insert headers
      const headerRow = worksheet.getRow(headerRowIndex);
      headerRow.values = [
        'Reference #',
        'Invoice #',
        'Date of Purchase',
        'Quantity Purchased',
        'Product Price',
        'Total Amount',
      ];
      headerRow.font = { bold: true };

      // Fetch the imported image for branding
      const imageResponse = await fetch(brandImage);
      const imageBuffer = await imageResponse.arrayBuffer();
      const imageId = workbook.addImage({
        buffer: imageBuffer,
        extension: 'png',
      });
      worksheet.addImage(imageId, {
        tl: {
          col: 0,
          row: 0,
          colOff: (10 * 9600) / 96,
          rowOff: (10 * 9600) / 96,
        } as any,
        ext: { width: 175, height: 40 },
        editAs: 'oneCell',
      });

      // Add data rows
      rows.forEach((row, index) => {
        const data = row.original;
        const currentRow = worksheet.getRow(dataStartRowIndex + index);

        currentRow.getCell(1).value = data.reference_number;
        currentRow.getCell(2).value = data.invoice_number;
        currentRow.getCell(3).value = data.date_of_purchase;
        currentRow.getCell(4).value = data.quantity_purchased;
        currentRow.getCell(5).value = data.product_price;
        currentRow.getCell(6).value = data.product_total_amount;

        currentRow.commit();
      });

      // Save the modified Excel file
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, `${productName}_PurchasesReport.xlsx`);
    } catch (error) {
      console.error('Error exporting rows with ExcelJS:', error);
      alert('Failed to export data. Please try again.');
    } finally {
      setExporting(false);
    }
  };

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
};

const queryClient = new QueryClient();

const SelectedProductPurchaseView: React.FC<SelectedProductPurchasesTableProps> = ({
  supplyCenterId,
  productId,
  dateRange,
}) => (
  <QueryClientProvider client={queryClient}>
    <SelectedProductPurchasesTable supplyCenterId={supplyCenterId} productId={productId} dateRange={dateRange} />
  </QueryClientProvider>
);

export default SelectedProductPurchaseView;
