import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { useAuth } from './AuthProvider';
import useServer from 'src/utils/useServer';
import Echo from 'laravel-echo';

interface UserData {
  id: number;
  name: string;
  email: string;
  username: string;
  userlevel: string;
}

interface EssentialDataContextType {
  userData: UserData | null;
}

interface Props {
  children: ReactNode;
}

const EssentialDataContext = createContext<EssentialDataContextType | undefined>(undefined);

export const useEssentialDataContext = () => {
  const context = useContext(EssentialDataContext);
  if (!context) {
    throw new Error('useEssentialDataContext must be used within a EssentialDataProvider');
  }

  return context;
};

const EssentialDataProvider: React.FC<Props> = ({ children }: Props) => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      const localUserData = localStorage.getItem('userDetails');

      setUserData(localUserData ? JSON.parse(localUserData) : null);

      
    }
  }, [isAuthenticated]);

  return (
    <EssentialDataContext.Provider value={{ userData }}>{children}</EssentialDataContext.Provider>
  );
};

export { EssentialDataProvider };
