import React, { useState } from 'react';
import { Alert, Box, Button, Grid, TextField } from '@mui/material';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from '../../components/shared/DashboardCard';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axiosClient from 'src/axios_client';
import { Navigate, useNavigate } from 'react-router';

const BCrumb = [
    {
        to: '/',
        title: 'Home',
    },
    {
        title: 'SupplyCenters Page',
    },
];
const validationSchema = yup.object({
    currentPassword: yup.string().required('Current Password is required'),
    password: yup
        .string()
        .required('New Password is required')
        .notOneOf([yup.ref('currentPassword')], 'New Password must be different from the Current Password'),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Repeat Password is required'),
});
const ChangePassword = () => {
    const [sucess, setSucess] = useState('');
    const [error, setError] = useState('');

    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            password: '',
            currentPassword: '',
            confirmPassword: '',
        },
        validationSchema,
        onSubmit: async (values) => {

            console.log(values);
            try {
                const payload = {
                    current_password: values.currentPassword,
                    password: values.password,
                };

                const response = await axiosClient.post(`/change_password`, payload);

                setSucess(response.data.message);

                setTimeout(() => {
                    navigate('/');
                }, 2000)
                // setStatusCode(response.status);
                // refetch();
                // onClose();
            } catch (error: any) {
                setError(error.response.data.message);
                console.log(error);
                // if (error.response && error.response.status === 422) {
                //     setStatusData(error.response.data);
                // }
                // setStatusCode(error.response?.status || 500);
            }
        },
    });
    return (
        <PageContainer title="RKCWMS - Supply Centers Page" description="this is Sample page">
            {/* breadcrumb */}
            {/* <Breadcrumb title="Supply Centers Page" items={BCrumb} /> */}
            {/* end breadcrumb */}

            <Box sx={{ mt: 3 }}>
                {sucess && (
                    <Alert variant="filled" severity="success" sx={{ alignItems: 'center', mb: 1 }}>
                        {sucess}
                    </Alert>
                )}

                {error && (
                    <Alert variant="filled" severity="error" sx={{ alignItems: 'center', mb: 1 }}>
                        {error}
                    </Alert>
                )}
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={1.5} sx={{ alignContent: 'space-evenly' }}>
                                    Current Password
                                </Grid>
                                <Grid item xs={3} >
                                    <TextField
                                        size='small'
                                        id="currentPassword"
                                        label="Current Password"
                                        fullWidth
                                        variant="outlined"
                                        value={formik.values.currentPassword}
                                        error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
                                        helperText={formik.touched.currentPassword && formik.errors.currentPassword}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        sx={{
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#0000005e !important'
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={1.5} sx={{ alignContent: 'space-evenly' }}>
                                    New Password
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        size='small'
                                        fullWidth
                                        id="password"
                                        label="New Password"
                                        variant="outlined"
                                        value={formik.values.password}
                                        error={formik.touched.password && Boolean(formik.errors.password)}
                                        helperText={formik.touched.password && formik.errors.password}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        sx={{
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#0000005e !important'
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={1.5} sx={{ alignContent: 'space-evenly' }}>
                                    Repeat New Password
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        size='small'
                                        fullWidth
                                        id="confirmPassword"
                                        label="Repeat New Password"
                                        variant="outlined"
                                        value={formik.values.confirmPassword}
                                        error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        sx={{
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#0000005e !important'
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={1.5}>

                            <Button variant='contained' size='small' type='submit'>
                                Change Password
                            </Button>
                        </Grid>


                    </Grid>
                </form>

            </Box>
        </PageContainer>
    );
};

export default ChangePassword;
