import React, { FC, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Box, Button } from '@mui/material';
import axiosClient from 'src/axios_client';
import dayjs from 'dayjs';
import Invoice from './Invoice'; // Adjust the import path
import { Input, Popconfirm } from 'antd';
import { InvoiceData } from './types';

interface DataType {
  key: string;
  id: number;
  name: string;
  sku: any;
  description: string;
  unit: string;
  quantity: number;
  price: number;
  amount: number;
  serial_numbers: { id: number; serial_number: string }[];
}

interface Transaction {
  id: number;
  issuance_id: string;
  reference_number: string;
  date_of_return: string;
  slip_number: string;
  checked_by: {
    id: number | null;
    name: string | null;
  };
  checked_note: string | null;
  received_at: string | null;
  received_by: {
    id: number | null;
    name: string | null;
  };
  received_note: string | null;
  created_by: {
    id: number;
    name: string;
  };
  created_at: string;
  checked_at: string;
  customer: {
    id: number;
    code: string;
    name: string;
    address: string;
    contact_person: string;
  };
  total_amount: number;
  total_lost_amount: any;
  transactions: {
    stocks: {
      id: number;
      transaction_id: number;
      product_id: number;
      product_name: string;
      product_sku: any;
      product_unit: string;
      product_description: string;
      quantity: number;
      price: number;
    }[];
    serial_numbers: {
      id: number;
      product_id: number;
      product_sku: any;
      product_name: string;
      product_unit: string;
      product_description: string;
      serial_number: string;
      price: number;
    }[];
    initial_location: any;
  };
  hauler: any;
}

interface IssuanceReturnEntryFormStaticProps {
  transactionId: number | null;
  userlevel: any;
}

const IssuanceReturnEntryFormStatic = forwardRef<
  { fetchData: () => void },
  IssuanceReturnEntryFormStaticProps
>(({ transactionId, userlevel }, ref) => {
  const [transaction, setTransaction] = useState<Transaction | null>(null);

  const fetchData = async () => {
    if (!transactionId) return;

    try {
      const response = await axiosClient.get(`ir_transactions/${transactionId}`);

      console.log(response);
      setTransaction(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [transactionId]);

  useImperativeHandle(ref, () => ({
    fetchData,
  }));
  
  const processTransactions = (transaction: Transaction): DataType[] => {
    const combinedData: { [key: number]: DataType } = {};


    transaction.transactions.stocks.forEach((stock) => {
      if (!combinedData[stock.product_id]) {
        combinedData[stock.product_id] = {
          key: stock.id.toString(),
          id: stock.product_id,
          name: stock.product_name,
          sku: stock.product_sku,
          description: stock.product_description,
          unit: stock.product_unit,
          quantity: stock.quantity,
          price: stock.price,
          amount: stock.quantity * stock.price,
          serial_numbers: [],
        };
      } else {
        combinedData[stock.product_id].quantity += stock.quantity;
        combinedData[stock.product_id].amount += stock.quantity * stock.price;
      }
    });

    transaction.transactions.serial_numbers.forEach((serial) => {
      if (!combinedData[serial.product_id]) {
        combinedData[serial.product_id] = {
          key: serial.id.toString(),
          id: serial.product_id,
          name: serial.product_name,
          sku: serial.product_sku,
          description: `${serial.product_description}`,
          unit: serial.product_unit,
          quantity: 1,
          price: serial.price,
          amount: serial.price,
          serial_numbers: [{ id: serial.id, serial_number: serial.serial_number }],
        };
      } else {
        combinedData[serial.product_id].quantity += 1;
        combinedData[serial.product_id].amount += serial.price;
        combinedData[serial.product_id].description += `, ${serial.serial_number}`;
        combinedData[serial.product_id].serial_numbers.push({
          id: serial.id,
          serial_number: serial.serial_number,
        });
      }
    });
    return Object.values(combinedData);
  };

  const createInvoiceData = (transaction: Transaction): InvoiceData => {
    const processedTransactions = processTransactions(transaction);

    return {
      id: transaction.id,
      issuance_id: transaction.issuance_id,
      slip_number: transaction.slip_number,
      reference_number: transaction.reference_number,
      date_of_return: transaction.date_of_return,
      customer_code: transaction.customer.code,
      customer_name: transaction.customer.name,
      customer_address: transaction.customer.address,
      customer_contact_person: transaction.customer.contact_person,
      transactions: processedTransactions.map((item) => ({
        product_name: item.name,
        product_sku: item.sku,
        description: item.description,
        unit: item.unit,
        unit_price: item.price,
        quantity: item.quantity,
        amount: item.amount,
      })),
      stock_location_record: transaction.transactions.initial_location.map((item: any) => {
        console.log(item);
        const details = processedTransactions.find((processTrans: any) => processTrans.id == item.product_id);

        console.log(processedTransactions);
        return {
          ...item,
          ...details,
        };
      }),
      checked_by: transaction.checked_by,
      checked_note: transaction.checked_note,
      checked_at: transaction.checked_at,
      received_at: transaction.received_at,
      received_by: transaction.received_by,
      received_note: transaction.received_note,
      created_at: transaction.created_at,
      created_by: transaction.created_by,
      subtotal: transaction.total_amount,
      newSubTotal: transaction.total_amount - transaction.total_lost_amount,
      hauler: transaction.hauler,
    };
  };

  return (
    <>
      {transaction && (
        <Invoice
          data={createInvoiceData(transaction)}
          userlevel={userlevel}
          onAcknowledgeSuccess={fetchData}
        />
      )}
    </>
  );
});

export default IssuanceReturnEntryFormStatic;
