import React, { createContext, useState, useContext, ReactNode } from 'react';

// Define the shape of your context state
interface NotificationType {
    notificationData: any[];
    setNotificationData: React.Dispatch<React.SetStateAction<any[]>>;
}

// Create the context with a default value
const Notification = createContext<NotificationType>({
    notificationData: [],
    setNotificationData: () => {
        console.log('Transaction ID received');
    },
});

interface NotificationsProviderProps {
    children: ReactNode;
}

export const NotificationProvider = ({ children }: NotificationsProviderProps) => {
    const [notificationData, setNotificationData] = useState<any[]>([]);

    return (
        <Notification.Provider value={{ notificationData, setNotificationData }}>
            {children}
        </Notification.Provider>
    );
};

export const useNotification = () => {
    return useContext(Notification);
};
