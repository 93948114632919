import React, { FC, useRef, useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Grid,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  Backdrop,
  CircularProgress,
  Divider,
  InputAdornment,
  Autocomplete,
  TextField,
  Alert,
} from '@mui/material';
import dayjs from 'dayjs';
import useFormattedNumber from 'src/hooks/useFormattedNumber';
import { ReactToPrint } from 'react-to-print';
import './print.css'; // Import the CSS file
import { ReactComponent as RECEIPT } from 'src/assets/images/svgs/rkc_wms_dark_receiptS.svg';
import { Input, Popconfirm, Popover, Steps, message, ConfigProvider } from 'antd';
import { theme } from 'antd/lib';
import axiosClient from 'src/axios_client';
import { InvoiceData } from './types';
import { useSelector, AppState } from 'src/store/Store';
import { useEssentialDataContext } from 'src/contexts/EssentialDataContext';
import { LogoDark, LogoLight } from 'src/globals/LogoExports';
import { formatDateWithTime } from 'src/globals/PublicFunctions';
import CustomTextField from 'src/components/theme-elements/CustomTextField';
import { ReturnsTable } from 'src/globals/MaterialTables';

interface GridItemProps {
  data: InvoiceData;
}

interface ErrorResponse {
  message: string;
  error: string;
}

const GridItem: FC<GridItemProps> = ({ data }) => {
  const { formatWithPrefix } = useFormattedNumber();

  const theme = useTheme();

  const excessQuantityArrays = data.stock_location_record.filter(
    (data: any) => data.excessQuantity > 0,
  );

  const customizer = useSelector((state: AppState) => state.customizer);

  const lostQuantityArrays = data.stock_location_record.filter(
    (data: any) => data.lostQuantity > 0,
  );
  const { userData } = useEssentialDataContext();
  console.log(data);
  console.log(userData);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        padding: { sm: '20px', md: '0px' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mb: 1,
        }}
      >
        <Box>
          <Box sx={{ width: '250px', marginLeft: '-36px', marginBottom: '5px' }}>
            {customizer.activeMode === 'dark' ? (
              <LogoLight height={'auto'} />
            ) : (
              <LogoDark height={'auto'} />
            )}

            {/* <RECEIPT
              style={{
                fill: theme.palette.mode === 'dark' ? '#ffffff' : '#000000', // Change fill color based on theme mode
              }}
              className="printable-logo"
            /> */}
          </Box>
          <Typography
            component="p"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            RAEL KITZ CORPORATION - WMS IS
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            RRF Building
            <br />
            San Miguel Calasiao, Pangasinan
            <br />
            +1 (123) 456 7891, +44 (876) 543 2198
          </Typography>
          <Typography
            variant="body2"
            sx={{ mt: 1, fontWeight: 'bold', textTransform: 'uppercase' }}
            className="fontSize8pt"
          >
            EXCESS RETURNS
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          <Typography variant="h6" component="p" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
            Reference Number: {data.reference_number}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Issuance Ref #:{data.issuance_id}
            <br />
            Return Date: {dayjs(data.date_of_return).format('MMMM DD, YYYY')}
          </Typography>
          <Typography variant="body1" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
            Customer: {data.customer_name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Customer Code: {data.customer_code}
            <br />
            Address: {data.customer_address}
            <br />
            Contact Person: {data.customer_contact_person}
            <br />
          </Typography>
        </Box>
      </Box>
      <Table className="printTable" sx={{ width: '100%', textAlign: 'left' }}>
        <TableHead sx={{ backgroundColor: 'grey.200' }}>
          <TableRow>
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              ITEM CODE
            </TableCell>
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              PRODUCT NAME
            </TableCell>
            {/* <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">DESCRIPTION</TableCell> */}
            {userData?.userlevel == 'superadmin' ||
              userData?.userlevel == 'stockman' ||
              userData?.userlevel == 'encoder' ? (
              <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
                Location
              </TableCell>
            ) : (
              ''
            )}
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              SN.
            </TableCell>
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              QTY
            </TableCell>
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              UNIT PRICE
            </TableCell>
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              AMOUNT
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {excessQuantityArrays.map((transaction: any, index: any) => (
            <TableRow key={index}>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.sku}
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.name}
              </TableCell>
              {/* <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.description}
              </TableCell> */}
              {userData?.userlevel == 'superadmin' ||
                userData?.userlevel == 'stockman' ||
                userData?.userlevel == 'encoder' ? (
                <TableCell sx={{ py: 1 }} className="fontSize8pt">
                  {transaction.location?.name ? (
                    <>
                      {transaction.location.name}
                      {transaction.divider} Floor {transaction.floor}
                    </>
                  ) : (
                    'No stock location record'
                  )}

                  {/* {transaction.location.name}{transaction.divider} Floor {transaction.floor} */}
                </TableCell>
              ) : (
                ''
              )}
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.serial_numbers.length > 0 &&
                  transaction.excess_serial_numbers
                    .map((serial: any) => serial.serial_number)
                    .join(', ')}
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.excessQuantity} {transaction.unit}
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {formatWithPrefix(transaction.price)}
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {formatWithPrefix(transaction.excessQuantity * transaction.price)}
              </TableCell>
            </TableRow>
          ))}

          {lostQuantityArrays.map((transaction: any, index: any) => (
            <TableRow
              key={index}
              sx={{ '.MuiTableCell-root': { color: 'red', fontWeight: 'bold' } }}
            >
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.product_sku}
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.name}
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                LOST IN TRANSIT
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.serial_numbers.length > 0 &&
                  transaction.lost_serial_numbers
                    .map((serial: any) => serial.serial_number)
                    .join(', ')}
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.excessQuantity} {transaction.unit} LOST
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                -{formatWithPrefix(transaction.price)}
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                -{formatWithPrefix(transaction.excessQuantity * transaction.price)}
              </TableCell>
            </TableRow>
          ))}

          {/* {data.transactions.map((transaction, index) => (
            <TableRow key={index}>
              <TableCell sx={{ py: 1 }}>{transaction.product_name}</TableCell>
              <TableCell sx={{ py: 1, whiteSpace: 'pre-line' }}>
                {transaction.description}
              </TableCell>
              <TableCell sx={{ py: 1 }}>{transaction.unit}</TableCell>
              <TableCell sx={{ py: 1 }}>{formatWithPrefix(transaction.unit_price)}</TableCell>
              <TableCell sx={{ py: 1 }}>{transaction.quantity}</TableCell>
              <TableCell sx={{ py: 1 }}>{formatWithPrefix(transaction.amount)}</TableCell>
            </TableRow>
          ))} */}

          <TableRow>
            <TableCell sx={{ py: 1 }}>
              <Typography sx={{ fontWeight: 'bold' }} className="fontSize8pt">
                Total: {data.transactions.length}
              </Typography>
            </TableCell>
            <TableCell sx={{ py: 1, whiteSpace: 'pre-line' }}></TableCell>
            <TableCell sx={{ py: 1 }}></TableCell>
            <TableCell sx={{ py: 1 }}></TableCell>

            {/* <TableCell sx={{ py: 1 }}></TableCell> */}
            <TableCell sx={{ py: 1 }}></TableCell>
            <TableCell sx={{ py: 1, fontWeight: 'bold' }} className="fontSize8pt">
              {formatWithPrefix(data.newSubTotal)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Divider sx={{ borderColor: '#acacac' }} />
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        Returned By: {data.hauler}
      </Typography>
      {/* <Typography
        variant="body1"
        sx={{ textAlign: 'right', color: 'text.primary', fontWeight: 'bold' }}
      >
        Subtotal: {formatWithPrefix(data.newSubTotal)}
      </Typography> */}
    </Box>
  );
};

const Invoice: FC<{ data: InvoiceData; userlevel: any; onAcknowledgeSuccess?: () => void }> = ({
  data,
  userlevel,
  onAcknowledgeSuccess,
}) => {
  const [note, setNote] = useState('');
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();
  const { userData } = useEssentialDataContext();

  const componentRef = useRef(null);

  const getCurrentStep = (data: InvoiceData): number => {
    if (data.received_by.name && data.received_at) {
      return 3; // Confirmed
    } else if (data.checked_by.name) {
      return 2; // Received by Checker
    } else if (data.created_at) {
      return 1; // Created Return Transaction
    }
    return 0; // Default to the first step
  };

  const acknowledge = async () => {
    if (!data.id) return;

    try {
      const response = await axiosClient.post(`/ir_transactions/${data.id}/acknowledge`, {
        userlevel: userlevel,
        note: note,
      });
      console.log('Acknowledgment successful', response.data);
      messageApi.open({
        type: 'success',
        content: `Acknowledgment successful`,
        className: 'robotoFont',
        style: {
          marginTop: '20vh',
        },
      });
      setIsAcknowledged(true);
      onAcknowledgeSuccess?.();
    } catch (error) {
      console.error('Acknowledgment failed', error);
      const errorMessage =
        (error as { response?: { data?: ErrorResponse } }).response?.data?.error ||
        'An unexpected error occurred';
      messageApi.open({
        type: 'error',
        content: `Acknowledgment unsuccessful: ${errorMessage}`,
        className: 'robotoFont',
        style: {
          marginTop: '20vh',
        },
      });
    }
  };

  const customizer = useSelector((state: AppState) => state.customizer);
  const currentTheme = customizer.activeMode;

  const [isDarkMode, setIsDarkMode] = useState(false);
  const { defaultAlgorithm, darkAlgorithm } = theme;
  useEffect(() => {
    setIsDarkMode(currentTheme === 'dark');
  }, [currentTheme]);

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [sucess, setSucess] = useState('');
  const [error, setError] = useState('');

  const handleClickOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const removeErrorOrSuccess = () => {
    setTimeout(() => {
      setError('');
      setSucess('');
    }, 3000);
  };
  const reportTransaction = (transactionsWithLostQuantity: any) => {
    setSubmitting(true);

    const newAmount = data.subtotal;
    let minusThis = 0;
    let totalItemsMissing = 0;
    let isValid = true;
    const reportTransact = transactionsWithLostQuantity.map((transactions: any) => {
      console.log(transactions);

      if (transactions.serial_numbers.length > 0 && transactions.lostQuantity > 0) {
        if (transactions.lostQuantity != transactions.lost_serial_numbers?.length || 0) {
          setError('PLEASE CHECK YOUR QUANTITY AND SERIAL NUMBERS');
          isValid = false;
          removeErrorOrSuccess();
          return;
        }
      }

      if (transactions.lostQuantity > 0) {
        minusThis = minusThis + transactions.lostQuantity * transactions.price;
        totalItemsMissing = totalItemsMissing + transactions.lostQuantity;
      }

      const array = { ...transactions };

      delete array.key;
      delete array.name;
      delete array.description;
      delete array.unit;
      delete array.amount;

      return array;
    });

    const passThisData = {
      total_lost_amount: minusThis,
      total_missing_items: totalItemsMissing,
      stock_location_record: reportTransact,
    };

    if (totalItemsMissing == 0) {
      setError('CHECK YOUR MISSING ITEMS!');
      setSubmitting(false);
      removeErrorOrSuccess();
      return;
    }

    if (!isValid) {
      setSubmitting(false);
      return;
    }

    const response = axiosClient
      .post(`/ir_transactions/report_missing/${data.id}`, passThisData)
      .then((response) => {
        if (response.status == 201 || response.status == 200) {
          setSucess('REPORT SUCCESSFULLY SUBMITTED!');

          data.newSubTotal = newAmount - minusThis;
          data.stock_location_record = transactionsWithLostQuantity;
          // data.checked_at = dayjs();
          // data.checked_note = "HAS MISSING ITEMS";
          // data.checked_by.name = userData ? userData?.name : `Can't Find name`;

          setIsAcknowledged(true);
          setSubmitting(false);
          setTimeout(() => {
            setSucess('');
          }, 3000);
          setDialogOpen(false);
        } else if (response.status == 500) {
          setError('REPORT ENCOUNTERED SOME ERRORS');
          setTimeout(() => {
            setError('');
          }, 3000);
          setDialogOpen(false);
        }
      })
      .catch((error) => {
        setSubmitting(false);

        setError(error.response.data.message);
        setDialogOpen(false);
        setTimeout(() => {
          setError('');
        }, 3000);
      });
  };

  return (
    // <Box
    //   key={refreshKey}
    //   sx={{
    //     display: 'flex',
    //     flexDirection: { xs: 'column', md: 'row' },
    //     p: 4,
    //     bgcolor: 'background.paper',
    //     borderRadius: 1,
    //     boxShadow: 3,
    //   }}
    // >
    //   {contextHolder}

    //   <Grid container spacing={4}>
    //     <Grid item xs={12}>
    //       {sucess && (
    //         <Alert variant="filled" severity="success" sx={{ alignItems: 'center', mb: 1 }}>
    //           {sucess}
    //         </Alert>
    //       )}

    //       {error && (
    //         <Alert variant="filled" severity="error" sx={{ alignItems: 'center', mb: 1 }}>
    //           {error}
    //         </Alert>
    //       )}
    //     </Grid>
    //     <Grid item xs={12} md={9} ref={componentRef}>
    //       <Box>
    //         <GridItem
    //           data={data}
    //           copy={'Receiver Copy'}
    //           isConfirmed={isConfirmed}
    //           userlevel={userlevel}
    //           handleNoticeSelect={handleNoticeSelect}
    //           selectedNotices={selectedNotices}
    //           handleNoteChange={handleNoteChange}
    //         />
    //       </Box>

    //       {userlevel != 'customer' &&

    //         <>
    //           <div className="pageBreak"></div>
    //           <Box sx={{ display: 'none', displayPrint: 'block' }}>
    //             <GridItem
    //               data={data}
    //               copy={'Encoder Copy'}
    //               isConfirmed={isConfirmed}
    //               userlevel={userlevel}
    //               handleNoticeSelect={handleNoticeSelect}
    //               selectedNotices={selectedNotices}
    //               handleNoteChange={handleNoteChange}
    //             />
    //           </Box>
    //           <div className="pageBreak"></div>
    //           <Box sx={{ display: 'none', displayPrint: 'block' }}>
    //             <GridItem
    //               data={data}
    //               copy={'Warehouse Copy'}
    //               isConfirmed={isConfirmed}
    //               userlevel={userlevel}
    //               handleNoticeSelect={handleNoticeSelect}
    //               selectedNotices={selectedNotices}
    //               handleNoteChange={handleNoteChange}
    //             />
    //           </Box>
    //           <div className="pageBreak"></div>
    //           <Box sx={{ display: 'none', displayPrint: 'block' }}>
    //             <GridItem
    //               data={data}
    //               copy={'Checker Copy'}
    //               userlevel={userlevel}
    //               isConfirmed={isConfirmed}
    //               handleNoticeSelect={handleNoticeSelect}
    //               selectedNotices={selectedNotices}
    //               handleNoteChange={handleNoteChange}
    //             />
    //           </Box>
    //           <div className="pageBreak"></div>
    //           <Box sx={{ display: 'none', displayPrint: 'block' }}>
    //             <GridItem
    //               data={data}
    //               copy={'Accounting Copy'}
    //               isConfirmed={isConfirmed}
    //               userlevel={userlevel}
    //               handleNoticeSelect={handleNoticeSelect}
    //               selectedNotices={selectedNotices}
    //               handleNoteChange={handleNoteChange}
    //             />
    //           </Box>
    //         </>
    //       }

    //     </Grid>

    //     <Grid item xs={12} md={3}>
    //       <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
    //         {data.status === 'floating' && data.released_at == null && userlevel === 'stockman' && (
    //           <Button
    //             variant="outlined"
    //             color="error"
    //             sx={{ py: 1.5, px: 3, borderRadius: 1, display: isConfirmed ? 'none' : 'flex' }}
    //             onClick={handleConfirmNotices}
    //           >
    //             Confirm Notice
    //           </Button>
    //         )}

    //         <Steps
    //           progressDot
    //           current={getCurrentStep(data)}
    //           direction="vertical"
    //           items={[
    //             {
    //               title: 'Issuance Created',
    //               description: `Created by ${data.created_by.name} on ${dayjs(
    //                 data.created_at,
    //               ).format('MMMM DD, YYYY HH:mm:ss')}`,
    //             },

    //             {
    //               title: (
    //                 <>
    //                   <Popover
    //                     content={
    //                       data.released_note === '' || data.released_note == null
    //                         ? 'Notes will be here'
    //                         : data.released_note
    //                     }
    //                   >
    //                     Stockman Receipt
    //                   </Popover>
    //                 </>
    //               ),
    //               description:
    //                 data.released_by.name && data.released_at
    //                   ? `Released by ${data.released_by.name} on ${dayjs(data.released_at).format(
    //                     'MMMM DD, YYYY HH:mm:ss',
    //                   )}`
    //                   : 'Pending',
    //             },
    //             {
    //               // title: 'Checker Review',
    //               title: (
    //                 <>
    //                   <Popover
    //                     content={
    //                       data.checked_note === '' || data.checked_note == null
    //                         ? 'Notes will be here'
    //                         : data.checked_note
    //                     }
    //                   >
    //                     Checker Review
    //                   </Popover>
    //                 </>
    //               ),
    //               description:
    //                 data.checked_by.name && data.checked_at
    //                   ? `Cross-Checked by ${data.checked_by.name} on ${dayjs(
    //                     data.checked_at,
    //                   ).format('MMMM DD, YYYY HH:mm:ss')}`
    //                   : 'Pending',
    //             },
    //             {
    //               title: (
    //                 <>
    //                   <Popover content={data.received_at === '' ? 'OK' : data.received_at}>
    //                     Receiving
    //                   </Popover>
    //                 </>
    //               ),
    //               description: data.received_at
    //                 ? `Received on ${dayjs(data.received_at).format('MMMM DD, YYYY HH:mm:ss')}`
    //                 : 'Pending',
    //             },
    //             {
    //               title: 'Confirmation',
    //               description: data.received_at ? 'Completed' : 'Pending',
    //             },
    //           ]}
    //         />

    //         {(userlevel === 'checker' ||
    //           userlevel === 'stockman' ||
    //           userlevel === 'customer' ||
    //           userlevel === 'superadmin') && (
    //             <Popconfirm
    //               title="Acknowledge Issuance"
    //               description={
    //                 <>
    //                   <p>
    //                     Are you sure you want to{' '}
    //                     {userlevel === 'checker'
    //                       ? 'RELEASE (SECOND LEVEL)'
    //                       : userlevel === 'stockman'
    //                         ? 'RELEASE (FIRST LEVEL)'
    //                         : userlevel === 'customer'
    //                           ? 'RECEIVE'
    //                           : ''}{' '}
    //                     this issuance?
    //                   </p>
    //                   <Input.TextArea
    //                     placeholder="Add a note (optional)"
    //                     value={note}
    //                     onChange={(e) => setNote(e.target.value)}
    //                     rows={4}
    //                   />
    //                 </>
    //               }
    //               onConfirm={acknowledge}
    //               okText="Yes"
    //               cancelText="No"
    //               placement="right"
    //             >
    //               <Button
    //                 disabled={
    //                   isAcknowledged ||
    //                   (userlevel === 'checker' && data?.checked_by.id !== null) ||
    //                   (userlevel === 'stockman' && data?.released_by.id !== null) ||
    //                   (userlevel === 'customer' && data?.received_at !== null) ||
    //                   (userlevel === 'superadmin' &&
    //                     data?.checked_by.id !== null &&
    //                     data?.released_by.id !== null)
    //                 }
    //               >
    //                 {(userlevel === 'checker' && data?.checked_by.id !== null) ||
    //                   (userlevel === 'stockman' && data?.released_by.id !== null) ||
    //                   (userlevel === 'customer' && data?.received_at !== null) ||
    //                   (userlevel === 'superadmin' &&
    //                     data?.checked_by.id !== null &&
    //                     data?.released_by.id !== null)
    //                   ? 'Acknowledged'
    //                   : 'Acknowledge'}
    //               </Button>
    //             </Popconfirm>
    //           )}

    //         {
    //           data.checked_by.name && !data.received_at && ((userlevel === 'customer') || (userlevel === 'superadmin')) ?
    //             <Button variant='text' color='error' onClick={handleClickOpen}>
    //               Report Missing Items
    //             </Button> : ''
    //         }

    //         {
    //           !data.excess_transaction_date && data.checked_by.name && ((userlevel === 'customer') || (userlevel === 'superadmin')) ?
    //             <Button variant='text' color='error' onClick={handleOpenExcess}>
    //               Return Excess
    //             </Button> : ''
    //         }

    //         <ReactToPrint
    //           trigger={() => (
    //             <Button variant="contained" size='small' color='primary'>
    //               Print
    //             </Button>
    //           )}
    //           content={() => {
    //             return componentRef.current;
    //           }}
    //           pageStyle={`
    //             @media print {
    //               body {
    //                 -webkit-print-color-adjust: exact;
    //                 margin: 20mm;
    //                 padding: 50px;
    //               }
    //               @page {
    //                 margin: 20mm;
    //               }
    //               .printTable th,
    //               .printTable td {
    //                 padding: 2px;
    //               }
    //               header, footer {
    //                 display: none;
    //               }
    //               .pageBreak {
    //                 page-break-after: always;
    //               }
    //               .fontSize8pt {
    //                 font-size: 8pt !important;
    //               }
    //               tbody tr:not(:last-child) td {
    //                 border-bottom: 1px solid #acacac;
    //               }
    //               .printable-logo {
    //                 fill: #000000 !important; /* Ensure the logo prints in black */
    //               }
    //             }
    //           `}
    //         />
    //       </Box>
    //     </Grid>
    //   </Grid>

    //   <Dialog
    //     open={dialogOpen}
    //     onClose={handleDialogClose}
    //     fullWidth
    //     maxWidth={'md'}
    //     aria-labelledby="alert-dialog-title"
    //     aria-describedby="alert-dialog-description"
    //   >
    //     <DialogTitle id="alert-dialog-title">{'Missing Details'}</DialogTitle>
    //     <DialogContent>
    //       <Backdrop
    //         sx={(theme: any) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
    //         open={isSubmitting}
    //       >
    //         <CircularProgress color="inherit" />
    //       </Backdrop>
    //       <ReportItems data={data} detail={'lost'} userlevel={userlevel} reportClick={reportTransaction} cancelClick={handleDialogClose} />
    //     </DialogContent>
    //   </Dialog>

    //   <Dialog
    //     open={excessOpen}
    //     onClose={handleExcessClose}
    //     fullWidth
    //     maxWidth={'md'}
    //     aria-labelledby="alert-dialog-title"
    //     aria-describedby="alert-dialog-description"
    //   >
    //     <DialogTitle id="alert-dialog-title">{'Excess Details'}</DialogTitle>
    //     <DialogContent>
    //       <Backdrop
    //         sx={(theme: any) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
    //         open={isSubmitting}
    //       >
    //         <CircularProgress color="inherit" />
    //       </Backdrop>
    //       <ReportItems data={data} detail={'excess'} userlevel={userlevel} reportClick={excessTransaction} cancelClick={handleExcessClose} />
    //     </DialogContent>
    //   </Dialog>
    // </Box>

    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        p: 4,
        bgcolor: 'background.paper',
        borderRadius: 1,
        boxShadow: 3,
      }}
    >
      {contextHolder}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {sucess && (
            <Alert variant="filled" severity="success" sx={{ alignItems: 'center', mb: 1 }}>
              {sucess}
            </Alert>
          )}

          {error && (
            <Alert variant="filled" severity="error" sx={{ alignItems: 'center', mb: 1 }}>
              {error}
            </Alert>
          )}
        </Grid>
        <Grid item xs={12} md={9} ref={componentRef}>
          <GridItem data={data} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Steps
              progressDot
              current={getCurrentStep(data)}
              direction="vertical"
              items={[
                {
                  title: 'Return Transaction Created',
                  description: `Created by ${data.created_by.name} on ${dayjs(
                    data.created_at,
                  ).format('MMMM DD, YYYY HH:mm:ss')}`,
                },
                {
                  // title: 'Checker Review',
                  title: (
                    <>
                      <Popover
                        content={
                          data.checked_note === '' || data.checked_note == null
                            ? 'Notes will be here'
                            : data.checked_note
                        }
                      >
                        Checker Review
                      </Popover>
                    </>
                  ),
                  description:
                    data.checked_by.name && data.checked_at
                      ? `Checked by ${data.checked_by.name} on ${dayjs(data.checked_at).format(
                        'MMMM DD, YYYY HH:mm:ss',
                      )}`
                      : 'Pending',
                },
                {
                  title: (
                    <>
                      <Popover
                        content={
                          data.received_note === '' || data.received_note == null
                            ? 'Notes will be here'
                            : data.received_note
                        }
                      >
                        Stockman Receipt
                      </Popover>
                    </>
                  ),
                  description:
                    data.received_by.name && data.received_at
                      ? `Received by ${data.received_by.name} on ${dayjs(data.received_at).format(
                        'MMMM DD, YYYY HH:mm:ss',
                      )}`
                      : 'Pending',
                },
                {
                  title: 'Confirmation',
                  description: data.received_by.name && data.received_at ? 'Completed' : 'Pending',
                },
              ]}
            />

            {/* <Button variant="contained" color="primary" sx={{ py: 1.5, px: 3, borderRadius: 1 }}>
              Send Transaction Record
            </Button> */}

            {(userlevel === 'checker' ||
              userlevel === 'stockman' ||
              userlevel === 'superadmin') && (
                <Popconfirm
                  title="Acknowledge Transmittal"
                  description={
                    <>
                      <p>Are you sure you want to acknowledge this transmittal?</p>
                      <Input.TextArea
                        placeholder="Add a note (optional)"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        rows={4}
                      />
                    </>
                  }
                  onConfirm={acknowledge}
                  okText="Yes"
                  cancelText="No"
                  placement="right"
                >
                  <Button
                    disabled={
                      isAcknowledged ||
                      (userlevel === 'checker' && data?.checked_by.id !== null) ||
                      (userlevel === 'stockman' && data?.received_by.id !== null) ||
                      (userlevel === 'superadmin' &&
                        data?.checked_by.id !== null &&
                        data?.received_by.id !== null)
                    }
                  >
                    {(userlevel === 'checker' && data?.checked_by.id !== null) ||
                      (userlevel === 'stockman' && data?.received_by.id !== null) ||
                      (userlevel === 'superadmin' &&
                        data?.checked_by.id !== null &&
                        data?.received_by.id !== null)
                      ? 'Acknowledged'
                      : 'Acknowledge'}
                  </Button>
                </Popconfirm>
              )}
            {userlevel === 'checker' || userlevel === 'superadmin' ? (
              <Button
                variant="text"
                disabled={data.checked_at}
                color="error"
                onClick={handleClickOpen}
              >
                Report Missing Items
              </Button>
            ) : (
              ''
            )}
            <ReactToPrint
              trigger={() => (
                <Button variant="contained" color="primary">
                  Print
                </Button>
              )}
              content={() => componentRef.current}
              pageStyle={`
                @media print {
                  body {
                    -webkit-print-color-adjust: exact;
                    margin: 20mm;
                    padding: 50px;
                  }
                  @page {
                    margin: 20mm;
                  }
                  .printTable {
                    font-size: 7px;
                  }
                  .printTable th,
                  .printTable td {
                    padding: 2px;
                  }
                  header, footer {
                    display: none;
                  }
                     .printable-logo {
                    fill: #000000 !important; /* Ensure the logo prints in black */
                  }
                }
              `}
            />
          </Box>
        </Grid>
      </Grid>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        fullWidth
        maxWidth={'lg'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Report Details'}</DialogTitle>
        <DialogContent>
          <Backdrop
            sx={(theme: any) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
            open={isSubmitting}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <ReportItems
            data={data}
            detail={'lost'}
            userlevel={userlevel}
            reportClick={reportTransaction}
            cancelClick={handleDialogClose}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};
interface ReportItemProps {
  data: InvoiceData;
  detail: any;
  userlevel: any;
  reportClick: any;
  cancelClick: any;
}
const ReportItems: FC<ReportItemProps> = ({
  data,
  detail,
  reportClick,
  cancelClick,
  userlevel,
}) => {
  const { formatWithPrefix } = useFormattedNumber();

  const validUserLevels = ['stockman', 'encoder', 'superadmin', 'customer'];

  const isValidUserLevel = (currentUserLevel: any) => validUserLevels.includes(currentUserLevel);

  console.log(data);
  const customizer = useSelector((state: AppState) => state.customizer);

  const { userData } = useEssentialDataContext();

  useEffect(() => {
    console.log(data);
  }, [data]);

  const [transactionsWithLostQuantity, setTransactionsWithLostQuantity] = useState(
    data.stock_location_record.map((transaction: any) => ({
      ...transaction,
      missing_serial_numbers: [],
      ...(detail === 'lost' ? { lostQuantity: 0 } : { excessQuantity: 0 }),
    })),
  );

  // Handle change for lostQuantity TextField
  const handleLostQuantityChange = (index: any, event: any) => {
    const value = parseInt(event.target.value, 10);
    const takenQuantity = transactionsWithLostQuantity[index].takenQuantity ?? 0;
    const lostQuantity = transactionsWithLostQuantity[index].lostQuantity ?? 0;
    const excessQuantity = transactionsWithLostQuantity[index].excessQuantity ?? 0;

    const maxQuantity = excessQuantity;

    if (value >= 0 && value <= maxQuantity) {
      setTransactionsWithLostQuantity((prevTransactions: any) =>
        prevTransactions.map((transaction: any, idx: any) =>
          idx === index
            ? {
              ...transaction,
              ...(detail === 'lost' ? { lostQuantity: value } : { excessQuantity: value }),
            }
            : transaction,
        ),
      );
    } else {
      console.log('Sobra na yan insan');
    }
  };

  const handleSerialNumbersChange = (index: number, newSelectedSerialNumbers: any[]) => {
    setTransactionsWithLostQuantity((prevTransactions: any) =>
      prevTransactions.map((transaction: any, idx: any) =>
        idx === index
          ? {
            ...transaction,
            missing_serial_numbers: newSelectedSerialNumbers, // Update only selected serial numbers
          }
          : transaction,
      ),
    );
  };

  const handlePost = () => {
    reportClick(transactionsWithLostQuantity);
  };
  const handleCancel = () => {
    cancelClick();
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mb: 1,
        }}
      >
        <Box>
          <Box sx={{ width: '250px', marginLeft: '-36px', marginBottom: '5px' }}>
            {customizer.activeMode === 'dark' ? (
              <LogoLight height={'auto'} />
            ) : (
              <LogoDark height={'auto'} />
            )}
          </Box>
          <Typography
            component="p"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            RAEL KITZ CORPORATION - WMS IS
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            RRF Building San Miguel Calasiao, Pangasinan
            <br />
            +1 (123) 456 7891, +44 (876) 543 2198
          </Typography>

          <Typography
            variant="body2"
            sx={{ mt: 1, fontWeight: 'bold', textTransform: 'uppercase' }}
            className="fontSize8pt"
          >
            EXCESS RETURNS
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          <Typography
            variant="h6"
            component="p"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            Reference Number: {data.reference_number}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Issuance Ref #:{data.issuance_id}
            <br />
            Return Date: {data.date_of_return}
            {/* Transaction ID: {data.id} */}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            Customer: {data.customer_name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            Customer Code: {data.customer_code}
            <br />
            Address: {data.customer_address}
            <br />
            Contact Person: {data.customer_contact_person}
            <br />
          </Typography>
        </Box>
      </Box>
      <Table className="printTable" sx={{ width: '100%', textAlign: 'left' }}>
        <TableHead sx={{ backgroundColor: 'grey.200' }}>
          <TableRow>
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              ITEM CODE
            </TableCell>
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              PRODUCT NAME
            </TableCell>
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              MODEL
            </TableCell>
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              SERIAL NO.
            </TableCell>
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              QTY
            </TableCell>
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              COST
            </TableCell>
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              AMOUNT
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactionsWithLostQuantity.map((transaction: any, index: any) => (
            <TableRow key={index}>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.product_sku}
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.name}
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.description}
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.serial_numbers.length > 0 && (
                  <Autocomplete
                    multiple
                    id={`autocomplete-serial-numbers-${index}`}
                    options={transaction.serial_numbers} // Keep all serial numbers as options
                    getOptionLabel={(option) => option.serial_number} // Display the serial number as label
                    value={transaction.missing_serial_numbers || []} // The currently selected serial numbers
                    onChange={(event, newValue) => handleSerialNumbersChange(index, newValue)} // Handle selection change
                    size="small"
                    isOptionEqualToValue={(option, value) =>
                      option.serial_number === value.serial_number
                    } // Compare based on serial number
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" size="small" />
                    )}
                  />
                )}

                {/* {transaction.serial_numbers.length > 0 &&
                  transaction.serial_numbers.map((serial: any) => serial.serial_number).join(', ')} */}
              </TableCell>
              <TableCell sx={{ py: 1, maxWidth: 80 }} className="fontSize8pt">
                <CustomTextField
                  id="quotation_quantity"
                  size="small"
                  variant="standard"
                  type="number"
                  value={detail == 'lost' ? transaction.lostQuantity : transaction.excessQuantity}
                  onChange={(e: any) => handleLostQuantityChange(index, e)}
                  InputProps={{
                    min: 0, // Minimum value is 0
                    max: transaction.lostQuantity, // Maximum value is takenQuantity
                    endAdornment: (
                      <InputAdornment position="start">{transaction.unit}</InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {formatWithPrefix(transaction.price)}
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {formatWithPrefix(transaction.lostQuantity * transaction.price)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Grid container columnSpacing={1}>
        <Grid item>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            WAREHOUSE MANAGEMENT SYSTEM - by: INFORMATION SYSTEMS{' '}
            {formatDateWithTime(data.created_at)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            User: {userData?.name}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" sx={{ fontSize: '10pt' }} color="error" className="fontSize8pt">
            Note: Input 0 if theres no lost
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container columnSpacing={1} justifyContent={'end'}>
            <Grid item>
              <Button onClick={handleCancel}>Cancel</Button>
            </Grid>
            <Grid item>
              <Button autoFocus color="error" onClick={handlePost}>
                Report
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Invoice;
