// IssuanceEntryTransactionsTable.tsx
import React, { useEffect, useMemo, useState, useCallback, useImperativeHandle } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from 'material-react-table';
import { Tooltip, IconButton, Grid, Button, Box, Chip, Typography } from '@mui/material';
import { useSelector } from 'src/store/Store';
import { AppState } from 'src/store/Store';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';
import axiosClient from 'src/axios_client';
import { IconDeviceDesktop, IconEdit, IconEye, IconPlus, IconTrash, IconX } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import { useTransaction } from 'src/contexts/PurchaseTransactionContext';
import { useEssentialDataContext } from 'src/contexts/EssentialDataContext';
import { Check, LocalShipping, ThumbUpAlt, Waves } from '@mui/icons-material';

export type IssuanceEntryTransactionsApiResponse = {
  data: Array<IssuanceEntryTransactions>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

export type IssuanceEntryTransactions = {
  id: number;
  reference_number?: string;
  slip_number?: string;
  date_of_issuance?: string;
  transactions?: any;
  name: string;
  description: string;
  customer: any;
  status: string;
};

type IssuanceEntryTransactionsTableProps = {
  onOpenFloatingIssuance: (transaction: IssuanceEntryTransactions | null) => void;
  onRefetchRef: React.RefObject<{ refetch: () => void }>;
  userlevel: any;
};

const statusSelect = [
  { label: 'Posted', value: 'posted' },
  { label: 'Floating', value: 'floating' },
  { label: 'In Transit', value: 'in_transit' },
  { label: 'Checked', value: 'checked' },
  { label: 'Finalized', value: 'finalized' },
  { label: 'Cancelled', value: 'cancelled' },
];
const IssuanceEntryTransactionsTable: React.FC<IssuanceEntryTransactionsTableProps> = ({
  onOpenFloatingIssuance,
  onRefetchRef,
  userlevel,
}) => {
  const customizer = useSelector((state: AppState) => state.customizer);
  const currentTheme = customizer.activeMode;

  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    setIsDarkMode(currentTheme === 'dark');
  }, [currentTheme]);

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const {
    data: { data = [], pagination: meta } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<IssuanceEntryTransactionsApiResponse>({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      try {
        const params = {
          page: `${pagination.pageIndex + 1}`,
          per_page: `${pagination.pageSize}`,
          filters: JSON.stringify(columnFilters ?? []),
          globalFilter: globalFilter ?? '',
          sorting: JSON.stringify(sorting ?? []),
        };

        const response = await axiosClient.get<IssuanceEntryTransactionsApiResponse>(
          '/ie_transactions',
          { params },
        );

        console.log(response); // You can keep this for debugging purposes.

        return response.data;
      } catch (error) {
        console.error('Error fetching issuance entry transactions:', error);
        throw error;
      }
    },

    placeholderData: keepPreviousData,
  });

  const columns = useMemo<MRT_ColumnDef<IssuanceEntryTransactions>[]>(
    () => [
      // {
      //   accessorKey: 'reference_number',
      //   header: 'Reference Number',
      //   size: 180,
      // },
      {
        accessorKey: 'slip_number',
        header: 'Slip Number',
        size: 50,
      },
      {
        accessorKey: 'reference_number',
        header: 'Reference Number',
        size: 50,
      },
      {
        accessorKey: 'customer',
        header: 'Customer',
        enableSorting: false,
        enableColumnDragging: false,
        enableColumnFilter: true,
        size: 100,
        Cell: ({ row }) => row.original.customer.name,
      },
      {
        accessorKey: 'date_of_issuance',
        header: 'Transaction Date',
        enableColumnDragging: false,
        enableColumnFilter: false,
        size: 180,
        Cell: ({ cell }) => {
          const date = new Date(cell.getValue<string>());
          return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          });
        },
      },
      {
        accessorKey: 'status',
        header: 'Status',
        filterVariant: 'select',
        filterSelectOptions: statusSelect,

        size: 180,
        Cell: ({ row }) =>
          row.original.status === 'posted' || row.original.status === 'checked' ? (
            <Chip icon={<Check />} label={row.original.status} color="success" />
          ) : row.original.status === 'floating' ? (
            <Chip icon={<Waves />} label={'Floating'} color="warning" />
          ) : row.original.status === 'initial_floating' ? (
            <Chip icon={<Waves />} label={'Initial Floating'} color="warning" />
          ) : row.original.status === 'in_transit' ? (
            <Chip icon={<LocalShipping />} label={'In transit'} color="success" />
          ) : row.original.status === 'finalized' ? (
            <Chip icon={<ThumbUpAlt />} label={'Finalized'} color="info" />
          ) : (
            <Chip label={row.original.status} color="error" />
          ),
      },
      {
        accessorKey: 'actions',
        header: 'Actions',
        size: 120,
        enableSorting: false,
        enableColumnDragging: false,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          const { original } = row;
          const { status } = original;
          console.log(userlevel);
          const renderButton = () => {
            if (status === 'posted') {
              return (
                <IconButton
                  sx={{
                    borderRadius: '5px',
                    color: 'white',
                    backgroundColor: '#13DEB9',
                    '&:hover': {
                      backgroundColor: '#02b3a9',
                      boxShadow: '0 0 1px 0 rgba(0, 0, 0, 0.31), 0 3px 4px -2px rgba(0, 0, 0, 0.25)'
                    }

                  }}
                  onClick={() => handleViewIssuanceEntryTransaction(original)}
                  aria-label="reset password"
                  size="small"
                >
                  <IconEye />
                </IconButton>
              );
            }
            else {
              return (
                <>
                  <Grid container spacing={1}>
                    <Grid item /* sx={{ display: userData?.userlevel == 'customer' ? 'none' : 'flex' }} */>
                      <IconButton
                        sx={{
                          borderRadius: '5px',
                          color: 'white',
                          backgroundColor: '#13DEB9',
                          '&:hover': {
                            backgroundColor: '#02b3a9',
                            boxShadow: '0 0 1px 0 rgba(0, 0, 0, 0.31), 0 3px 4px -2px rgba(0, 0, 0, 0.25)'
                          }

                        }}
                        onClick={() => handleViewIssuanceEntryTransaction(original)}
                        aria-label="reset password"
                        size="small"
                      >
                        <IconEye />
                      </IconButton>
                    </Grid>
                    <Grid item sx={{ display: userlevel == "encoder" || userlevel == "superadmin" && (status == "floating" || status == "initial_floating") ? "block" : "none" }} >
                      <IconButton
                        sx={{
                          borderRadius: '5px',
                          backgroundColor: '#FFAE1F',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: '#ae8e59',
                            boxShadow: '0 0 1px 0 rgba(0, 0, 0, 0.31), 0 3px 4px -2px rgba(0, 0, 0, 0.25)'
                          }
                        }}
                        onClick={() => onOpenFloatingIssuance(original)}
                        aria-label="reset password"
                        size="small"
                      >
                        <IconDeviceDesktop />
                      </IconButton>
                    </Grid>
                    <Grid item sx={{ display: userlevel == 'encoder' || userlevel == "superadmin" && (status != "in_transit" && status != "posted" && status != "cancelled") ? 'block' : 'none' }}>
                      <IconButton
                        sx={{
                          borderRadius: '5px',
                          backgroundColor: '#FA896B',
                          '&:hover': {
                            backgroundColor: '#f3704d',
                            boxShadow: '0 0 1px 0 rgba(0, 0, 0, 0.31), 0 3px 4px -2px rgba(0, 0, 0, 0.25)'
                          },
                          color: 'white'
                        }}
                        onClick={() => handleCancelTransaction(original)}
                        aria-label="reset password"
                        size="small"
                      >
                        <IconX />
                      </IconButton>
                    </Grid>
                  </Grid>
                  {/* <Button
                    onClick={() => handleViewIssuanceEntryTransaction(original)}
                    variant="outlined"
                  >
                    <IconEye />
                  </Button>
                  <Button sx={{
                    display: userlevel == "encoder" || userlevel == "superadmin" ? "" : "none"
                  }} onClick={() => onOpenFloatingIssuance(original)} variant="outlined">
                    <IconDeviceDesktop />
                  </Button>
                  <Button
                    sx={{ ml: 1 }}
                    onClick={() => handleCancelTransaction(original)}
                    variant="outlined"
                    color="error"
                  >
                    <IconX />
                  </Button> */}
                </>
              );
            }

            if (status === 'finalized') {
              return (
                <>
                  <Button
                    onClick={() => handleViewIssuanceEntryTransaction(original)}
                    variant="outlined"
                  >
                    <IconEye />
                  </Button>
                  <Button
                    sx={{ ml: 1 }}
                    onClick={() => handleCancelTransaction(original)}
                    variant="outlined"
                    color="error"
                  >
                    <IconX />
                  </Button>
                </>
              );
            }

            if (status === 'floating' || status === 'checked') {
              if (
                userlevel === 'stockman' ||
                userlevel === 'checker' ||
                userlevel === 'customer' ||
                userlevel === 'superadmin'
              ) {
                return (
                  <Button
                    onClick={() => handleViewIssuanceEntryTransaction(original)}
                    variant="outlined"
                  >
                    <IconEye />
                  </Button>
                );
              }
              return (
                <>
                  <Button onClick={() => onOpenFloatingIssuance(original)} variant="outlined">
                    <IconDeviceDesktop />
                  </Button>
                  <Button
                    sx={{ ml: 1 }}
                    onClick={() => handleCancelTransaction(original)}
                    variant="outlined"
                    color="error"
                  >
                    <IconX />
                  </Button>
                </>
              );
            } else {
              return (
                <Button
                  onClick={() => handleViewIssuanceEntryTransaction(original)}
                  variant="outlined"
                >
                  <IconEye />
                </Button>
              );
            }

            return null;
          };

          return (
            <Grid container spacing={2}>
              <Grid item>{renderButton()}</Grid>
            </Grid>
          );
        },
      },
    ],
    [onOpenFloatingIssuance],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: { showColumnFilters: true },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiTablePaperProps: {
      sx: {
        maxWidth: 'min(100vw, 2200px)'
      }
    },
    muiToolbarAlertBannerProps: isError
      ? {
        color: 'error',
        children: 'Error loading data',
      }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableColumnOrdering: true,
    renderTopToolbarCustomActions: () => (
      <Box sx={{ p: '0px', display: 'flex', justifyContent: 'space-between', paddingLeft: '8px' }}>
        <Typography variant="h6" sx={{ alignSelf: 'center' }}>
          Ipo Issuance Table
        </Typography>

        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <Tooltip arrow title="Refresh Data">
            <IconButton onClick={() => refetch()}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    ),
    rowCount: meta?.total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  const navigate = useNavigate();
  const { setTransactionId } = useTransaction();

  const handleViewIssuanceEntryTransaction = (row: any) => {
    setTransactionId(row.id);
    navigate('/issuance-entry-view-transaction');
  };

  const handleCancelTransaction = (row: any) => {
    axiosClient
      .post(`/ie_transactions/cancel/${row.id}`)
      .then((response) => {
        console.log(response);
        refetch();
        onOpenFloatingIssuance(null);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOpenTest = (row: any) => {
    console.log('Open Test', row);
  };

  useImperativeHandle(onRefetchRef, () => ({
    refetch: () => {
      if (refetch) {
        refetch();
      }
    },
  }));

  // React.useImperativeHandle(onRefetchRef, () => ({
  //   refetch,
  // }));

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
};

const queryClient = new QueryClient();

const IpoIssuanceTableView: React.FC<IssuanceEntryTransactionsTableProps> = ({
  onOpenFloatingIssuance,
  onRefetchRef,
  userlevel,
}) => (
  <QueryClientProvider client={queryClient}>
    <IssuanceEntryTransactionsTable
      onOpenFloatingIssuance={onOpenFloatingIssuance}
      onRefetchRef={onRefetchRef}
      userlevel={userlevel}
    />
  </QueryClientProvider>
);

export default IpoIssuanceTableView;
