import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Divider,
  Paper,
  Grid,
} from '@mui/material';
import axiosClient from 'src/axios_client';
import dayjs from 'dayjs';
import { Input, Popconfirm, Popover, Steps, message, ConfigProvider } from 'antd';
import { theme } from 'antd/lib';
import {
  LoadingOutlined,
  SmileOutlined,
  SolutionOutlined,
  UserOutlined,
  FormOutlined,
} from '@ant-design/icons';
import { useSelector, AppState } from 'src/store/Store';
import DashboardCard from '../../dashboard/components/DashboardCard';
import ReactToPrint from 'react-to-print';
import { LogoDark, LogoLight } from 'src/globals/LogoExports';
import { formatDateWithTime } from 'src/globals/PublicFunctions';

interface Product {
  id: number;
  name: string;
  brand: string;
  model: string;
  quantity: number;
  status: number;
  serial_number: string;
  reference_number: string;
}

interface Transaction {
  id: number;
  reference_number: any;
  image: string | null;
  notes: string;
  pickup_by: string;
  destination: number;
  supply_center: any;
  created_by: {
    id: number;
    name: string;
  };
  checked_by: any;
  received_by: any;
  checked_at: any;
  received_at: any;
  received_note: string;
  checked_note: string;
  created_at: string;
  updated_at: string;
  products: Product[];
}

interface TransmittalBodyProps {
  transactionId: string | null;
  userlevel: string | undefined;
  onAcknowledge: () => void;
}

interface ErrorResponse {
  message: string;
  error: string;
}

const TransmittalBody: FC<TransmittalBodyProps> = ({ transactionId, userlevel, onAcknowledge }) => {
  const [transaction, setTransaction] = useState<Transaction | null>(null);
  const [note, setNote] = useState('');
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();

  // Use useSelector at the top level
  const customizer = useSelector((state: AppState) => state.customizer);
  const currentTheme = customizer.activeMode;

  const [isDarkMode, setIsDarkMode] = useState(currentTheme === 'dark');
  const { defaultAlgorithm, darkAlgorithm } = theme;

  useEffect(() => {
    setIsDarkMode(currentTheme === 'dark');
  }, [currentTheme]);

  const fetchData = useCallback(async () => {
    if (!transactionId) {
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      const response = await axiosClient.get(`te_transactions/${transactionId}`);
      const transactionData = response.data.data;
      setTransaction(transactionData);

      // Check if the current user has already acknowledged the transaction
      if (userlevel === 'checker' && transactionData.checked_by?.id) {
        setIsAcknowledged(true);
      } else if (userlevel === 'stockman' && transactionData.received_by?.id) {
        setIsAcknowledged(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      messageApi.open({
        type: 'error',
        content: `Contact your system administrator`,
        className: 'robotoFont',
        style: {
          marginTop: '20vh',
        },
      });
    } finally {
      setIsLoading(false);
    }
  }, [transactionId, userlevel, messageApi]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const acknowledge = async () => {
    if (!transactionId) return;

    try {
      const response = await axiosClient.post(`/te_transactions/${transactionId}/acknowledge`, {
        userlevel: userlevel,
        note: note,
      });
      console.log('Acknowledgment successful', response.data);
      messageApi.open({
        type: 'success',
        content: `Acknowledgment successful`,
        className: 'robotoFont',
        style: {
          marginTop: '20vh',
        },
      });
      setIsAcknowledged(true);
      onAcknowledge(); // Call the callback to refresh the parent component
      fetchData(); // Refresh the transaction data
    } catch (error) {
      console.error('Acknowledgment failed', error);
      const errorMessage =
        (error as { response?: { data?: ErrorResponse } }).response?.data?.error ||
        'An unexpected error occurred';
      messageApi.open({
        type: 'error',
        content: `Acknowledgment unsuccessful: ${errorMessage}`,
        className: 'robotoFont',
        style: {
          marginTop: '20vh',
        },
      });
    }
  };

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (!transaction) {
    return <Typography>No transaction data available.</Typography>;
  }

  const getCurrentStep = () => {
    if (transaction.received_by?.id) return 3;
    if (transaction.checked_by?.id) return 2;
    if (transaction.created_by?.id) return 1;
    return 0;
  };

  const getStepStatus = (step: number) => {
    const currentStep = getCurrentStep();
    if (step < currentStep) return 'finish';
    if (step === currentStep) return 'process';
    return 'wait';
  };

  const formatDate = (date: string | null) => {
    return date ? dayjs(date).format('MMMM D, YYYY h:mm A') : 'Pending';
  };

  const getName = (user: { id: number | null; name: string | null } | null) => {
    return user?.name || 'Pending';
  };

  // const componentRef = useRef(null);

  return (


    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        p: 4,
        bgcolor: 'background.paper',
        borderRadius: 1,
        boxShadow: 3,
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} md={9} id={'transactionData'}>
          {/* <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              padding: '10px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                mb: 4,
              }}
            >
              <Box>
                <Box sx={{ width: '250px', marginLeft: '-36px', marginBottom: '5px' }}>
                  {customizer.activeMode === 'dark' ? (
                    <LogoLight height={'auto'} />
                  ) : (
                    <LogoDark height={'auto'} />
                  )}
                </Box>
                <Typography
                  variant="h6"
                  component="p"
                  sx={{ color: 'text.primary', fontWeight: 'bold' }}
                >
                  RAEL KITZ CORPORATION - WMS IS
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  RRF Building
                  <br />
                  San Miguel Calasiao, Pangasinan
                  <br />
                  Tel No: +1 (123) 456 7891, +44 (876) 543 2198
                </Typography>
              </Box>

              <Box sx={{ textAlign: 'right' }}>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ color: 'text.primary', fontWeight: 'bold' }}
                >
                  TRANSACTION #: {transaction.reference_number}
                </Typography>
                <Typography variant="body1" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
                  <strong>Originating Station:</strong> {transaction.created_by.name}
                </Typography>
                <Typography variant="body1" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
                  <strong>Destination:</strong> {transaction.supply_center.name}
                </Typography>

                <Typography variant="body1" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
                  <strong>Transfer Date:</strong> {formatDate(transaction.created_at)}
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ color: 'text.primary', fontWeight: 'bold' }}
                >
                  HAULER: {transaction.pickup_by}
                </Typography>
              </Box>
            </Box> */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              padding: { sm: '20px', md: '0px' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                mb: 1,
              }}
            >
              <Box>
                <Box sx={{ width: '250px', marginLeft: '-36px', marginBottom: '5px' }}>
                  {customizer.activeMode === 'dark' ? (
                    <LogoLight height={'auto'} />
                  ) : (
                    <LogoDark height={'auto'} />
                  )}
                </Box>
                <Typography
                  component="p"
                  sx={{ color: 'text.primary', fontWeight: 'bold' }}
                  className="fontSize8pt"
                >
                  RAEL KITZ CORPORATION - WMS IS
                </Typography>

                <Typography
                  variant="body2"
                  sx={{ color: 'text.secondary', displayPrint: 'none' }}
                  className="fontSize8pt"
                >
                  RRF Building San Miguel Calasiao, Pangasinan
                  <br />
                  +1 (123) 456 7891, +44 (876) 543 2198
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ mt: 1, fontWeight: 'bold', textTransform: 'uppercase' }}
                  className="fontSize8pt"
                >
                  RETURN ITEMS
                </Typography>
              </Box>
              <Box sx={{ textAlign: 'right' }}>
                <Typography
                  variant="h6"
                  component="p"
                  sx={{ color: 'text.primary', fontWeight: 'bold' }}
                  className="fontSize8pt"
                >
                  REFERENCE NUMBER: {transaction.reference_number}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
                  Originating Station: {transaction.created_by.name} <br />
                  Destination: {transaction.supply_center.name}
                  {/* Transaction ID: {data.id} */}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: 'text.primary', fontWeight: 'bold' }}
                  className="fontSize8pt"
                >
                  Transfer Date: {formatDate(transaction.created_at)}
                </Typography>
                {/* <Typography
                  variant="body2"
                  sx={{ color: 'text.secondary', displayPrint: 'block' }}
                  className="fontSize8pt"
                >
                  Customer Code: {data.customer_code}
                  <br />
                  Contact Person: {data.customer_contact_person}
                  <br />
                </Typography> */}
              </Box>
            </Box>
            <Table
              className="printTable" sx={{ width: '100%', textAlign: 'left' }}
            >
              <TableHead sx={{ backgroundColor: 'grey.200' }}>
                <TableRow>
                  <TableCell
                    sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt"
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt"
                  >
                    Brand
                  </TableCell>
                  <TableCell
                    sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt"
                  >
                    Model
                  </TableCell>
                  <TableCell
                    sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt"
                  >
                    Quantity
                  </TableCell>
                  <TableCell
                    sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt"
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt"
                  >
                    Serial Number
                  </TableCell>
                  <TableCell
                    sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt"
                  >
                    Reference Number
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transaction.products.map((product) => (
                  <TableRow key={product.id}>
                    <TableCell
                      sx={{ py: 1, alignItems: 'center' }} className="fontSize8pt"
                    >
                      {product.name}
                    </TableCell>
                    <TableCell
                      sx={{ py: 1, alignItems: 'center' }} className="fontSize8pt"
                    >
                      {product.brand}
                    </TableCell>
                    <TableCell
                      sx={{ py: 1, alignItems: 'center' }} className="fontSize8pt"
                    >
                      {product.model}
                    </TableCell>
                    <TableCell
                      sx={{ py: 1, alignItems: 'center' }} className="fontSize8pt"
                    >
                      {product.quantity}
                    </TableCell>
                    <TableCell
                      sx={{ py: 1, alignItems: 'center' }} className="fontSize8pt"
                    >
                      {product.status === 0 ? 'DAMAGED' : 'GOOD'}
                    </TableCell>
                    <TableCell
                      sx={{ py: 1, alignItems: 'center' }} className="fontSize8pt"
                    >
                      {product.serial_number}
                    </TableCell>
                    <TableCell
                      sx={{ py: 1, alignItems: 'center' }} className="fontSize8pt"
                    >
                      {product.reference_number}
                    </TableCell>
                  </TableRow>
                ))}

                <TableRow>

                </TableRow>
              </TableBody>
            </Table>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Note: {transaction.notes}
            </Typography>

            <Divider sx={{ borderColor: '#acacac' }} />
            <Grid
              container
              columnSpacing={5}
              sx={{ fontSize: { md: '8pt', lg: '10pt' }, display: 'none', displayPrint: 'flex' }}
            >
              <Grid item xs={5} className="fontSize8pt">
                Prepared By:
                <Divider sx={{ borderColor: 'black', mt: { xs: 5 } }} />
              </Grid>
              <Grid item xs={7}>
                <Grid container columnSpacing={1}>
                  <Grid item className="fontSize8pt">
                    Checked By:
                  </Grid>
                  <Grid item flexGrow={1}>
                    <Grid container columnSpacing={2}>
                      <Grid item className="fontSize8pt">
                        Stockman
                      </Grid>
                      <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                        <Divider sx={{ borderColor: 'black' }} />
                      </Grid>
                    </Grid>
                    <Grid container columnSpacing={2}>
                      <Grid item className="fontSize8pt">
                        Checker
                      </Grid>
                      <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                        <Divider sx={{ borderColor: 'black' }} />
                      </Grid>
                    </Grid>
                    <Grid container columnSpacing={2} mt={1}>
                      <Grid item className="fontSize8pt">
                        Hauler Representative
                      </Grid>
                      <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                        <Divider sx={{ borderColor: 'black' }} />
                      </Grid>
                    </Grid>
                    <Grid container columnSpacing={2} mt={1}>
                      <Grid item className="fontSize8pt">
                        Customer Authorized Representative
                      </Grid>
                      <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                        <Divider sx={{ borderColor: 'black' }} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} mt={1}>
                <Grid container columnSpacing={1} justifyContent={'between'}>
                  <Grid item xs={8}>
                    <Typography sx={{ fontWeight: 'bold' }} className="fontSize8pt">
                      Received the above goods in full and no lost items.
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid item flexGrow={1}>
                      <Grid container columnSpacing={2}>
                        <Grid item className="fontSize8pt">
                          Received By:
                        </Grid>
                        <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                          <Divider sx={{ borderColor: 'black' }} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'center' }} mt={1}>
              <Divider sx={{ borderColor: 'black' }} />
            </Grid>

            <Grid item sx={{ textAlign: 'left' }}>
              <Typography
                variant="body1"
                sx={{ color: 'text.primary', fontWeight: 'bold' }}
                className="fontSize8pt"
              >
                Hauler: {transaction.pickup_by}
              </Typography>
            </Grid>

            <Grid item xs={12} sx={{ textAlign: 'center' }} mt={1}>
              <Grid container columnSpacing={1}>
                <Grid item>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
                    WAREHOUSE MANAGEMENT SYSTEM - by: INFORMATION SYSTEMS{' '}
                    {formatDateWithTime(transaction.created_at)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
                    Created By: {transaction.created_by.name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Steps
              progressDot
              current={getCurrentStep()}
              direction="vertical"
              items={[
                {
                  title: 'Created Obsolete Item Return',
                  description: (
                    <>
                      <div>{getName(transaction.created_by)}</div>
                      <div>{formatDate(transaction.created_at)}</div>
                    </>
                  ),
                },
                {
                  title: (
                    <>
                      <Popover
                        content={
                          transaction.checked_note === '' || transaction.checked_note == null
                            ? 'Notes will be here'
                            : transaction.checked_note
                        }
                      >
                        Cross-Checked by Checker
                      </Popover>
                    </>
                  ),
                  description: (
                    <>
                      <div>{getName(transaction.checked_by)}</div>
                      <div>{formatDate(transaction.checked_at)}</div>
                    </>
                  ),
                },
                {
                  title: (
                    <>
                      <Popover
                        content={
                          transaction.received_note === '' || transaction.received_note == null
                            ? 'Notes will be here'
                            : transaction.received_note
                        }
                      >
                        Received by Stockman
                      </Popover>
                    </>
                  ),
                  description: (
                    <>
                      <div>{getName(transaction.received_by)}</div>
                      <div>{formatDate(transaction.received_at)}</div>
                    </>
                  ),
                },
                {
                  title: 'Confirmation',
                  description: transaction.received_at ? 'Completed' : 'Pending',
                },
              ]}
            />
            {(userlevel === 'checker' ||
              userlevel === 'stockman' ||
              userlevel === 'superadmin') && (
                <Popconfirm
                  title="Acknowledge Transmittal"
                  description={
                    <>
                      <p>Are you sure you want to acknowledge this transmittal?</p>
                      <Input.TextArea
                        placeholder="Add a note (optional)"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        rows={4}
                      />
                    </>
                  }
                  onConfirm={acknowledge}
                  okText="Yes"
                  cancelText="No"
                  placement="right"
                >
                  <Button
                    variant="outlined"
                    disabled={isAcknowledged}
                    size='small'

                  >
                    {isAcknowledged ? 'Acknowledged' : 'Acknowledge'}
                  </Button>
                </Popconfirm>
              )}

            <ReactToPrint
              trigger={() => (
                <Button variant="contained" size="small" color="primary">
                  Print
                </Button>
              )}
              content={() => document.getElementById('transactionData')}
              pageStyle={`
                @media print {
                  body {
                    -webkit-print-color-adjust: exact;
                    margin: 20mm;
                    padding: 50px;
                  }
                  @page {
                    margin: 20mm;
                  }
                  .printTable th,
                  .printTable td {
                    padding: 2px;
                  }
                  header, footer {
                    display: none;
                  }
                  .pageBreak {
                    page-break-after: always;
                  }
                  .fontSize8pt {
                    font-size: 8pt !important;
                  }
                  tbody tr:not(:last-child) td {
                    border-bottom: 1px solid #acacac;
                  }
                  .printable-logo {
                    fill: #000000 !important; /* Ensure the logo prints in black */
                  }
                }
              `}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TransmittalBody;
