// contextProviders.tsx
import React, { ReactNode } from 'react';
import { TransactionIdProvider } from 'src/contexts/PurchaseTransactionContext';
import { SupplierPurchaseProvider } from 'src/contexts/SupplierPurchasesContext';
import { ProductPurchaseProvider } from 'src/contexts/ProductPurchasesContext';
import { CustomerSalesProvider } from 'src/contexts/CustomerSalesContext';
import { CustomerReturnsProvider } from 'src/contexts/CustomerReturnsContext';
import { ProductSalesProvider } from 'src/contexts/ProductSalesContext';
import { AuthProvider } from 'src/contexts/AuthProvider';
import { EssentialDataProvider } from './EssentialDataContext';
import { TransmittalIdProvider } from './TransmittalTransactionContext';
import { ProductReturnsProvider } from './ProductReturnsContext';
import { ReturnTransmitalProvider } from './ReturnTransmital';
import { NotificationProvider } from './NotificationsContext';

interface CombinedProvidersProps {
  children: ReactNode;
}

export const CombinedProviders: React.FC<CombinedProvidersProps> = ({ children }) => (
  <AuthProvider>
    <EssentialDataProvider>
      <NotificationProvider>
        <TransactionIdProvider>
          <SupplierPurchaseProvider>
            <ProductPurchaseProvider>
              <CustomerSalesProvider>
                <CustomerReturnsProvider>
                  <TransmittalIdProvider>
                    <ProductSalesProvider>
                      <ReturnTransmitalProvider>
                        <ProductReturnsProvider>{children}</ProductReturnsProvider>

                      </ReturnTransmitalProvider>
                    </ProductSalesProvider>
                  </TransmittalIdProvider>
                </CustomerReturnsProvider>
              </CustomerSalesProvider>
            </ProductPurchaseProvider>
          </SupplierPurchaseProvider>
        </TransactionIdProvider>
      </NotificationProvider>
    </EssentialDataProvider>
  </AuthProvider>
);
