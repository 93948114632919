import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';

interface ConfirmationButtonProps {
  onConfirm: () => void; // Function to execute on confirmation
  title?: string; // Dialog title
  description?: string; // Dialog description
  buttonText?: string; // Text for the button
  startIcon?: React.ReactNode; // Optional start icon for the button
  buttonProps?: React.ComponentProps<typeof Button>; // Props for the button
  confirmButtonText?: string; // Text for the confirm button
  cancelButtonText?: string; // Text for the cancel button
}

const ConfirmationButton: React.FC<ConfirmationButtonProps> = ({
  onConfirm,
  title = 'Confirm Action',
  description = 'Are you sure you want to perform this action?',
  buttonText = 'Click Me',
  startIcon,
  buttonProps,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleConfirm = () => {
    onConfirm(); // Trigger the confirmation action
    handleClose();
  };

  return (
    <>
      <IconButton {...buttonProps} size='small' onClick={handleOpen}/*  startIcon={startIcon} */>
        {startIcon}{/* {buttonText} */}
      </IconButton>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {cancelButtonText}
          </Button>
          <Button onClick={handleConfirm} color="error" variant="contained">
            {confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationButton;
