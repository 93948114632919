import { Alert, Box, Button, Card, Grid, Stack, Typography } from '@mui/material'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import axiosClient from 'src/axios_client'
import PageContainer from 'src/components/container/PageContainer'
import CustomFormLabel from 'src/components/theme-elements/CustomFormLabel'
import CustomTextField from 'src/components/theme-elements/CustomTextField'
import { LogoDark, LogoLight } from 'src/globals/LogoExports'
import * as yup from 'yup';

type Props = {}

const validationSchema = yup.object({
    email: yup
        .string()
        .required('Email is required')
});

function ForgotPassword({ }: Props) {

    const [sucess, setSucess] = useState('');
    const [error, setError] = useState('');

    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema,
        onSubmit: async (values) => {
            try {
                const payload = {
                    email: values.email,
                };

                const response = await axiosClient.post(`/password_reset`, payload);


                setSucess(response.data.message);

                setTimeout(() => {
                    navigate('/');
                }, 2000)
                // setStatusCode(response.status);
                // refetch();
                // onClose();
            } catch (error: any) {
                setError(error.response.data.message);

                setTimeout(() => {
                    setError('');
                }, 3000)

                // if (error.response && error.response.status === 422) {
                //     setStatusData(error.response.data);
                // }
                // setStatusCode(error.response?.status || 500);
            }
        },
    });

    return (
        <PageContainer title="Forgot Password" description="this is Forgot Password page">
            <Box
                sx={{
                    position: 'relative',
                    '&:before': {
                        content: '""',
                        background: 'radial-gradient(#d2f1df, #d3d7fa, #bad8f4)',
                        backgroundSize: '400% 400%',
                        animation: 'gradient 15s ease infinite',
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        opacity: '0.3',
                    },
                }}
            >
                <Grid container spacing={0} justifyContent="center" sx={{ height: '100vh' }}>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        lg={4}
                        xl={3}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Card elevation={9} sx={{ p: 4, zIndex: 1, width: '100%', maxWidth: '500px' }}>
                            <Box display="flex" alignItems="center" justifyContent="center" sx={{ marginBottom: 2 }}>
                                <LogoDark height={'auto'} />
                            </Box>
                            <Typography
                                color="textSecondary"
                                textAlign="center"
                                variant="subtitle2"
                                fontWeight="400"
                            >
                                Please enter the email address associated with your account and We will email you a
                                link to reset your password.
                            </Typography>
                            <form onSubmit={formik.handleSubmit}>
                                <Stack mt={0} spacing={1}>
                                    {sucess && (
                                        <Alert variant="filled" severity="success" sx={{ alignItems: 'center', mb: 1 }}>
                                            {sucess}
                                        </Alert>
                                    )}

                                    {error && (
                                        <Alert variant="filled" severity="error" sx={{ alignItems: 'center', mb: 1 }}>
                                            {error}
                                        </Alert>
                                    )}

                                    <CustomFormLabel htmlFor="reset-email">Email Address</CustomFormLabel>
                                    <CustomTextField
                                        id="email"
                                        variant={'outlined'}
                                        fullWidth size={'small'}
                                        value={formik.values.email}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />

                                    <Button color="primary" variant="contained" size="small" fullWidth type='submit'>
                                        Send Link
                                    </Button>
                                    <Button color="primary" size="small" fullWidth component={Link} to="/auth/login">
                                        Back to Login
                                    </Button>
                                </Stack>
                            </form>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </PageContainer>
    )
}

export default ForgotPassword