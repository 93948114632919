import {
  Box,
  IconButton,
  Tooltip,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  DialogActions,
  Autocomplete,
  Alert,
  Typography,
  Badge,
  Chip,
  MenuItem,
  ListItemIcon,
  Snackbar,
} from '@mui/material';
import { AppState, useSelector } from 'src/store/Store';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';
import ProductCategoryDialog from '../product-categories/components/ProductCategoryDialog';
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import axiosClient from 'src/axios_client';
import { IconEdit, IconEye, IconPlus, IconTrash } from '@tabler/icons';
import { Build, RefreshOutlined, CloudUpload, QrCode2 } from '@mui/icons-material';
import CustomFormLabel from 'src/components/theme-elements/CustomFormLabel';
import CustomTextField from 'src/components/theme-elements/CustomTextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CustomCheckbox from 'src/components/theme-elements/CustomCheckbox';
import { formatCurrency } from 'src/globals/NumericFormat';
import { Image, message } from 'antd';
import { useEssentialDataContext } from 'src/contexts/EssentialDataContext';
import { FileExcelFilled } from '@ant-design/icons';

type ProductApiResponse = {
  data: Array<Product>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

type Product = {
  id: number;
  name: string;
  sku: any;
  description: string;
  supplier: string;
  has_serial: any;
  supplier_id: any;
  supplier_name: any;
  quantity: string;
  unit: any;
  lead_time: any;
  buying_price: string;
  stock_status: string;
  is_adjusting: any;
  image: any;
  minimum_stock: number;
  supply_center: any;
  brand_name: string;
  category: any;
};

const statusSelect = [
  { label: 'No Stock', value: '1' },
  { label: 'Low Stock', value: '2' },
  { label: 'On Stock', value: '3' },
  { label: 'Ongoing Adjustment', value: '4' },
];

type SupplyCenterOptions = {
  label: any;
  value: any;
};


const ProductCategoriesTable = ({ supplyCenters }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const customizer = useSelector((state: AppState) => state.customizer);
  const currentTheme = customizer.activeMode;

  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [productData, setProductData] = useState<Product | null>(null);


  useEffect(() => {
    setIsDarkMode(currentTheme === 'dark');
  }, [currentTheme]);



  console.warn('DarkMode: ', isDarkMode);

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(() => {
    const savedFilters = localStorage.getItem('productTableFilters');
    return savedFilters ? JSON.parse(savedFilters) : [];
  });

  const [globalFilter, setGlobalFilter] = useState(() => {
    const savedGlobalFilter = localStorage.getItem('productTableGlobalFilter');
    return savedGlobalFilter || '';
  });



  // const [globalFilter, setGlobalFilter] = useState('');

  const [sorting, setSorting] = useState<MRT_SortingState>(() => {
    const savedSorting = localStorage.getItem('productTableSorting');
    return savedSorting ? JSON.parse(savedSorting) : [];
  });

  const [pagination, setPagination] = useState<MRT_PaginationState>(() => {
    const savedPagination = localStorage.getItem('productTablePagination');
    return savedPagination ? JSON.parse(savedPagination) : { pageIndex: 0, pageSize: 10 };
  });

  useEffect(() => {
    localStorage.setItem('productTableFilters', JSON.stringify(columnFilters));
  }, [columnFilters]);

  useEffect(() => {
    // Only save non-empty filters to localStorage
    if (globalFilter) {
      localStorage.setItem('productTableGlobalFilter', globalFilter);
    } else {
      localStorage.removeItem('productTableGlobalFilter');
    }
  }, [globalFilter]);

  const resetFilters = useCallback(() => {
    setGlobalFilter('');
    setColumnFilters([]);
    setPagination({ pageIndex: 0, pageSize: 10 });
    setSorting([]);
    localStorage.removeItem('productTableGlobalFilter');
    localStorage.removeItem('productTableFilters');
    localStorage.removeItem('productTableSorting');
    localStorage.removeItem('productTablePagination');
  }, []);

  useEffect(() => {
    localStorage.setItem('productTableSorting', JSON.stringify(sorting));
  }, [sorting]);

  useEffect(() => {
    localStorage.setItem('productTablePagination', JSON.stringify(pagination));
  }, [pagination]);

  const [deleteId, setDeleteId] = useState();

  const {
    data: { data = [], pagination: meta } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<ProductApiResponse>({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      try {
        const params = {
          page: `${pagination.pageIndex + 1}`,
          per_page: `${pagination.pageSize}`,
          filters: JSON.stringify(columnFilters ?? []),
          globalFilter: String(globalFilter || ''),
          sorting: JSON.stringify(sorting ?? []),
        };

        const response = await axiosClient.get<ProductApiResponse>('/products', { params });

        console.log(response.data);

        return response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    },
    placeholderData: keepPreviousData,
  });

  const columns = useMemo<MRT_ColumnDef<Product>[]>(
    () => [
      // {
      //   accessorKey: 'id',
      //   header: 'DB ID',
      //   size: 40,
      // },
      {
        accessorKey: 'image',
        header: 'Image',
        size: 40,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ row }) => {
          return (
            <Image
              width={50}
              alt=""
              src={row.original.image} // Secure image URL from Laravel
              preview={{
                width: 'auto',
                src: row.original.image,
              }}
            />
          );
        },
      },
      {
        accessorKey: 'name',
        header: 'Name',
        size: 100,
        muiTableBodyCellProps: ({ row }) => ({
          onClick: (event) => {

            if (userData?.userlevel == 'encoder') {
              message.warning('You want access to this? Contact your administrator')
            }
            else {
              navigate(`/product-master/view/${row.original.sku}`);
            }
          },
          sx: {
            cursor: 'pointer', //you might want to change the cursor too when adding an onClick
          },
        }),
        Cell: ({ row }) => {
          return (
            <Typography
              variant="subtitle1"
              sx={{
                textDecoration: 'underline',
                color: 'blue',
                wordBreak: 'break-word',
                whiteSpace: 'pre-wrap',
              }}
            >
              {row.original.name}
            </Typography>
          );
        },
      },
      {
        accessorKey: 'sku',
        header: 'SKU',
        size: 50,
      },
      {
        accessorKey: 'description',
        header: 'Description',
        size: 80,
        Cell: ({ row }) => {
          return (
            <Typography
              variant="body2"
              sx={{
                wordBreak: 'break-word', // Break long words to fit the width
                whiteSpace: 'pre-wrap', // Preserve new lines and break lines properly
              }}
            >
              {row.original.description}
            </Typography>
          );
        },
      },

      // {
      //   accessorKey: 'supplier_name',
      //   header: 'Supplier',
      //   size: 80,
      // },
      {
        accessorKey: 'brand_name',
        header: 'Brand',
        size: 50,
        minWidth: 50, // Explicitly set minimum width
        maxWidth: 50, // Explicitly set maximum width
        width: 50, // Set a fixed width
        Cell: ({ row }) => (
          <>
            {row.original.brand_name !== null ? (
              row.original.brand_name
            ) : (
              <Chip variant="filled" label="unbranded" color="error" />
            )}
          </>
        ),
      },
      {
        accessorKey: 'stock_status',
        header: 'Stock Status',
        size: 50,
        minWidth: 50, // Match minWidth to brand_name
        maxWidth: 50, // Match maxWidth to brand_name
        width: 50, // Match width to brand_name
        filterVariant: 'select',
        filterSelectOptions: statusSelect,
        Cell: ({ row }) => {
          const status = row.original.stock_status;
          let color: 'error' | 'success' | 'default';
          let label = '';

          switch (status) {
            case 'LS':
              color = 'error';
              label = 'Low Stock';
              break;
            case 'OS':
              color = 'success';
              label = 'On Stock';
              break;
            case 'NS':
              color = 'default';
              label = 'No Stock';
              break;
            default:
              color = 'default';
              label = status;
          }

          return (
            <Grid container columnGap={1}>
              <Chip
                label={label}
                color={color}
                size="small"
                sx={{
                  fontSize: '0.8rem',
                  height: '22px',
                  minWidth: '50px',
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-wrap',
                  ...(status === 'NS' && {
                    bgcolor: 'black',
                    color: 'white',
                    '&:hover': {
                      bgcolor: 'black',
                    },
                  }),
                }}
              />
              {row.original.is_adjusting == 1 && (
                <Chip
                  label="Adjustment Ongoing"
                  color="info"
                  size="small"
                  sx={{
                    fontSize: '0.8rem',
                    height: '22px',
                    minWidth: '50px',
                  }}
                />
              )}
            </Grid>
          );
        },
      },
      {
        accessorKey: 'category',
        header: 'Category',
        enableSorting: false,
        enableColumnDragging: false,
        enableColumnFilter: false,
        size: 50,
        Cell: ({ row }) => <Typography>{row.original.category.name}</Typography>,
      },
      {
        accessorKey: 'lead_time',
        header: 'Lead Time',
        enableSorting: false,
        enableColumnDragging: false,
        enableColumnFilter: false,
        size: 50,
        Cell: ({ row }) => <Typography>{row.original.lead_time}</Typography>,
      },
      {
        accessorKey: 'minimum_stock',
        header: 'Min. Stock',
        enableSorting: false,
        enableColumnDragging: false,
        enableColumnFilter: false,
        size: 50,
        Cell: ({ row }) => <Typography>{row.original.minimum_stock}</Typography>,
      },
      {
        accessorKey: 'quantity',
        header: 'Qty.',
        enableSorting: false,
        enableColumnDragging: false,
        enableColumnFilter: false,
        size: 10,
        Cell: ({ row }) => (
          <Typography>
            {row.original.quantity} {row.original.unit}
          </Typography>
        ),
      },
      {
        accessorKey: 'buying_price',
        header: 'Price',
        enableSorting: false,
        enableColumnDragging: false,
        enableColumnFilter: false,
        size: 10,
        Cell: ({ row }) => <Typography>{formatCurrency(row.original.buying_price)}</Typography>,
      },

      {
        accessorKey: 'sc_id',
        header: 'Supply Center',
        size: 180,
        filterVariant: 'select',
        filterSelectOptions: supplyCenters,
        Cell: ({ row }) => row.original.supply_center.name,
      },

      // {
      //   accessorKey: 'actions',
      //   header: 'Actions',
      //   size: 120,
      //   enableSorting: false,
      //   enableColumnActions: false,
      //   Cell: ({ row }) => (
      //     <Grid container spacing={2}>
      //       <Grid item>
      //         <Button onClick={() => handleProduct(row.original)} variant="outlined">
      //           <IconEdit />
      //         </Button>
      //       </Grid>
      //       <Grid item>
      //         <Button onClick={() => handleDeleteProduct(row.original.id)} variant="outlined">
      //           <IconTrash />
      //         </Button>
      //       </Grid>
      //     </Grid>
      //   ),
      // },
    ],
    [],
  );

  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [alertInfo, setAlertInfo] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error' | 'info' | 'warning';
  }>({
    open: false,
    message: '',
    severity: 'info',
  });

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      // Check if file is Excel format
      if (
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'application/vnd.ms-excel'
      ) {
        setSelectedFile(file);
        setUploadError(null);
      } else {
        setUploadError('Please select only Excel files (.xlsx or .xls)');
        setSelectedFile(null);
      }
    }
  };

  const { userData } = useEssentialDataContext();
  const handleCloseAlert = () => {
    setAlertInfo((prev) => ({ ...prev, open: false }));
  };

  const showAlert = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setAlertInfo({
      open: true,
      message,
      severity,
    });
  };

  const handleGetExcelFormat = async () => {
    try {
      const response = await axiosClient.get('/dl-sample-import-product', {
        responseType: 'blob', // Ensures the response is handled as binary data
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Create a link to download the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'SampleImportProduct.xlsx'); // Set filename
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setUploadError('Please select a file first');
      return;
    }

    setIsUploading(true);
    const formData = new FormData();
    formData.append('excel_file', selectedFile);

    try {
      const response = await axiosClient.post('/import-products', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Handle successful response
      setOpenUploadModal(false);
      setSelectedFile(null);

      // Show success message with duplicate information if any
      const successMessage = `${response.data.message}${response.data.duplicates ? ` (${response.data.duplicates})` : ''
        }`;
      showAlert(successMessage, 'success');

      refetch(); // Refresh the table data
    } catch (error: any) {
      // Handle error response
      const errorMessage =
        error.response?.data?.error || error.response?.data?.message || 'Error uploading file';
      setUploadError(errorMessage);
      showAlert(errorMessage, 'error');
    } finally {
      setIsUploading(false);
    }
  };

  const [availableSerial, setAvailableserial] = useState<any[]>([]);
  const [openAvailableSerial, setOpenAvailableSerial] = useState(false);
  const handleAvailableSerialClose = () => {
    setOpenAvailableSerial(false);
  }
  const openSerialModal = (serialNumbers: any) => {
    console.log(serialNumbers);
    setAvailableserial(serialNumbers);
    setOpenAvailableSerial(true);

  }

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: {
      showColumnFilters: true,

      density: 'compact',
      columnPinning: {
        left: [],
        right: ['mrt-row-actions'],
      },
      showGlobalFilter: true,
    },
    muiTablePaperProps: {
      sx: {
        maxWidth: 'min(100vw, 2200px)'
      }
    },
    renderRowActionMenuItems: ({ closeMenu, row }) => {

      if (userData?.userlevel != 'control' && userData?.userlevel != 'superadmin' && userData?.userlevel != 'encoder') {
        return [
          <MenuItem key={0} disabled sx={{ m: 0 }}>
            <ListItemIcon>
              <Build />
            </ListItemIcon>
            Adjust
          </MenuItem>,
          <MenuItem key={0} disabled sx={{ m: 0 }}>
            <ListItemIcon>
              <Build />
            </ListItemIcon>
            Cancel adjustment
          </MenuItem>,
          <MenuItem key={0} disabled sx={{ m: 0 }}>
            <ListItemIcon>
              <IconEye />
            </ListItemIcon>
            View Serial
          </MenuItem>,
        ];
      } else {
        return [
          <MenuItem
            key={0}
            disabled={row.original.is_adjusting ? true : false}
            onClick={() => {
              axiosClient
                .post(`/products/convert/${row.original.id}`)
                .then(({ data, status }) => {
                  console.log(data);

                  refetch();
                  closeMenu();
                })
                .catch((error) => {
                  console.log(error);
                });
            }}
            sx={{ m: 0, display: userData?.userlevel == 'encoder' ? 'none' : 'block' }}
          >
            <ListItemIcon>
              <Build />
            </ListItemIcon>
            Adjust
          </MenuItem>,
          <MenuItem
            key={0}

            disabled={row.original.is_adjusting ? false : true}
            onClick={() => {
              axiosClient
                .post(`/products/cancel_adjustment/${row.original.id}`)
                .then(({ data, status }) => {
                  console.log(data);

                  refetch();
                  closeMenu();
                })
                .catch((error) => {
                  console.log(error);
                });
            }}
            sx={{ m: 0, display: userData?.userlevel == 'encoder' ? 'none' : 'block' }}
          >
            <ListItemIcon>
              <Build />
            </ListItemIcon>
            Cancel Adjustment
          </MenuItem>,
          <MenuItem
            key={0}
            disabled={row.original.has_serial ? false : true}
            onClick={() => {
              const payload = {
                product_id: row.original.id,

                serial_status: 'available'
              }
              axiosClient
                .get(`/check_serial_number?per_page=all`, {
                  params: payload
                })
                .then(({ data, status }) => {
                  openSerialModal(data.data);
                  // refetch();
                  closeMenu();
                })
                .catch((error) => {
                  console.log(error);
                });
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <IconEye />
            </ListItemIcon>
            View Serial
          </MenuItem>,
        ];
      }
    },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableRowActions: true,
    muiToolbarAlertBannerProps: isError
      ? {
        color: 'error',
        children: 'Error loading data',
      }
      : undefined,
    onColumnFiltersChange: (updater) => {
      const newFilters = typeof updater === 'function' ? updater(columnFilters) : updater;
      setColumnFilters(newFilters);
    },
    enableGlobalFilter: true,
    globalFilterFn: 'contains',
    onGlobalFilterChange: (value) => {
      // Reset to first page when filter changes
      setPagination((prev) => ({
        ...prev,
        pageIndex: 0,
      }));
      setGlobalFilter(String(value || ''));
    },
    muiSearchTextFieldProps: {
      placeholder: 'Search products',
      sx: { minWidth: '300px' },
      variant: 'outlined',
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableColumnOrdering: true,
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
        <Tooltip arrow title="Refresh Data">
          <IconButton
            onClick={() => {
              resetFilters();

              refetch();
            }}
          >
            <RefreshOutlined />
          </IconButton>
        </Tooltip>

        <Button variant="contained" color="primary" to="/product-entry" component={Link}>
          Add New Entry
        </Button>

        <Button
          variant="contained"
          color="secondary"
          startIcon={<CloudUpload />}
          onClick={() => setOpenUploadModal(true)}
        >
          Import Products
        </Button>

        <Button variant="contained" onClick={downloadQRCodePdf} endIcon={<QrCode2 />}>
          Generate QR
        </Button>

        {/* File Upload Modal */}
        <Dialog
          open={openUploadModal}
          onClose={() => {
            setOpenUploadModal(false);
            setUploadError(null);
            setSelectedFile(null);
          }}
        >
          <DialogTitle>Import Products from Excel</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please select an Excel file (.xlsx or .xls) containing the product data to import.
            </DialogContentText>
            {uploadError && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {uploadError}
              </Alert>
            )}
            <Box sx={{ mt: 2 }}>
              <input
                accept=".xlsx,.xls"
                style={{ display: 'none' }}
                id="excel-file-upload"
                type="file"
                onChange={handleFileSelect}
              />
              <label htmlFor="excel-file-upload">
                <Button variant="outlined" component="span" startIcon={<CloudUpload />} fullWidth>
                  Select File
                </Button>
              </label>
              {selectedFile && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Selected file: {selectedFile.name}
                </Typography>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button startIcon={<FileExcelFilled />} onClick={handleGetExcelFormat}>
              Download Excel Format
            </Button>
            <Button
              onClick={() => {
                setOpenUploadModal(false);
                setUploadError(null);
                setSelectedFile(null);
              }}
              color="error"
            >
              Cancel
            </Button>
            <Button onClick={handleUpload} disabled={!selectedFile || isUploading} color="primary">
              {isUploading ? 'Uploading...' : 'Upload'}
            </Button>
          </DialogActions>
        </Dialog>

        {/* <Button variant="contained" color="success" onClick={downloadFormClicked}>
          Download Form
        </Button> */}
        {/* <Tooltip arrow title="Add New Product Category">
          <IconButton onClick={handleAddProduct}>
            <IconPlus />
          </IconButton>
        </Tooltip> */}

        <Snackbar
          open={alertInfo.open}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={handleCloseAlert}
            severity={alertInfo.severity}
            sx={{ width: '100%' }}
            variant="filled"
          >
            {alertInfo.message}
          </Alert>
        </Snackbar>
      </Box>
    ),
    rowCount: meta?.total ?? 0,
    state: {
      columnFilters,
      globalFilter: globalFilter || '',
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  const downloadFormClicked = () => {
    axiosClient
      .get(`/products/download/form`, {
        responseType: 'arraybuffer',
      })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'ProductFormat.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleProduct = useCallback((record: any) => {
    setProductData(record);
    setIsEditing(true);
    setOpenProductModal(true);
  }, []);

  const handleAddProduct = useCallback(() => {
    setProductData(null);
    setIsEditing(false);
    setOpenProductModal(true);
  }, []);

  const handleCloseProductModal = useCallback(() => {
    setOpenProductModal(false);
  }, []);

  const handleSubmit = useCallback((payload: any) => {
    console.log('Payload:', payload);
    setOpenProductModal(false);
  }, []);

  const handleDeleteProduct = useCallback((id: any) => {
    console.log('Delete action for record ID:', id);
    setDeleteId(id);
    setOpenDeleteDialog(true);
  }, []);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleDeletedialogClose = () => {
    setOpenDeleteDialog(false);
  };
  const handleProductDelete = async () => {
    axiosClient
      .delete(`/products/${deleteId}`)
      .then((response) => {
        console.log(response);

        if (response.status == 204) {
          setOpenDeleteDialog(false);
          refetch();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const downloadQRCodePdf = async () => {
    try {
      const response = await axiosClient.get(
        '/generate-prodqrcode-pdf/download', // Use the relative path
        {
          responseType: 'blob', // Ensures the response is a Blob (binary data)
        },
      );

      // Create a link element, set URL, and trigger download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'products_with_qrcodes.pdf'); // Set the filename
      document.body.appendChild(link);
      link.click();

      // Clean up
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  return (
    <>
      <Dialog
        open={openDeleteDialog}
        onClose={handleDeletedialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Delete Confirmation'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleDeletedialogClose}>
            Cancel
          </Button>
          <Button onClick={handleProductDelete} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAvailableSerial}
        onClose={handleAvailableSerialClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Available serials on ${availableSerial[0]?.product_name}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              availableSerial.map((serials: any, index) => {

                return (
                  <Typography key={index}>{serials.serial_number}</Typography>
                )
              })
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAvailableSerialClose} autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>

      <ProductModal
        isOpen={openProductModal}
        initialData={productData}
        onClose={handleCloseProductModal}
        onSubmit={handleSubmit}
        refetch={refetch}
      />

      <MaterialReactTable table={table} />
    </>
  );
};

// const validationSchema = Yup.object().shape({
//   name: Yup.string().required('Geofence name is required'),
//   description: Yup.string().required('Geofence description is required'),
// });

type ProductModalProps = {
  isOpen: boolean;
  initialData: {
    id: any;
    name: string;
    description: string;
    supplier_id: any;
    supplier_name: any;
    buying_price: any;
  } | null;
  onClose: () => void;
  onSubmit: (payload: {
    id: any | null;
    name: string;
    description: string;
    supplier_id: any;
    supplier_name: any;
    buying_price: any;
  }) => void;
  refetch: () => void;
};

const ProductModal: React.FC<ProductModalProps> = ({
  isOpen,
  initialData,
  onClose,
  onSubmit,
  refetch,
}) => {
  const [isSearching, setIsSearching] = useState(false);
  const [isEmptySearch, setIsEmptySearch] = useState(false);
  const [supplierOptions, setSupplierOptions] = useState<{ id: string; name: any }[]>([]);
  const debounceTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [error, setError] = useState('');

  const formik = useFormik({
    initialValues: {
      id: initialData?.id || '',
      name: initialData?.name || '',
      description: initialData?.description || '',
      supplier_id: initialData?.supplier_id || '',
      supplier_name: initialData?.supplier_name || '',
      buying_price: initialData?.buying_price || '',
    },

    // validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (formik.isValid) {
        if (values.id) {
          axiosClient
            .put(`/products/${values.id}`, values)
            .then((response) => {
              console.log(response);

              onSubmit(values);
              refetch();
            })
            .catch((error) => {
              setError(error.response.data.message);
              console.log(error);
            });
        }
        console.log(values);
      } else {
        console.log('Form invalid');
      }
    },
  });

  useEffect(() => {
    const newSupplier = { id: initialData?.supplier_id, name: initialData?.supplier_name };
    setSupplierOptions((prevOptions) => [...prevOptions, newSupplier]);
    formik.setValues({
      id: initialData?.id || '',
      name: initialData?.name || '',
      description: initialData?.description || '',
      supplier_id: initialData?.supplier_id || '',
      supplier_name: initialData?.supplier_name || '',
      buying_price: initialData?.buying_price || '',
    });
  }, [initialData]);

  const autocompleteSearchChange = (searchText: any, apiText: any) => {
    setIsSearching(true);
    setIsEmptySearch(false);
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      const value = searchText.target.value;
      fetchOptions(value, apiText);
    }, 500);
  };
  const fetchOptions = async (searchText: string, apiText: string) => {
    try {
      const { status, data } = await axiosClient.get(`/${apiText}`, {
        params: { globalFilter: searchText, per_page: 10 },
      });
      if (status == 200) {
        setIsSearching(false);
        if (data.data.length == 0) {
          setIsEmptySearch(true);
        }
        const fetchedData = data.data.map((innerData: any) => ({
          id: innerData.id,
          name: innerData.name,
        }));
        setSupplierOptions(fetchedData);
      }
    } catch (error) {
      console.error('Failed to fetch product options:', error);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={'sm'}>
      <DialogTitle>Edit Product</DialogTitle>

      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <DialogContentText>Please provide product details correctly</DialogContentText>
          {error && (
            <Alert variant="filled" severity="error" sx={{ alignItems: 'center' }}>
              {error}
            </Alert>
          )}

          <Grid container rowSpacing={2} mt={1}>
            <Grid item xs={8} sm={3} display="flex" alignItems="center">
              <CustomFormLabel htmlFor="fs-sku" sx={{ mt: 0, mb: { xs: '-10px', sm: 0 } }}>
                Name
              </CustomFormLabel>
            </Grid>
            <Grid item xs={12} sm={9}>
              <CustomTextField
                id="name"
                size="small"
                variant="standard"
                label="Item name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                fullWidth
              />
            </Grid>

            <Grid item xs={8} sm={3} display="flex" alignItems="center">
              <CustomFormLabel htmlFor="fs-sku" sx={{ mt: 0, mb: { xs: '-10px', sm: 0 } }}>
                Description
              </CustomFormLabel>
            </Grid>
            <Grid item xs={12} sm={9}>
              <CustomTextField
                id="description"
                size="small"
                variant="standard"
                label="Item description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={3} display="flex" alignItems="center">
              <CustomFormLabel htmlFor="fs-uname" sx={{ mt: 0, mb: { xs: '-10px', sm: 0 } }}>
                Supplier
              </CustomFormLabel>
            </Grid>
            <Grid item xs={12} sm={9} sx={{ height: { lg: '50px' }, alignContent: { lg: 'end' } }}>
              <Autocomplete
                id="supplier_id"
                noOptionsText={
                  isSearching
                    ? 'Searching...'
                    : isEmptySearch
                      ? `Can't find search word`
                      : 'Type to search'
                }
                value={formik.values.supplier_id || null}
                onChange={(_, newValue) => {
                  formik.setFieldValue('supplier_id', newValue);
                  if (newValue === null) {
                    setSupplierOptions([]);
                  }
                }}
                onBlur={() => formik.setFieldTouched('supplier_id', true)}
                options={supplierOptions.map((p) => p.id)}
                fullWidth
                getOptionLabel={(option) => {
                  return supplierOptions.find((p) => p.id === option)?.name ?? '';
                }}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    error={formik.touched.supplier_id && Boolean(formik.errors.supplier_id)}
                    helperText={formik.touched.supplier_id && formik.errors.supplier_id}
                    onChange={(e: any) => autocompleteSearchChange(e, 'suppliers')}
                    variant="standard"
                    placeholder="Supplier Name"
                    aria-label="Supplier Name"
                  />
                )}
              />
            </Grid>

            <Grid item xs={8} sm={3} display="flex" alignItems="center">
              <CustomFormLabel htmlFor="fs-sku" sx={{ mt: 0, mb: { xs: '-10px', sm: 0 } }}>
                Buying Price
              </CustomFormLabel>
            </Grid>
            <Grid item xs={12} sm={9}>
              <CustomTextField
                id="buying_price"
                size="small"
                variant="standard"
                label="Item description"
                value={formik.values.buying_price}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.buying_price && Boolean(formik.errors.buying_price)}
                helperText={formik.touched.buying_price && formik.errors.buying_price}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const queryClient = new QueryClient();

const ProductTableList = () => {

  const [supplyCenterOptions, setSupplyCenterOptions] = useState<SupplyCenterOptions[]>([]);
  useEffect(() => {

    async function fetchMyAPI() {
      axiosClient.get('/supply_centers')
        .then(response => {
          const dataResponse = response.data.data;

          const transformedData = dataResponse.map((item: any) => ({
            value: item.id,
            label: item.name
          }));


          setSupplyCenterOptions(transformedData);

        })
        .catch((err) => {
          console.log(err);
        });
    }

    fetchMyAPI()

  }, [])

  const { userData } = useEssentialDataContext();

  return (
    <Box>
      {
        userData?.userlevel == 'superadmin' ? (supplyCenterOptions.length > 1 && <QueryClientProvider client={queryClient}>
          <ProductCategoriesTable supplyCenters={supplyCenterOptions} />
        </QueryClientProvider>) : (
          <QueryClientProvider client={queryClient}>
            <ProductCategoriesTable supplyCenters={[]} />
          </QueryClientProvider>
        )

      }

    </Box>
  );
};

export default ProductTableList;
