import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  DialogActions,
  Button,
  FormControlLabel,
  IconButton,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomTextField from 'src/components/theme-elements/CustomTextField';
import CustomSwitch from 'src/components/theme-elements/CustomSwitch';
import axiosClient from 'src/axios_client';
import StatusAlert from 'src/components/shared/AlertResponse';
import { IconRefresh } from '@tabler/icons';

const validationSchema = yup.object({
  password: yup.string().when('toDefault', {
    is: false,
    then: yup.string().required('Password is required'),
  }),
  confirmPassword: yup.string().when('toDefault', {
    is: false,
    then: yup.string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  }),
});

type ResetPasswordDialogProps = {
  isOpen: boolean;
  userId: number | null | undefined;
  onClose: () => void;
  refetch: () => void;
};

const ResetPasswordDialog: React.FC<ResetPasswordDialogProps> = ({
  isOpen,
  userId,
  onClose,
  refetch,
}) => {
  const [statusCode, setStatusCode] = useState(0);
  const [statusData, setStatusData] = useState([]);
  const [toDefault, setToDefault] = useState(true);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
      toDefault: true,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const payload = {
          to_default: values.toDefault,
          password: values.toDefault ? null : values.password,
        };

        const response = await axiosClient.post(`/reset_password/${userId}`, payload);
        console.log('Server Response:', response.data);
        setStatusCode(response.status);
        refetch();
        onClose();
      } catch (error: any) {
        if (error.response && error.response.status === 422) {
          setStatusData(error.response.data);
        }
        setStatusCode(error.response?.status || 500);
      }
    },
  });

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setToDefault(checked);
    formik.setFieldValue('toDefault', checked);
    if (checked) {
      formik.setFieldValue('password', '');
      formik.setFieldValue('confirmPassword', '');
    }
  };

  const [generated, setGenerated] = useState('');
  const generateRandomPassword = () => {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+[]{}|;:,.<>?';
    let result = '';
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    setGenerated(result);
    formik.setFieldValue('password', result);
    formik.setFieldValue('confirmPassword', result);

  }

  return (
    <>
      {statusCode !== 0 && <StatusAlert statusCode={statusCode} statusData={statusData} />}
      <Dialog open={isOpen} onClose={onClose}>
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>Reset Password</DialogTitle>
          <DialogContent sx={{ overflow: 'hidden' }}>
            <DialogContentText>Enter a new password for the user.</DialogContentText>
            <Box mt={1} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <FormControlLabel
                control={
                  <CustomSwitch
                    checked={toDefault}
                    onChange={handleSwitchChange}
                  />
                }
                label="Reset to Default Password"
              />
              <Box sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: toDefault ? 'flex-start' : 'space-around'
              }}>
                {!toDefault && (
                  <>
                    <Button variant="outlined" onClick={generateRandomPassword} startIcon={<IconRefresh />}>
                      Generate Password
                    </Button>

                    <Typography>
                      Generated Password: {generated}
                    </Typography>
                    <CustomTextField
                      margin="dense"
                      id="password"
                      name="password"
                      size="small"
                      label="New Password"
                      type="password"
                      fullWidth
                      value={formik.values.password}
                      error={formik.touched.password && Boolean(formik.errors.password)}
                      helperText={formik.touched.password && formik.errors.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <CustomTextField
                      size="small"
                      margin="dense"
                      id="confirmPassword"
                      name="confirmPassword"
                      label="Confirm New Password"
                      type="password"
                      fullWidth
                      value={formik.values.confirmPassword}
                      error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                      helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </>
                )}
              </Box>
            </Box>
          </DialogContent>
          <DialogActions sx={{ mb: 2, mr: 2 }}>
            <Button color="error" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">Confirm</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default React.memo(ResetPasswordDialog);