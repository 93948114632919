import { Box, Button, Divider, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Steps } from 'antd';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import ReactToPrint from 'react-to-print';
import axiosClient from 'src/axios_client';
import PageContainer from 'src/components/container/PageContainer';
import { useEssentialDataContext } from 'src/contexts/EssentialDataContext';
import { useTransaction } from 'src/contexts/ReturnTransmital';
import { LogoDark, LogoLight } from 'src/globals/LogoExports';
import { formatDate, formatDateWithTime } from 'src/globals/PublicFunctions';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import { AppState } from 'src/store/Store';

type Props = {}

type TransmitalItem = {
    id: number;
    product_id: number;
    product_sku: any;
    product_name: any;
    product_brand: any;
    product_model: any;
    serial_numbers: any[];
    return_transaction_id: number;
    location_id: number;
    location_name: any;
    product_transmital_id: number;
    floor: string;
    divider: string;
    quantity: number;
    value: number;
    condition: string;
    created_at: string;
    updated_at: string;
};

type CreatedBy = {
    id: number;
    name: string;
    username: string;
    email: string;
    email_verified_at: string | null;
    userlevel: string;
    is_customer: boolean | null;
    created_by: number | null;
    updated_by: number | null;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
};

type Transaction = {
    id: number;
    transaction_number: string;
    transmital_id: number;
    transmital_created_by: any;
    transmital_items: TransmitalItem[];
    created_by: CreatedBy;
    sc_id: any;
    notes: any;
    pickup_by: any;
    supply_center_name: any;
    created_at: any;
};
const BCrumb = [
    {
        to: '/',
        title: 'Home',
    },
    {
        title: 'Return View Transaction',
    },
];
const ReturnProductsViewTransaction: React.FC = () => {

    const { transactionId } = useTransaction();
    const navigate = useNavigate();
    const { userData } = useEssentialDataContext();

    const [transmitalTransaction, setTransmitalTransaction] = useState<Transaction>();
    const userlevel = userData?.userlevel;

    console.log('Transaction ID: ', transactionId);
    const customizer = useSelector((state: AppState) => state.customizer);

    useEffect(() => {
        if (!transactionId) {
            console.warn('No transaction ID found, redirecting to purchase entry');
            navigate('/return_products');
        }
        else if (transactionId) {
            const fetchData = async () => {
                try {
                    const response = await axiosClient.get(`ptr_transmital_transaction/${transactionId}`);
                    const transactionData = response.data.data;
                    setTransmitalTransaction(transactionData);
                    // console.log(transactionData);
                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {

                }
            };

            fetchData();
        }
    }, [transactionId, navigate]);

    if (!transactionId) {
        return null; // or show a loading spinner/message
    }

    console.log(transmitalTransaction);
    return (

        <PageContainer title="RKCWMS - Transmittal View Transaction">
            <Breadcrumb title="Transmittal - View Transaction" items={BCrumb} />

            {transmitalTransaction ?
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        p: 4,
                        bgcolor: 'background.paper',
                        borderRadius: 1,
                        boxShadow: 3,
                    }}
                >
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={9} id={'transactionData'}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 1,
                                    padding: { sm: '20px', md: '0px' },
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-start',
                                        mb: 1,
                                    }}
                                >
                                    <Box>
                                        <Box sx={{ width: '250px', marginLeft: '-36px', marginBottom: '5px' }}>
                                            {customizer.activeMode === 'dark' ? (
                                                <LogoLight height={'auto'} />
                                            ) : (
                                                <LogoDark height={'auto'} />
                                            )}
                                        </Box>
                                        <Typography
                                            component="p"
                                            sx={{ color: 'text.primary', fontWeight: 'bold' }}
                                            className="fontSize8pt"
                                        >
                                            RAEL KITZ CORPORATION - WMS IS
                                        </Typography>

                                        <Typography
                                            variant="body2"
                                            sx={{ color: 'text.secondary', displayPrint: 'none' }}
                                            className="fontSize8pt"
                                        >
                                            RRF Building San Miguel Calasiao, Pangasinan
                                            <br />
                                            +1 (123) 456 7891, +44 (876) 543 2198
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{ mt: 1, fontWeight: 'bold', textTransform: 'uppercase' }}
                                            className="fontSize8pt"
                                        >
                                            TRANSMITAL FORM
                                        </Typography>
                                    </Box>
                                    <Box sx={{ textAlign: 'right' }}>
                                        <Typography
                                            variant="h6"
                                            component="p"
                                            sx={{ color: 'text.primary', fontWeight: 'bold' }}
                                            className="fontSize8pt"
                                        >
                                            Transaction ID: {transmitalTransaction?.id.toString().padStart(5, '0')}
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
                                            Reference Number : OIR-{transmitalTransaction?.transmital_id.toString().padStart(9, '0')} <br />
                                            Originating Station: {transmitalTransaction?.transmital_created_by} <br />
                                            Destination: {transmitalTransaction?.supply_center_name}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            sx={{ color: 'text.primary', fontWeight: 'bold' }}
                                            className="fontSize8pt"
                                        >
                                            Encoded Date: {formatDate(transmitalTransaction?.created_at)}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Table
                                    className="printTable" sx={{ width: '100%', textAlign: 'left' }}
                                >
                                    <TableHead sx={{ backgroundColor: 'grey.200' }}>
                                        <TableRow>
                                            <TableCell sx={{ py: 0.5, color: 'text.secondary' }}>Item Code</TableCell>
                                            <TableCell sx={{ py: 0.5, color: 'text.secondary' }}>Name</TableCell>
                                            <TableCell sx={{ py: 0.5, color: 'text.secondary' }}>Location</TableCell>
                                            <TableCell sx={{ py: 0.5, color: 'text.secondary' }}>Quantity</TableCell>
                                            <TableCell sx={{ py: 0.5, color: 'text.secondary' }}>Status</TableCell>
                                            <TableCell sx={{ py: 0.5, color: 'text.secondary' }}>Serial Number</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {transmitalTransaction?.transmital_items.map((product) => (
                                            <TableRow key={product.id}>
                                                <TableCell
                                                    sx={{ py: 1, alignItems: 'center' }} className="fontSize8pt"
                                                >
                                                    {product.product_sku}
                                                </TableCell>
                                                <TableCell
                                                    sx={{ py: 1, alignItems: 'center' }} className="fontSize8pt"
                                                >
                                                    {product.product_name}
                                                </TableCell>
                                                <TableCell
                                                    sx={{ py: 1, alignItems: 'center' }} className="fontSize8pt"
                                                >
                                                    {product.location_name}
                                                    {product.divider} Floor{product.floor}
                                                </TableCell>
                                                <TableCell
                                                    sx={{ py: 1, alignItems: 'center' }} className="fontSize8pt"
                                                >
                                                    {product.quantity}
                                                </TableCell>
                                                <TableCell
                                                    sx={{ py: 1, alignItems: 'center' }} className="fontSize8pt"
                                                >
                                                    {product.condition}
                                                </TableCell>
                                                <TableCell sx={{ py: 1, alignItems: 'center' }} className="fontSize8pt">


                                                    {product.serial_numbers?.length > 0 && product.serial_numbers.map((serials: any) => {
                                                        return ' ' + serials.value + ', ';
                                                    })}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>

                                        </TableRow>
                                    </TableBody>
                                </Table>

                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    Note: {transmitalTransaction?.notes}
                                </Typography>

                                <Divider sx={{ borderColor: '#acacac' }} />
                                <Grid
                                    container
                                    columnSpacing={5}
                                    sx={{ fontSize: { md: '8pt', lg: '10pt' }, display: 'none', displayPrint: 'flex' }}
                                >
                                    <Grid item xs={5} className="fontSize8pt">
                                        Prepared By:
                                        <Divider sx={{ borderColor: 'black', mt: { xs: 5 } }} />
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Grid container columnSpacing={1}>
                                            <Grid item className="fontSize8pt">
                                                Checked By:
                                            </Grid>
                                            <Grid item flexGrow={1}>
                                                <Grid container columnSpacing={2}>
                                                    <Grid item className="fontSize8pt">
                                                        Stockman
                                                    </Grid>
                                                    <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                                                        <Divider sx={{ borderColor: 'black' }} />
                                                    </Grid>
                                                </Grid>
                                                <Grid container columnSpacing={2}>
                                                    <Grid item className="fontSize8pt">
                                                        Checker
                                                    </Grid>
                                                    <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                                                        <Divider sx={{ borderColor: 'black' }} />
                                                    </Grid>
                                                </Grid>
                                                <Grid container columnSpacing={2} mt={1}>
                                                    <Grid item className="fontSize8pt">
                                                        Hauler Representative
                                                    </Grid>
                                                    <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                                                        <Divider sx={{ borderColor: 'black' }} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/* <Grid item xs={12} mt={1}>
                                    <Grid container columnSpacing={1} justifyContent={'between'}>
                                        <Grid item xs={8}>
                                            <Typography sx={{ fontWeight: 'bold' }} className="fontSize8pt">
                                                Received the above goods in full and no lost items.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Grid item flexGrow={1}>
                                                <Grid container columnSpacing={2}>
                                                    <Grid item className="fontSize8pt">
                                                        Received By:
                                                    </Grid>
                                                    <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                                                        <Divider sx={{ borderColor: 'black' }} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid> */}
                                </Grid>
                                <Grid item xs={12} sx={{ textAlign: 'center' }} mt={1}>
                                    <Divider sx={{ borderColor: 'black' }} />
                                </Grid>

                                <Grid item sx={{ textAlign: 'left' }}>
                                    <Typography
                                        variant="body1"
                                        sx={{ color: 'text.primary', fontWeight: 'bold' }}
                                        className="fontSize8pt"
                                    >
                                        Hauler: {transmitalTransaction?.pickup_by}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sx={{ textAlign: 'center' }} mt={1}>
                                    <Grid container columnSpacing={1}>
                                        <Grid item>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
                                                WAREHOUSE MANAGEMENT SYSTEM - by: INFORMATION SYSTEMS{' '}

                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
                                                Created By: {transmitalTransaction?.created_by.name} {formatDateWithTime(transmitalTransaction?.created_at)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>

                                <ReactToPrint
                                    trigger={() => (
                                        <Button variant="contained" size="small" color="primary">
                                            Print
                                        </Button>
                                    )}
                                    content={() => document.getElementById('transactionData')}
                                    pageStyle={`
                  @media print {
                    body {
                      -webkit-print-color-adjust: exact;
                      margin: 20mm;
                      padding: 50px;
                    }
                    @page {
                      margin: 20mm;
                    }
                    .printTable th,
                    .printTable td {
                      padding: 2px;
                    }
                    header, footer {
                      display: none;
                    }
                    .pageBreak {
                      page-break-after: always;
                    }
                    .fontSize8pt {
                      font-size: 8pt !important;
                    }
                    tbody tr:not(:last-child) td {
                      border-bottom: 1px solid #acacac;
                    }
                    .printable-logo {
                      fill: #000000 !important; /* Ensure the logo prints in black */
                    }
                  }
                `}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                : <></>
            }

        </PageContainer>

    )
}

export default ReturnProductsViewTransaction