import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import {
  MaterialReactTable,
  MRT_Row,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from 'material-react-table';
import { Tooltip, IconButton, Box, Button, Select, MenuItem } from '@mui/material';
import { useSelector } from 'src/store/Store';
import { AppState } from 'src/store/Store';
import {
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';
import axiosClient from 'src/axios_client';
import { Download, DownloadOutlined, Email, PlayArrow, PrintSharp } from '@mui/icons-material';
import { useReactToPrint } from 'react-to-print';
import 'src/styles/reports_print.css';
import {
  Table,
  Typography as TypographyAnt,
  Space,
  Image,
  Button as ButtonAnt,
  Tooltip as TooltipAnt,
  ConfigProvider,
  Row,
  Col,
} from 'antd';
import DateSelector from 'src/components/shared/AntDateSelector';
import dayjs, { Dayjs } from 'dayjs';
import useFormattedNumber from 'src/hooks/useFormattedNumber';
import Link from 'antd/es/typography/Link';
import { useNavigate } from 'react-router';
import { useTransaction } from 'src/contexts/ProductReturnsContext';
import LogoDark from 'src/assets/images/logos/dark-logo.svg';
import LogoLight from 'src/assets/images/logos/light-logo.svg';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { LoadingButton } from '@mui/lab';
import brandImage from 'src/assets/images/brand/rkc.png';

const { Title, Text } = TypographyAnt;
type ReturnsByProductApiResponse = {
  data: Array<ReturnsByProduct>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

type ReturnsByProduct = {
  id: number;
  name: string;
  sku: string;
  quantity_returned: string;
  total_return_amount: string;
};

interface SupplyCenter {
  id: number;
  name: string;
}

const ReturnsByProductTable = () => {
  const [exporting, setExporting] = useState(false);
  const customizer = useSelector((state: AppState) => state.customizer);
  const currentTheme = customizer.activeMode;

  const [isDarkMode, setIsDarkMode] = useState(false);
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs | null, dayjs.Dayjs | null]>([
    dayjs().startOf('day'),
    dayjs().endOf('day'),
  ]);
  const dateRangeRef = useRef(dateRange);

  useEffect(() => {
    dateRangeRef.current = dateRange;
  }, [dateRange]);

  const { formatWithPrefix } = useFormattedNumber();

  useEffect(() => {
    setIsDarkMode(currentTheme === 'dark');
  }, [currentTheme]);

  const [supplyCenters, setSupplyCenters] = useState<SupplyCenter[]>([]);
  const [selectedSupplyCenter, setSelectedSupplyCenter] = useState<number | string>('');

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await axiosClient.get<{ data: SupplyCenter[] }>('/supply_centers');
        const supplyCentersList = response.data.data;
        setSupplyCenters(supplyCentersList);

        if (supplyCentersList.length > 0) {
          // Set initial supply center for all components
          setSelectedSupplyCenter(supplyCentersList[0].id);
        }
      } catch (err) {
        console.error('Error fetching supply centers', err);
      }
    };

    fetchInitialData();
  }, []);

  const handleSupplyCenterChange = (supplyCenter: number | string) => {
    setSelectedSupplyCenter(supplyCenter);
  };

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });

  const {
    data: { data = [], pagination: meta } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<ReturnsByProductApiResponse>({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      try {
        // Construct the params object
        const params: { [key: string]: any } = {
          page: `${pagination.pageIndex + 1}`,
          per_page: `${pagination.pageSize}`,
          filters: JSON.stringify(columnFilters ?? []),
          globalFilter: globalFilter ?? '',
          sorting: JSON.stringify(sorting ?? []),
          start_date: dateRange[0] ? dateRange[0].toISOString() : '',
          end_date: dateRange[1] ? dateRange[1].toISOString() : '',
          supply_center: selectedSupplyCenter ?? null
        };

        // Make the API request with the params object
        const response = await axiosClient.get<ReturnsByProductApiResponse>(
          '/product-returns-report',
          { params },
        );

        return response.data;
      } catch (error) {
        console.error('Error fetching product returns report:', error);
        throw error;
      }
    },
    placeholderData: keepPreviousData,
  });

  const handleRunReport = useCallback(async () => {
    refetch();
  }, [refetch]);

  const navigate = useNavigate();
  const { setSupplyCenterId, setProductId, setDR } = useTransaction();

  useEffect(() => {
    console.log('Updated Daterange1', dateRange[0]?.toISOString());
    console.log('Updated Daterange2', dateRange[1]?.toISOString());
  }, [dateRange]);

  const handleViewReturnsOfProduct = useCallback(
    (row: any) => {
      setSupplyCenterId(row.sc_id);
      setProductId(row.id);
      setDR(dateRangeRef.current);
      navigate('/report-returns-by-selected-product');
    },
    [setProductId, setDR, navigate],
  );

  const columns = useMemo<MRT_ColumnDef<ReturnsByProduct>[]>(
    () => [
      // {
      //   accessorKey: 'id',
      //   header: 'DB ID',
      //   minSize: 50,
      //   maxSize: 50,
      //   size: 50,
      //   enableColumnFilter: false,
      //   enableSorting: false,
      //   enableColumnActions: false,
      //   enableColumnDragging: false,
      // },
      {
        accessorKey: 'name',
        header: 'Product Name',
        size: 180,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Link onClick={() => handleViewReturnsOfProduct(row.original)}>
              {renderedCellValue}
            </Link>
          );
        },
      },
      {
        accessorKey: 'sku',
        header: 'SKU',
        size: 180,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'quantity_returned',
        header: 'Return Count',
        grow: false,
        size: 50,
        Cell: ({ renderedCellValue, row }) => {
          return <span>{renderedCellValue}</span>;
        },
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => row.total_return_amount,
        id: 'total_return_amount',
        header: 'Total Return Amount',
        size: 100,
        Cell: ({ renderedCellValue, row }) => {
          const value = typeof renderedCellValue === 'number' ? renderedCellValue : 0;
          return <span>{formatWithPrefix(value)}</span>;
        },
        enableColumnFilter: false,
      },
    ],
    [],
  );

  const printableTableRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => printableTableRef.current,
    pageStyle: `
    @media print {
      @page {
        size: landscape;
        margin: 0;
      }
      body {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
      }
      html, body {
        height: 100%;
        margin: 0 !important;
        padding: 0 !important;
        overflow: hidden;
      }
    }
  `,
  });

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: { showColumnFilters: true, density: 'compact' },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableColumnOrdering: true,
    enableDensityToggle: false,
    enableRowSelection: true,
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
        <DateSelector onDateChange={setDateRange} />

        <Select
          labelId="supply-center-select-label"
          id="supply-center-select"
          value={selectedSupplyCenter}
          label="Supply Center"
          onChange={(e) => handleSupplyCenterChange(e.target.value)}
        >
          {supplyCenters.map((sc) => (
            <MenuItem key={sc.id} value={sc.id}>
              {sc.name}
            </MenuItem>
          ))}
        </Select>

        <Tooltip arrow title="Extract Report">
          <Button
            onClick={handleRunReport}
            startIcon={
              <>
                <PlayArrow />
              </>
            }
          >
            Run Report
          </Button>
        </Tooltip>

        <LoadingButton
          onClick={handleExportData}
          startIcon={<DownloadOutlined />}
          loading={exporting}
          loadingPosition="start"
          variant="outlined"
        >
          Export All Data
        </LoadingButton>

        <LoadingButton
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<DownloadOutlined />}
          loading={exporting}
          loadingPosition="start"
          variant="outlined"
          disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
        >
          Export Selected Rows
        </LoadingButton>
      </Box>
    ),
    rowCount: meta?.total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  const TEMPLATE_PATH = '/assets/etc/DocFormat.xlsx';

  const handleExportData = async () => {
    setExporting(true);
    try {
      const totalRows = meta?.total ?? 0;
      const pageSize = pagination.pageSize;
      const totalPages = Math.ceil(totalRows / pageSize);
      const allData: ReturnsByProduct[] = [];

      // Fetch paginated data
      for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
        const params: Record<string, any> = {
          page: `${pagination.pageIndex + 1}`,
          per_page: `${pagination.pageSize}`,
          filters: JSON.stringify(columnFilters ?? []),
          globalFilter: globalFilter ?? '',
          sorting: JSON.stringify(sorting ?? []),
          start_date: dateRange[0]?.toISOString() ?? '',
          end_date: dateRange[1]?.toISOString() ?? '',
          supply_center: selectedSupplyCenter ?? null
        };

        // Add date range if specified
        if (dateRange[0] && dateRange[1]) {
          params.start_date = dateRange[0].toISOString();
          params.end_date = dateRange[1].toISOString();
        }

        const response = await axiosClient.get<ReturnsByProductApiResponse>(
          '/product-returns-report',
          { params },
        );
        allData.push(...response.data.data);
      }

      // Reuse handleExportRows logic for data export
      await handleExportRows(
        allData.map((data) => ({ original: data } as MRT_Row<ReturnsByProduct>)),
      );
    } catch (error) {
      console.error('Error exporting data with ExcelJS:', error);
      alert('Failed to export data. Please try again.');
    } finally {
      setExporting(false);
    }
  };

  const handleExportRows = async (rows: MRT_Row<ReturnsByProduct>[]) => {
    setExporting(true);
    try {
      // Load the template file
      const workbook = new ExcelJS.Workbook();
      const response = await fetch(TEMPLATE_PATH);
      const arrayBuffer = await response.arrayBuffer();
      await workbook.xlsx.load(arrayBuffer);

      // Access the first worksheet in the template
      const worksheet = workbook.getWorksheet(1);
      if (!worksheet) {
        throw new Error('Worksheet not found in the template file.');
      }

      // Adjust column widths
      worksheet.columns = [
        { width: 20, key: 'sku' },
        { width: 45, key: 'name' },
        { width: 20, key: 'quantity_returned' },
        { width: 20, key: 'total_return_amount' },
      ];

      // Set title
      const titleCell = worksheet.getCell('F5');
      titleCell.value = 'Customer Returns Report';
      titleCell.font = { bold: true, size: 14 };

      // Add today's date dynamically
      const dateCell = worksheet.getCell('F6');
      const today = new Date();
      const formattedDate = today.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
      dateCell.value = formattedDate;
      dateCell.font = { italic: true, size: 12 };

      const rangeCell = worksheet.getCell('H6');

      // Function to format dates as "Month XX, XXXX"
      const formatDate = (isoString: any) => {
        const date = new Date(isoString);
        return date.toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        });
      };

      // Format the date range
      const startDate = formatDate(dateRange[0]);
      const endDate = formatDate(dateRange[1]);

      // Update the cell value with the formatted dates
      rangeCell.value = `Returns from ${startDate} to ${endDate}`;
      rangeCell.font = { italic: true, size: 12 };

      // Define header and data start rows
      const headerRowIndex = 7;
      const dataStartRowIndex = 8;

      // Insert headers
      const headerRow = worksheet.getRow(headerRowIndex);
      headerRow.values = ['SKU', 'Product Name', 'Quantity Returned', 'Total Return Value'];
      headerRow.font = { bold: true };

      // Fetch the imported image for branding
      const imageResponse = await fetch(brandImage);
      const imageBuffer = await imageResponse.arrayBuffer();
      const imageId = workbook.addImage({
        buffer: imageBuffer,
        extension: 'png',
      });
      worksheet.addImage(imageId, {
        tl: {
          col: 0,
          row: 0,
          colOff: (10 * 9600) / 96,
          rowOff: (10 * 9600) / 96,
        } as any,
        ext: { width: 175, height: 40 },
        editAs: 'oneCell',
      });

      // Add data rows
      rows.forEach((row, index) => {
        const data = row.original;
        const currentRow = worksheet.getRow(dataStartRowIndex + index);

        currentRow.getCell(1).value = data.sku;
        currentRow.getCell(2).value = data.name;
        currentRow.getCell(3).value = data.quantity_returned;
        currentRow.getCell(4).value = data.total_return_amount;

        currentRow.commit();
      });

      // Save the modified Excel file
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, 'CustomerReturnsReport.xlsx');
    } catch (error) {
      console.error('Error exporting rows with ExcelJS:', error);
      alert('Failed to export data. Please try again.');
    } finally {
      setExporting(false);
    }
  };

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
};

const queryClient = new QueryClient();

const ReturnsByProductView = () => (
  <QueryClientProvider client={queryClient}>
    <ReturnsByProductTable />
  </QueryClientProvider>
);

export default ReturnsByProductView;
