import { RefreshOutlined } from '@mui/icons-material';
import { Box, Button, Card, Divider, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { IconEye } from '@tabler/icons';
import {
  keepPreviousData,
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  useMaterialReactTable,
} from 'material-react-table';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axiosClient from 'src/axios_client';
import { useTransaction } from 'src/contexts/ReturnTransmital';
import { LogoDark, LogoLight } from 'src/globals/LogoExports';
import { formatDate, formatDateWithTime } from 'src/globals/PublicFunctions';
import { AppState } from 'src/store/Store';

type Props = {};

type TransmitalItem = {
  id: number;
  product_id: number;
  product_sku: any;
  product_name: any;
  product_brand: any;
  product_model: any;
  serial_numbers: any[];
  return_transaction_id: number;
  location_id: number;
  location_name: any;
  product_transmital_id: number;
  floor: string;
  divider: string;
  quantity: number;
  value: number;
  condition: string;
  created_at: string;
  updated_at: string;
};

type CreatedBy = {
  id: number;
  name: string;
  username: string;
  email: string;
  email_verified_at: string | null;
  userlevel: string;
  is_customer: boolean | null;
  created_by: number | null;
  updated_by: number | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
};

type Transaction = {
  id: number;
  transaction_number: string;
  transmital_id: number;
  transmital_created_by: any;
  transmital_items: TransmitalItem[];
  created_by: CreatedBy;
  sc_id: any;
  notes: any;
  pickup_by: any;
  supply_center_name: any;
  created_at: any;
};

type ReturnTransactionsApiResponse = {
  data: Array<Transaction>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

const queryClient = new QueryClient();

const RetTable = () => {
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const customizer = useSelector((state: AppState) => state.customizer);
  const currentTheme = customizer.activeMode;
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { setTransactionId } = useTransaction();

  const handleViewReturnProducts = (row: any) => {
    setTransactionId(row.id);
    console.log(row.id);
    navigate('/product-return-view-transaction');
  };
  const {
    data: { data = [], pagination: meta } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<ReturnTransactionsApiResponse>({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      try {
        const params = {
          page: `${pagination.pageIndex + 1}`,
          per_page: `${pagination.pageSize}`,
          filters: JSON.stringify(columnFilters ?? []),
          globalFilter: globalFilter ?? '',
          sorting: JSON.stringify(sorting ?? []),
        };

        const response = await axiosClient.get<ReturnTransactionsApiResponse>(
          '/ptr_transmital_transaction',
          { params },
        );

        console.log(response.data);

        return response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    },

    placeholderData: keepPreviousData,
  });
  const columns = useMemo<MRT_ColumnDef<Transaction>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'DB ID',
        size: 80,
      },
      // {
      //     accessorKey: 'transaction_number',
      //     header: 'Return Transaction',
      // },
      {
        accessorKey: 'created_by',
        header: 'Created By',
        size: 120,
        Cell: ({ row }) => {
          return <Typography variant="subtitle1">{row.original.created_by.name}</Typography>;
        },
      },
      {
        accessorKey: 'created_at',
        header: 'Created At',
        size: 120,
        Cell: ({ row }) => {
          return <Typography variant="subtitle1">{formatDate(row.original.created_at)}</Typography>;
        },
      },

      {
        accessorKey: 'actions',
        header: 'Actions',
        size: 120,
        enableSorting: false,
        enableColumnActions: false,
        Cell: ({ row }) => {
          const { original } = row;

          return (
            <Grid container spacing={2}>
              <Grid item>
                <Button onClick={() => handleViewReturnProducts(original)} variant="outlined">
                  <IconEye />
                </Button>
              </Grid>

            </Grid>
          );
        }
        ,
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    // layoutMode: 'grid',
    defaultColumn: {
      minSize: 20, //allow columns to get smaller than default
      maxSize: 9001, //allow columns to get larger than default
      size: 180, //make columns wider by default
    },
    initialState: {
      showColumnFilters: true,
      columnPinning: {
        left: ['mrt-row-expand', 'mrt-row-select'],
      },
    },
    muiTablePaperProps: {
      sx: {
        maxWidth: 'min(100vw, 2200px)'
      }
    },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
        color: 'error',
        children: 'Error loading data',
      }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    // enableColumnOrdering: false,
    // enableColumnActions: false,
    // enableSorting: false,
    // enableTopToolbar: false,
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px', alignItems: 'center' }}>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => refetch()}>
            <RefreshOutlined />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        console.log(row.original);
        // navigate(`/adjustment-transactions/view/${row.original.transaction_number}`);
      },
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
    }),
    renderDetailPanel: ({ row }) => {
      if (row.original.transmital_items) {
        return (
          <Grid container>
            <Grid item xs={12} md={8}>
              <Card elevation={12}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    padding: '10px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      mb: 1,
                    }}
                  >
                    <Box>
                      <Box sx={{ width: '250px', marginLeft: '-36px', marginBottom: '5px' }}>
                        {customizer.activeMode === 'dark' ? (
                          <LogoLight height={'auto'} />
                        ) : (
                          <LogoDark height={'auto'} />
                        )}
                      </Box>
                      <Typography
                        component="p"
                        sx={{ color: 'text.primary', fontWeight: 'bold' }}
                        className="fontSize8pt"
                      >
                        RAEL KITZ CORPORATION - WMS IS
                      </Typography>

                      <Typography
                        variant="body2"
                        sx={{ color: 'text.secondary', displayPrint: 'none' }}
                        className="fontSize8pt"
                      >
                        RRF Building San Miguel Calasiao, Pangasinan
                        <br />
                        +1 (123) 456 7891, +44 (876) 543 2198
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ mt: 1, fontWeight: 'bold', textTransform: 'uppercase' }}
                        className="fontSize8pt"
                      >
                        TRANSMITAL FORM
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: 'right' }}>
                      <Typography
                        variant="h6"
                        component="p"
                        sx={{ color: 'text.primary', fontWeight: 'bold' }}
                        className="fontSize8pt"
                      >
                        Transaction ID: {row.original.id?.toString().padStart(5, '0')}
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
                        Reference Number : OIR-{row.original.transmital_id.toString().padStart(9, '0')} <br />
                        Originating Station: {row.original.transmital_created_by} <br />
                        Destination: {row.original.supply_center_name}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ color: 'text.primary', fontWeight: 'bold' }}
                        className="fontSize8pt"
                      >
                        Encoded Date: {formatDate(row.original.created_at)}
                      </Typography>
                    </Box>
                  </Box>
                  <Table
                    className="printTable" sx={{ width: '100%', textAlign: 'left' }}
                  >
                    <TableHead sx={{ backgroundColor: 'grey.200' }}>
                      <TableRow>
                        <TableCell
                          sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt"
                        >
                          Item Code
                        </TableCell>
                        <TableCell
                          sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt"
                        >
                          Name
                        </TableCell>
                        <TableCell
                          sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt"
                        >
                          Quantity
                        </TableCell>
                        <TableCell
                          sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt"
                        >
                          Status
                        </TableCell>
                        <TableCell
                          sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt"
                        >
                          Serial Number
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.original.transmital_items?.map((product: any) => (
                        <TableRow key={product.id}>
                          <TableCell
                            sx={{ py: 1, alignItems: 'center' }} className="fontSize8pt"
                          >
                            {product.product_sku}
                          </TableCell>
                          <TableCell
                            sx={{ py: 1, alignItems: 'center' }} className="fontSize8pt"
                          >
                            {product.product_name}
                          </TableCell>
                          <TableCell
                            sx={{ py: 1, alignItems: 'center' }} className="fontSize8pt"
                          >
                            {product.quantity}
                          </TableCell>
                          <TableCell
                            sx={{ py: 1, alignItems: 'center' }} className="fontSize8pt"
                          >
                            {product.condition}
                          </TableCell>
                          <TableCell sx={{ py: 1, alignItems: 'center' }} className="fontSize8pt">


                            {product.serial_numbers?.length > 0 && product.serial_numbers.map((serials: any) => {
                              return ' ' + serials.value + ', ';
                            })}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>

                      </TableRow>
                    </TableBody>
                  </Table>

                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Note: {row.original.notes}
                  </Typography>

                  <Divider sx={{ borderColor: '#acacac' }} />
                  <Grid
                    container
                    columnSpacing={5}
                    sx={{ fontSize: { md: '8pt', lg: '10pt' }, display: 'none', displayPrint: 'flex' }}
                  >
                    <Grid item xs={5} className="fontSize8pt">
                      Prepared By:
                      <Divider sx={{ borderColor: 'black', mt: { xs: 5 } }} />
                    </Grid>
                    <Grid item xs={7}>
                      <Grid container columnSpacing={1}>
                        <Grid item className="fontSize8pt">
                          Checked By:
                        </Grid>
                        <Grid item flexGrow={1}>
                          <Grid container columnSpacing={2}>
                            <Grid item className="fontSize8pt">
                              Stockman
                            </Grid>
                            <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                              <Divider sx={{ borderColor: 'black' }} />
                            </Grid>
                          </Grid>
                          <Grid container columnSpacing={2}>
                            <Grid item className="fontSize8pt">
                              Checker
                            </Grid>
                            <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                              <Divider sx={{ borderColor: 'black' }} />
                            </Grid>
                          </Grid>
                          <Grid container columnSpacing={2} mt={1}>
                            <Grid item className="fontSize8pt">
                              Hauler Representative
                            </Grid>
                            <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                              <Divider sx={{ borderColor: 'black' }} />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: 'center' }} mt={1}>
                    <Divider sx={{ borderColor: 'black' }} />
                  </Grid>

                  <Grid item sx={{ textAlign: 'left' }}>
                    <Typography
                      variant="body1"
                      sx={{ color: 'text.primary', fontWeight: 'bold' }}
                      className="fontSize8pt"
                    >
                      Hauler: {row.original.pickup_by}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sx={{ textAlign: 'center' }} mt={1}>
                    <Grid container columnSpacing={1}>
                      <Grid item>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
                          WAREHOUSE MANAGEMENT SYSTEM - by: INFORMATION SYSTEMS{' '}

                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
                          Created By: {row.original.created_by?.name} {formatDateWithTime(row.original.created_at)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>
          </Grid>


          // <Grid container>
          //   <Grid item xs={12}>
          //     <Card elevation={12}>
          //       <Typography variant="h4" gutterBottom>
          //         Transaction Details
          //       </Typography>
          //       <Grid container spacing={2}>
          //         <Grid item xs={12}>
          //           <Typography variant="body1">
          //             <strong>Transaction ID:</strong> {row.original.id}
          //           </Typography>
          //           {/* <Typography variant="body1">
          //             <strong>Pickup By:</strong> {transaction.pickup_by}
          //           </Typography> */}
          //           <Typography variant="body1">
          //             <strong>Destination:</strong>{' '}
          //             {row.original.sc_id === 1
          //               ? 'Warehouse - San Miguel Calasiao'
          //               : 'Unknown'}
          //           </Typography>
          //           {/* <Typography variant="body1">
          //             <strong>Notes:</strong> {transaction.notes}
          //           </Typography> */}
          //         </Grid>
          //       </Grid>
          //       <Divider sx={{ my: 1, borderColor: '#dbdbdb' }} />

          //       {/* Products Table */}
          //       <Typography variant="h5" gutterBottom style={{ marginBottom: '15px' }}>
          //         Products
          //       </Typography>
          //       <Table>
          //         <TableHead>
          //           <TableRow>
          //             <TableCell sx={{ backgroundColor: '#eaeef3' }}>Item Code</TableCell>
          //             <TableCell sx={{ backgroundColor: '#eaeef3' }}>Name</TableCell>
          //             <TableCell sx={{ backgroundColor: '#eaeef3' }}>Location</TableCell>
          //             <TableCell sx={{ backgroundColor: '#eaeef3' }}>Brand</TableCell>
          //             <TableCell sx={{ backgroundColor: '#eaeef3' }}>Model</TableCell>
          //             <TableCell sx={{ backgroundColor: '#eaeef3' }}>Quantity</TableCell>
          //             <TableCell sx={{ backgroundColor: '#eaeef3' }}>Status</TableCell>
          //             <TableCell sx={{ backgroundColor: '#eaeef3' }}>Serial Number</TableCell>
          //             {/* <TableCell sx={{ backgroundColor: '#eaeef3' }}>Reference Number</TableCell> */}
          //           </TableRow>
          //         </TableHead>
          //         <TableBody>
          //           {row.original.transmital_items.map((product) => (
          //             <TableRow key={product.id}>
          //               <TableCell>{product.product_sku}</TableCell>
          //               <TableCell>{product.product_name}</TableCell>
          //               <TableCell>{product.location_name}{product.divider} Floor{product.floor}</TableCell>
          //               <TableCell>{product.product_brand}</TableCell>
          //               <TableCell>{product.product_model}</TableCell>
          //               <TableCell>{product.quantity}</TableCell>
          //               <TableCell>{product.condition}</TableCell>
          //               <TableCell>{product.serial_numbers?.length > 0 &&
          //                 product.serial_numbers.map((serial: any) => serial.value ? serial.value : 'CONTACT ADMIN').join(', ')}</TableCell>
          //             </TableRow>
          //           ))}
          //         </TableBody>
          //       </Table>
          //       <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
          //         <Divider sx={{ my: 1, borderColor: '#bfbfbf' }} />
          //       </Grid>
          //       <Grid item xs={12} sx={{ textAlign: 'center' }} mt={1}>
          //         <Divider sx={{ borderColor: 'black' }} />
          //       </Grid>
          //     </Card>

          //   </Grid>
          // </Grid>
        );
      }
      else {
        return null;
      }

    },
    rowCount: meta?.total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });
  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
};
function ReturnProductsTable({ }: Props) {
  return (
    <QueryClientProvider client={queryClient}>
      <RetTable />
    </QueryClientProvider>
  );
}

export default ReturnProductsTable;
