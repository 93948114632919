import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Grid,
  Divider,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  Backdrop,
  CircularProgress,
  DialogActions,
  TextField,
  InputAdornment,
  Alert,
  Autocomplete,
} from '@mui/material';
import dayjs from 'dayjs';
import useFormattedNumber from 'src/hooks/useFormattedNumber';
import { ReactToPrint } from 'react-to-print';
import './print.css'; // Import the CSS file
import {
  Popover,
  Button as ButtonAnt,
  Form,
  Select,
  Input,
  Steps,
  Popconfirm,
  message,
  Image,
  Watermark,
} from 'antd';
import axiosClient from 'src/axios_client';
import { useNavigate } from 'react-router';
import { useTransaction } from 'src/contexts/PurchaseTransactionContext';
import { ReactComponent as RECEIPT } from 'src/assets/images/svgs/rkc_wms_dark_receiptS.svg';
import { useSelector } from 'react-redux';
import { AppState } from 'src/store/Store';
import { ReactComponent as LogoDark } from 'src/assets/images/logos/dark-logo.svg';
import { ReactComponent as LogoDarkRTL } from 'src/assets/images/logos/dark-rtl-logo.svg';
import { ReactComponent as LogoLight } from 'src/assets/images/logos/light-logo.svg';
import { ReactComponent as LogoLightRTL } from 'src/assets/images/logos/light-logo-rtl.svg';
import { useEssentialDataContext } from 'src/contexts/EssentialDataContext';
import CustomTextField from 'src/components/theme-elements/CustomTextField';
import { formatDateWithTime } from 'src/globals/PublicFunctions';
import { SendOutlined } from '@ant-design/icons';
import ConfirmationButton from 'src/views/IPurchaseOrder/components/ConfirmationButton';

type InvoiceData = {
  id: number;
  invoice_number: string;
  sc_id: any;
  slip_number: any;
  status: any;
  reference_number: string;
  date_of_issuance: string;
  stock_location_record: any;
  customer_code: string;
  customer_name: string;
  customer_address: string;
  customer_contact_person: string;
  transactions: any[];
  created_by_userid: string;
  created_by_name: string;
  subtotal: number;
  released_by: any;
  released_at: any;
  checked_by: any;
  checked_at: any;
  checked_note: any;
  created_by: any;
  created_at: any;
  received_at: any;
  newSubTotal: any;
  total_lost_amount: any;
  total_excess_amount: any;
  released_note: any;
  excess_transaction_date: any;
  hauler: any;
};

interface ErrorResponse {
  message: string;
  error: string;
}

interface GridItemProps {
  data: InvoiceData;
  copy: any;
  userlevel: any;
  isConfirmed: any;

  handleNoticeSelect: (productId: string, noticeId: string, keys: string[]) => void;
  handleNoteChange: (productId: string, note: string) => void;
  selectedNotices: {
    [key: string]: { noticeId: string; keys: string[]; note?: string };
  };
}

const { Option } = Select;

const GridItem: FC<GridItemProps> = ({
  data,
  copy,
  isConfirmed,
  userlevel,
  handleNoticeSelect,
  handleNoteChange,
  selectedNotices,
}) => {
  const { formatWithPrefix } = useFormattedNumber();

  const validUserLevels = ['stockman', 'encoder', 'superadmin', 'customer'];

  const isValidUserLevel = (currentUserLevel: any) => validUserLevels.includes(currentUserLevel);

  console.log(data);
  const customizer = useSelector((state: AppState) => state.customizer);

  const { userData } = useEssentialDataContext();

  const lostQuantityArrays = data.transactions.filter((data: any) => data.lostQuantity > 0);
  const excessQuantityArrays = data.transactions.filter((data: any) => data.excessQuantity > 0);

  console.log('Transaction Data:', data);

  // console.log(transaction);

  return (
    <Watermark
      content={data.status == 'cancelled' ? ['ISSUANCE', 'CANCELLED'] : ''}
      font={{
        color: '#ff4d4fb8',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          padding: { sm: '20px', md: '0px' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            mb: 1,
          }}
        >
          <Box>
            <Box sx={{ width: '250px', marginLeft: '-36px', marginBottom: '5px' }}>
              {customizer.activeMode === 'dark' ? (
                <LogoLight height={'auto'} />
              ) : (
                <LogoDark height={'auto'} />
              )}
            </Box>
            <Typography
              component="p"
              sx={{ color: 'text.primary', fontWeight: 'bold' }}
              className="fontSize8pt"
            >
              RAEL KITZ CORPORATION - WMS IS
            </Typography>

            <Typography
              variant="body2"
              sx={{ color: 'text.secondary', displayPrint: 'none' }}
              className="fontSize8pt"
            >
              RRF Building San Miguel Calasiao, Pangasinan
              <br />
              +1 (123) 456 7891, +44 (876) 543 2198
            </Typography>

            <Typography
              variant="body2"
              sx={{ /* mt: 1, */ fontWeight: 'bold', textTransform: 'uppercase' }}
              className="fontSize8pt"
            >
              {copy} <br />
              FINAL ISSUANCE ({data.status})
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'right' }}>
            <Typography
              variant="h6"
              component="p"
              sx={{ color: 'text.primary', fontWeight: 'bold' }}
              className="fontSize8pt"
            >
              {data.slip_number}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
              Reference Number: {data.reference_number} <br />
              Issuance Date: {data.date_of_issuance}
              {/* Transaction ID: {data.id} */}
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: 'text.primary', fontWeight: 'bold' }}
              className="fontSize8pt"
            >
              Customer: {data.customer_name}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: 'text.secondary', displayPrint: 'block' }}
              className="fontSize8pt"
            >
              Customer Code: {data.customer_code}
              <br />
              Contact Person: {data.customer_contact_person}
              <br />
            </Typography>
          </Box>
        </Box>
        <Table className="printTable" sx={{ width: '100%', textAlign: 'left' }}>
          <TableHead sx={{ backgroundColor: 'grey.200' }}>
            <TableRow>
              <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
                SKU
              </TableCell>
              <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
                PRODUCT NAME
              </TableCell>
              {isValidUserLevel(userlevel) && (
                <TableCell
                  sx={{ py: 0.5, color: 'text.secondary', displayPrint: 'none' }}
                  className="fontSize8pt"
                >
                  LOCATION
                </TableCell>
              )}
              <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
                SERIAL NO.
              </TableCell>
              <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
                QTY
              </TableCell>
              {/* <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              UNIT
            </TableCell> */}
              {isValidUserLevel(userlevel) && (
                <>
                  <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
                    COST
                  </TableCell>
                  <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
                    AMOUNT
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.transactions.map((transaction, index) => (
              <TableRow key={index}>
                <TableCell sx={{ py: 1 }} className="fontSize8pt">
                  {transaction.product_sku}
                </TableCell>

                <TableCell sx={{ py: 1, alignItems: 'center' }} className="fontSize8pt">
                  <Box sx={{ displayPrint: 'none' }}>
                    <Image
                      // sx={{ marginRight: '8px', displayPrint: 'none' }}
                      width={35}
                      alt=""
                      src={transaction.product_image}
                      preview={{
                        width: 'auto',
                        src: transaction.product_image,
                      }}
                    />
                    {transaction.product_name}
                  </Box>
                  <Box sx={{ displayPrint: 'block', display: 'none' }}>
                    {transaction.product_name}
                  </Box>
                </TableCell>

                {isValidUserLevel(userlevel) && (
                  <TableCell sx={{ py: 1, displayPrint: 'none' }} className="fontSize8pt">
                    {transaction.location?.name ? (
                      <>
                        Recommended Location: {transaction.location.name}
                        {transaction.divider} Floor {transaction.floor}
                        <br />
                        Possible Location:{' '}
                      </>
                    ) : (
                      'No stock location record'
                    )}

                    {transaction.location?.name &&
                      transaction.possibleLocations?.map((possibleLocation: any) => {
                        if (possibleLocation.excessQuantity || possibleLocation.lostQuantity) {
                          return '';
                        } else {
                          return `${possibleLocation.location?.name}${possibleLocation?.divider}  Floor ${possibleLocation?.floor}, `;
                        }
                      })}
                  </TableCell>
                )}
                <TableCell sx={{ py: 1 }} className="fontSize8pt">
                  {transaction.serial_numbers.length > 0 &&
                    transaction.serial_numbers
                      .map((serial: any) => serial.serial_number)
                      .join(', ')}
                </TableCell>
                <TableCell sx={{ py: 1 }} className="fontSize8pt">
                  {transaction.takenQuantity} {transaction.product_unit}
                </TableCell>
                {/* <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.product_unit}
              </TableCell> */}
                {isValidUserLevel(userlevel) && (
                  <>
                    <TableCell sx={{ py: 1 }} className="fontSize8pt">
                      {formatWithPrefix(transaction.price)}
                    </TableCell>
                    <TableCell sx={{ py: 1 }} className="fontSize8pt">
                      {formatWithPrefix(transaction.takenQuantity * transaction.price)}
                    </TableCell>
                  </>
                )}
                {data.status != 'cancelled' && (
                  <>
                    {userlevel === 'stockman' &&
                      data.status !== 'posted' &&
                      data.status !== 'finalized' &&
                      data.status !== 'in_transit' &&
                      data.status !== 'initial_floating' &&
                      data.status !== 'checked' &&
                      !isConfirmed && (
                        <TableCell sx={{ py: 1, displayPrint: 'none' }} className="fontSize8pt">
                          {transaction.notice !== 0 && (
                            <Popover
                              content={
                                <Form layout="vertical">
                                  <Form.Item
                                    name="reason"
                                    label="Select Reason"
                                    rules={[{ required: true, message: 'Please select a reason' }]}
                                  >
                                    <Select
                                      placeholder="Select a reason"
                                      value={selectedNotices[transaction.product_id]?.noticeId} // Add this line
                                      onChange={(value) =>
                                        handleNoticeSelect(
                                          transaction.product_id,
                                          value,
                                          transaction.keys,
                                        )
                                      }
                                    >
                                      <Option value="0">Ok</Option>
                                      <Option value="1">Physical Stock Mismatch</Option>
                                    </Select>
                                  </Form.Item>
                                  <Form.Item
                                    name="note"
                                    label="Note"
                                    rules={[
                                      {
                                        required:
                                          selectedNotices[transaction.product_id]?.noticeId === '1',
                                        message: 'Note is required for Physical Stock Mismatch',
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Enter note"
                                      value={selectedNotices[transaction.product_id]?.note || ''} // Add this line
                                      disabled={
                                        selectedNotices[transaction.product_id]?.noticeId !== '1'
                                      }
                                      onChange={(e) =>
                                        handleNoteChange(transaction.product_id, e.target.value)
                                      }
                                    />
                                  </Form.Item>
                                </Form>
                              }
                              title="Notice"
                              trigger="click"
                            >
                              <ButtonAnt>Notice</ButtonAnt>

                              {selectedNotices[transaction.product_id]?.noticeId && (
                                <span style={{ color: 'blue', marginLeft: '8px' }}>🔵</span>
                              )}
                            </Popover>
                          )}
                        </TableCell>
                      )}
                  </>
                )}
              </TableRow>
            ))}

            {lostQuantityArrays.map((transaction, index) => (
              <TableRow
                key={index}
                sx={{ '.MuiTableCell-root': { color: 'red', fontWeight: 'bold' } }}
              >
                <TableCell sx={{ py: 1 }} className="fontSize8pt">
                  {transaction.product_name}
                </TableCell>
                <TableCell sx={{ py: 1 }} className="fontSize8pt">
                  {transaction.product_model}
                </TableCell>
                <TableCell sx={{ py: 1 }} className="fontSize8pt">
                  LOST IN TRANSIT
                </TableCell>
                <TableCell sx={{ py: 1 }} className="fontSize8pt">
                  {transaction.serial_numbers.length > 0 &&
                    transaction.lost_serial_numbers
                      .map((serial: any) => serial.serial_number)
                      .join(', ')}
                </TableCell>
                <TableCell sx={{ py: 1 }} className="fontSize8pt">
                  {transaction.lostQuantity} {transaction.product_unit} LOST
                </TableCell>
                {/* <TableCell sx={{ py: 1 }} className="fontSize8pt">
               {transaction.product_unit}
             </TableCell> */}
                {isValidUserLevel(userlevel) && (
                  <>
                    <TableCell sx={{ py: 1 }} className="fontSize8pt">
                      -{formatWithPrefix(transaction.price)}
                    </TableCell>
                    <TableCell sx={{ py: 1 }} className="fontSize8pt">
                      -{formatWithPrefix(transaction.lostQuantity * transaction.price)}
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}

            {excessQuantityArrays.map((transaction, index) => (
              <TableRow
                key={index}
                sx={{ '.MuiTableCell-root': { color: 'green', fontWeight: 'bold' } }}
              >
                <TableCell sx={{ py: 1 }} className="fontSize8pt">
                  {transaction.product_sku}
                </TableCell>
                <TableCell sx={{ py: 1 }} className="fontSize8pt">
                  {transaction.product_name}
                </TableCell>
                {isValidUserLevel(userlevel) && (
                  <TableCell sx={{ py: 1 }} className="fontSize8pt">
                    Excess
                  </TableCell>
                )}

                <TableCell sx={{ py: 1 }} className="fontSize8pt">
                  {transaction.serial_numbers.length > 0 &&
                    transaction.excess_serial_numbers
                      .map((serial: any) => serial.serial_number)
                      .join(', ')}
                </TableCell>
                <TableCell sx={{ py: 1 }} className="fontSize8pt">
                  {transaction.excessQuantity} {transaction.product_unit}
                </TableCell>
                {/* <TableCell sx={{ py: 1 }} className="fontSize8pt">
               {transaction.product_unit}
             </TableCell> */}
                {isValidUserLevel(userlevel) && (
                  <>
                    <TableCell sx={{ py: 1 }} className="fontSize8pt">
                      -{formatWithPrefix(transaction.price)}
                    </TableCell>
                    <TableCell sx={{ py: 1 }} className="fontSize8pt">
                      -{formatWithPrefix(transaction.excessQuantity * transaction.price)}
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}

            <TableRow>
              <TableCell sx={{ py: 1 }}>
                <Typography sx={{ fontWeight: 'bold' }} className="fontSize8pt">
                  Total: {data.transactions.length}
                </Typography>
              </TableCell>
              <TableCell sx={{ py: 1, whiteSpace: 'pre-line' }}></TableCell>
              {isValidUserLevel(userlevel) && (
                <TableCell sx={{ py: 1, displayPrint: 'none' }}></TableCell>
              )}
              <TableCell sx={{ py: 1 }}></TableCell>
              {/* <TableCell sx={{ py: 1 }}></TableCell> */}
              <TableCell sx={{ py: 1 }}></TableCell>
              {isValidUserLevel(userlevel) && (
                <>
                  <TableCell sx={{ py: 1 }}></TableCell>
                  <TableCell sx={{ py: 1, fontWeight: 'bold' }} className="fontSize8pt">
                    {formatWithPrefix(data.newSubTotal)}
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableBody>
        </Table>

        <Divider sx={{ borderColor: '#acacac' }} />
        <Grid
          container
          columnSpacing={5}
          sx={{ fontSize: { md: '8pt', lg: '10pt' }, display: 'none', displayPrint: 'flex' }}
        >
          {/* <Grid item xs={2.5} className="fontSize8pt">
          Requested By:
          <Divider sx={{ borderColor: 'black', mt: { xs: 5 } }} />
        </Grid> */}
          <Grid item xs={5} className="fontSize8pt">
            Prepared By:
            <Divider sx={{ borderColor: 'black', mt: { xs: 5 } }} />
          </Grid>
          <Grid item xs={7}>
            <Grid container columnSpacing={1}>
              <Grid item className="fontSize8pt">
                Checked By:
              </Grid>
              <Grid item flexGrow={1}>
                <Grid container columnSpacing={2}>
                  <Grid item className="fontSize8pt">
                    Stockman
                  </Grid>
                  <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                    <Divider sx={{ borderColor: 'black' }} />
                  </Grid>
                </Grid>
                <Grid container columnSpacing={2}>
                  <Grid item className="fontSize8pt">
                    Checker
                  </Grid>
                  <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                    <Divider sx={{ borderColor: 'black' }} />
                  </Grid>
                </Grid>
                <Grid container columnSpacing={2} mt={1}>
                  <Grid item className="fontSize8pt">
                    Hauler Representative
                  </Grid>
                  <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                    <Divider sx={{ borderColor: 'black' }} />
                  </Grid>
                </Grid>
                <Grid container columnSpacing={2} mt={1}>
                  <Grid item className="fontSize8pt">
                    Customer Authorized Representative
                  </Grid>
                  <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                    <Divider sx={{ borderColor: 'black' }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} mt={1}>
            <Grid container columnSpacing={1} justifyContent={'between'}>
              <Grid item xs={8}>
                <Typography sx={{ fontWeight: 'bold' }} className="fontSize8pt">
                  Received the above goods in full and good condition.
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Grid item flexGrow={1}>
                  <Grid container columnSpacing={2}>
                    <Grid item className="fontSize8pt">
                      Received By:
                    </Grid>
                    <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                      <Divider sx={{ borderColor: 'black' }} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center' }} mt={1}>
          <Divider sx={{ borderColor: 'black' }} />
        </Grid>

        <Grid item sx={{ textAlign: 'left' }}>
          <Typography
            variant="body1"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            Hauler: {data.hauler}
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ textAlign: 'center' }} mt={1}>
          <Grid container columnSpacing={1}>
            <Grid item>
              <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
                WAREHOUSE MANAGEMENT SYSTEM - by: INFORMATION SYSTEMS{' '}
                {formatDateWithTime(data.created_at)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
                Created By: {data.created_by.name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Watermark>
  );
};

interface ReportItemProps {
  data: InvoiceData;
  detail: any;
  userlevel: any;
  reportClick: any;
  cancelClick: any;
  haulerInput: any;
}

const ReportItems: FC<ReportItemProps> = ({
  data,
  detail,
  reportClick,
  cancelClick,
  userlevel,
  haulerInput,
}) => {
  const { formatWithPrefix } = useFormattedNumber();

  const validUserLevels = ['stockman', 'encoder', 'superadmin', 'customer'];

  const isValidUserLevel = (currentUserLevel: any) => validUserLevels.includes(currentUserLevel);

  console.log(data);
  const customizer = useSelector((state: AppState) => state.customizer);

  const { userData } = useEssentialDataContext();

  useEffect(() => {
    console.log(data);
  }, [data]);

  const [transactionsWithLostQuantity, setTransactionsWithLostQuantity] = useState(
    data.transactions.map((transaction) => ({
      ...transaction,
      ...(detail === 'lost'
        ? { lostQuantity: 0, lost_serial_numbers: [] }
        : { excessQuantity: 0, excess_serial_numbers: [] }),
    })),
  );

  //  ...(transaction.excess_serial_numbers ? { excess_serial_numbers: transaction.excess_serial_numbers } : { excess_serial_numbers: [] })

  // Handle change for lostQuantity TextField
  const handleLostQuantityChange = (index: any, event: any) => {
    const value = event.target.value;
    const takenQuantity = transactionsWithLostQuantity[index].takenQuantity ?? 0;
    const lostQuantity = transactionsWithLostQuantity[index].lostQuantity ?? 0;
    const excessQuantity = transactionsWithLostQuantity[index].excessQuantity ?? 0;

    const maxQuantity = takenQuantity - (detail == 'excess' ? lostQuantity : excessQuantity);

    if (value >= 0 && value <= maxQuantity) {
      setTransactionsWithLostQuantity((prevTransactions) =>
        prevTransactions.map((transaction, idx) =>
          idx === index
            ? {
              ...transaction,
              ...(detail === 'lost' ? { lostQuantity: value } : { excessQuantity: value }),
            }
            : transaction,
        ),
      );
    } else {
      console.log('Sobra na yan insan');
    }
  };

  const handleSerialNumbersChange = (index: number, newSelectedSerialNumbers: any[]) => {
    setTransactionsWithLostQuantity((prevTransactions) =>
      prevTransactions.map((transaction, idx) =>
        idx === index
          ? {
            ...transaction,
            ...(detail === 'lost'
              ? { lost_serial_numbers: newSelectedSerialNumbers }
              : { excess_serial_numbers: newSelectedSerialNumbers }),
            // selected_serial_numbers: newSelectedSerialNumbers,
          }
          : transaction,
      ),
    );
  };

  const handlePost = () => {
    reportClick(transactionsWithLostQuantity);
  };

  const handleCancel = () => {
    cancelClick();
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mb: 1,
        }}
      >
        <Box>
          <Box sx={{ width: '250px', marginLeft: '-36px', marginBottom: '5px' }}>
            {customizer.activeMode === 'dark' ? (
              <LogoLight height={'auto'} />
            ) : (
              <LogoDark height={'auto'} />
            )}
          </Box>
          <Typography
            component="p"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            RAEL KITZ CORPORATION - WMS IS
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            RRF Building San Miguel Calasiao, Pangasinan
            <br />
            +1 (123) 456 7891, +44 (876) 543 2198
          </Typography>
          {/* <Typography
            variant="body2"
            sx={{ mt: 1, fontWeight: 'bold', textTransform: 'uppercase' }}
            className="fontSize8pt"
          >
            {copy}
          </Typography> */}
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          <Typography
            variant="h6"
            component="p"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            {data.slip_number}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            Reference Number: {data.reference_number} <br />
            Issuance Date: {data.date_of_issuance}
            {/* Transaction ID: {data.id} */}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            Customer: {data.customer_name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            Customer Code: {data.customer_code}
            <br />
            Address: {data.customer_address}
            <br />
            Contact Person: {data.customer_contact_person}
            <br />
          </Typography>
        </Box>
      </Box>
      <Table className="printTable" sx={{ width: '100%', textAlign: 'left' }}>
        <TableHead sx={{ backgroundColor: 'grey.200' }}>
          <TableRow>
            <TableCell
              sx={{ py: 0.5, color: 'text.secondary', width: '30%' }}
              className="fontSize8pt"
            >
              PRODUCT NAME
            </TableCell>
            {/* <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              MODEL
            </TableCell> */}
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              SERIAL NO.
            </TableCell>
            <TableCell
              sx={{ py: 0.5, color: 'text.secondary', minWidth: '70px' }}
              className="fontSize8pt"
            >
              QTY
            </TableCell>
            {isValidUserLevel(userlevel) && (
              <>
                <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
                  COST
                </TableCell>
                {/* <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
                  AMOUNT
                </TableCell> */}
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {transactionsWithLostQuantity.map((transaction, index) => {
            console.log(data.transactions);

            return (
              <TableRow key={index}>
                <TableCell sx={{ py: 1 }} className="fontSize8pt">
                  {transaction.product_name}
                </TableCell>
                {/* <TableCell sx={{ py: 1 }} className="fontSize8pt">
                  {transaction.product_model}
                </TableCell> */}
                <TableCell sx={{ py: 1 }} className="fontSize8pt">
                  {transaction.serial_numbers.length > 0 && (
                    <Autocomplete
                      multiple
                      id={`autocomplete-serial-numbers-${index}`}
                      options={transaction.serial_numbers} // Keep all serial numbers as options
                      getOptionLabel={(option) => option.serial_number} // Display the serial number as label
                      value={
                        detail == 'lost'
                          ? transaction.lost_serial_numbers || []
                          : transaction.excess_serial_numbers || []
                      } // The currently selected serial numbers
                      onChange={(event, newValue) => handleSerialNumbersChange(index, newValue)} // Handle selection change
                      isOptionEqualToValue={(option, value) =>
                        option.serial_number === value.serial_number
                      } // Compare based on serial number
                      getOptionDisabled={(options) => {
                        console.log(transaction);
                        const isLost = transaction.lost_serial_numbers?.some(
                          (serial: any) => serial.serial_number === options.serial_number,
                        );
                        const isExcess = transaction.excess_serial_numbers?.some(
                          (serial: any) => serial.serial_number === options.serial_number,
                        );

                        if (isLost || isExcess) {
                          return true;
                        }
                        return false;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Serial Numbers"
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  )}

                  {/* {transaction.serial_numbers.length > 0 &&
                    transaction.serial_numbers.map((serial: any) => serial.serial_number).join(', ')} */}
                </TableCell>
                <TableCell sx={{ py: 1, maxWidth: 80 }} className="fontSize8pt">
                  <CustomTextField
                    id="quotation_quantity"
                    size="small"
                    variant="standard"
                    type="number"
                    value={detail == 'lost' ? transaction.lostQuantity : transaction.excessQuantity}
                    onChange={(e: any) => handleLostQuantityChange(index, e)}
                    InputProps={{
                      min: 0,
                      max: transaction.takenQuantity,
                      endAdornment: (
                        <InputAdornment position="start">{transaction.product_unit}</InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                </TableCell>
                {isValidUserLevel(userlevel) && (
                  <>
                    <TableCell sx={{ py: 1 }} className="fontSize8pt">
                      {formatWithPrefix(transaction.price)}
                    </TableCell>
                    {/* <TableCell sx={{ py: 1 }} className="fontSize8pt">
                      {formatWithPrefix(transaction.takenQuantity * transaction.price)}
                    </TableCell> */}
                  </>
                )}
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell sx={{ py: 1 }}>
              <Typography sx={{ fontWeight: 'bold' }} className="fontSize8pt">
                Total: {data.transactions.length}
              </Typography>
            </TableCell>
            {/* <TableCell sx={{ py: 1, whiteSpace: 'pre-line' }}></TableCell> */}
            {isValidUserLevel(userlevel) && <TableCell sx={{ py: 1 }}></TableCell>}
            <TableCell sx={{ py: 1 }}></TableCell>
            {isValidUserLevel(userlevel) && (
              <>
                <TableCell sx={{ py: 1 }}></TableCell>
                {/* <TableCell sx={{ py: 1, fontWeight: 'bold' }} className="fontSize8pt">
                  {formatWithPrefix(data.subtotal)}
                </TableCell> */}
              </>
            )}
          </TableRow>
        </TableBody>
      </Table>

      <Grid container columnSpacing={1}>
        <Grid item>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            WAREHOUSE MANAGEMENT SYSTEM - by: INFORMATION SYSTEMS{' '}
            {formatDateWithTime(data.created_at)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            User: {userData?.name}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" sx={{ fontSize: '10pt' }} color="error" className="fontSize8pt">
            {detail == 'lost' ? (
              <>Note: Input 0 if theres no lost</>
            ) : (
              <>Note: Input 0 if theres no return</>
            )}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          {haulerInput}
        </Grid>

        <Grid item xs={12}>
          <Grid container columnSpacing={1} justifyContent={'end'}>
            <Grid item>
              <Button onClick={handleCancel}>Cancel</Button>
            </Grid>
            <Grid item>
              <ConfirmationButton
                onConfirm={handlePost} // The function to execute on confirmation
                title="Confirm Report"
                description="Are you sure you want to report this? This action cannot be undone."
                buttonText="Report"
                startIcon={<SendOutlined />} // Icon for reporting
                buttonProps={{
                  autoFocus: true, // Button will have focus automatically
                  color: 'error', // Red color for the error action
                }}
                confirmButtonText="Yes, Report"
                cancelButtonText="No"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const Invoice: FC<{ data: InvoiceData; userlevel: any; onAcknowledgeSuccess?: () => void }> = ({
  data,
  userlevel,
  onAcknowledgeSuccess,
}) => {
  const componentRef = useRef(null);
  const [selectedNotices, setSelectedNotices] = useState<{
    [key: string]: { noticeId: string; keys: string[]; note?: string; productId: string };
  }>({});

  const [refreshKey, setRefreshKey] = useState(0);
  const [haulerIssuance, setHaulerIssuance] = useState('');

  const handleNoticeSelect = (productId: string, noticeId: string, keys: string[]) => {
    setSelectedNotices((prev) => ({
      ...prev,
      // Use productId as the key instead of keys[0]
      [productId]: {
        ...prev[productId],
        noticeId,
        keys,
        productId,
        // Reset note when switching to "Ok"
        note: noticeId === '0' ? '' : prev[productId]?.note || '',
      },
    }));
  };

  const handleNoteChange = (productId: string, note: string) => {
    setSelectedNotices((prev) => ({
      ...prev,
      [productId]: {
        ...prev[productId],
        note,
        // Preserve other fields
        noticeId: prev[productId]?.noticeId || '',
        keys: prev[productId]?.keys || [],
        productId,
      },
    }));
  };

  const navigate = useNavigate();
  const { setTransactionId } = useTransaction();

  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleConfirmNotices = async () => {
    const payload = {
      transactionId: data.id,
      products: Object.entries(selectedNotices).map(
        ([index, { noticeId, keys, note, productId }]) => ({
          productId: parseInt(productId, 10),
          noticeId: parseInt(noticeId, 10),
          keys: keys.map((key) => parseInt(key, 10)),
          note: note || '',
        }),
      ),
    };

    console.log('Confirming notices with payload:', payload);

    try {
      const response = await axiosClient.post('/confirm-notices', payload);
      setIsConfirmed(true);
      setTransactionId(data.id);
      setRefreshKey((prevKey) => prevKey + 1);
      navigate('/issuance-entry-view-transaction');
    } catch (error) {
      console.error('Failed to confirm notices:', error);
    }
  };

  const getCurrentStep = (data: InvoiceData): number => {
    if (data.received_at) {
      return 4; // Confirmed
    } else if (data.checked_by.name && data.checked_at) {
      return 3; // Confirmed
    } else if (data.released_by.name) {
      return 2; // Received by Checker
    } else if (data.created_at) {
      return 1; // Created Return Transaction
    }
    return 0; // Default to the first step
  };

  const [note, setNote] = useState('');
  const [hauler, setHauler] = useState('');
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const acknowledge = async () => {
    if (!data.id) return;

    try {
      const response = await axiosClient.post(`/ie_transactions/${data.id}/acknowledge`, {
        userlevel: userlevel,
        note: note,
        hauler: haulerIssuance,
      });
      console.log('Acknowledgment successful', response.data);
      messageApi.open({
        type: 'success',
        content: `Acknowledgment successful`,
        className: 'robotoFont',
        style: {
          marginTop: '20vh',
        },
      });
      setIsAcknowledged(true);
      onAcknowledgeSuccess?.();
    } catch (error: any) {
      console.error('Acknowledgment failed', error);

      // Extract both 'message' and 'error' if available
      const responseData = error?.response?.data;
      const message = responseData?.message || 'An unexpected error occurred';
      const errorDetails = responseData?.error ? ` (${responseData.error})` : '';

      messageApi.open({
        type: 'error',
        content: `Acknowledgement unsuccessful: ${message}${errorDetails}`,
        className: 'robotoFont',
        style: {
          marginTop: '20vh',
        },
      });
    }
  };

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [excessOpen, setExcessOpen] = React.useState(false);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [sucess, setSucess] = useState('');
  const [error, setError] = useState('');

  const handleClickOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const removeErrorOrSuccess = () => {
    setTimeout(() => {
      setError('');
      setSucess('');
    }, 3000);
  };
  const reportTransaction = (transactionsWithLostQuantity: any) => {
    setSubmitting(true);
    const newAmount = data.newSubTotal;
    let minusThis = 0;

    let totalItemsMissing = 0;

    if (hauler == '') {
      setError('Please fill up Hauler');
      removeErrorOrSuccess();
      setSubmitting(false);
      return;
    }

    const reportTransact = transactionsWithLostQuantity.map((transactions: any) => {
      if (transactions.serial_numbers.length > 0) {
        if (transactions.lostQuantity != transactions.lost_serial_numbers.length) {
          setError('PLEASE CHECK YOUR QUANTITY AND SERIAL NUMBERS');
          removeErrorOrSuccess();
          return;
        }
      }

      if (transactions.lostQuantity > 0) {
        minusThis = minusThis + transactions.lostQuantity * transactions.price;
        totalItemsMissing = totalItemsMissing + transactions.lostQuantity;
      }

      const array = { ...transactions };

      delete array.key;
      delete array.product_name;
      delete array.product_model;
      delete array.price;
      delete array.notice;
      delete array.product_unit;
      delete array.keys;

      return array;
    });

    if (totalItemsMissing == 0) {
      setError('CHECK YOUR MISSING ITEMS!');
      removeErrorOrSuccess();
      return;
    }

    const passThisData = {
      total_lost_amount: minusThis,
      total_missing_items: totalItemsMissing,
      stock_location_record: reportTransact,
    };

    const response = axiosClient
      .post(`/ie_transactions/report_missing/${data.id}`, passThisData)
      .then((response) => {
        if (response.status == 201 || response.status == 200) {
          setSucess('REPORT SUCCESSFULLY SUBMITTED!');
          data.newSubTotal = newAmount - minusThis;
          data.transactions = transactionsWithLostQuantity;
          data.received_at = dayjs().toISOString;
          setSubmitting(false);
          removeErrorOrSuccess();
          setDialogOpen(false);
        } else if (response.status == 500) {
          setError('REPORT ENCOUNTERED SOME ERRORS');
          setSubmitting(false);

          removeErrorOrSuccess();
          setDialogOpen(false);
        }
      })
      .catch((error) => {
        setSubmitting(false);

        setError(error.message);
        setDialogOpen(false);
        setTimeout(() => {
          setError('');
        }, 3000);
        console.error('Failed to post transaction:', error);
      });
  };

  const handleOpenExcess = () => {
    setExcessOpen(true);
  };
  const handleExcessClose = () => {
    setExcessOpen(false);
  };

  const excessTransaction = (excessTransaction: any) => {
    const newAmount = data.newSubTotal;
    let returnTotalPrice = 0;
    setSubmitting(true);
    let totalExcessItems = 0;

    if (hauler == '') {
      setError('Please fill up Hauler');
      removeErrorOrSuccess();
      setSubmitting(false);
      return;
    }

    const reportTransact = excessTransaction.map((transactions: any) => {
      if (transactions.serial_numbers.length > 0) {
        if (transactions.excessQuantity != transactions.excess_serial_numbers.length) {
          setError('PLEASE CHECK YOUR QUANTITY AND SERIAL NUMBERS');
          removeErrorOrSuccess();
          setSubmitting(false);
          return;
        }
      }

      if (transactions.excessQuantity > 0) {
        returnTotalPrice = returnTotalPrice + transactions.excessQuantity * transactions.price;
        totalExcessItems = totalExcessItems + transactions.excessQuantity;
      }
      const array = { ...transactions };
      delete array.key;
      delete array.keys;
      delete array.product_name;
      delete array.product_model;
      // delete array.price;
      delete array.notice;
      delete array.product_unit;
      delete array.keys;
      return array;
    });

    if (totalExcessItems == 0) {
      setError('CHECK YOUR EXCESS RETURNS!');
      setSubmitting(false);
      removeErrorOrSuccess();
      return;
    }

    const excess_return = reportTransact
      .filter((item: any) => item.excessQuantity > 0)
      .map((item: any) => {
        const trans = { ...item };
        delete trans.lost_serial_numbers;
        delete trans.lostQuantity;
        return trans;
      });

    const filteredReport = reportTransact.filter((item: any) => item.excessQuantity > 0);

    const passThisData = {
      sc_id: data.sc_id,
      issuance_id: data.id,
      excess_transactions: reportTransact,
      insert_this_to_return: excess_return,
      total_excess_amount: returnTotalPrice,
      total_excess_items: totalExcessItems,
      stock_location_record: filteredReport,
      hauler: hauler,
    };

    const response = axiosClient
      .post(`/ie_transactions/report_excess/${data.id}`, passThisData)
      .then((response) => {
        if (response.status == 201 || response.status == 200) {
          setSucess('REPORT SUCCESSFULLY SUBMITTED!');
          removeErrorOrSuccess();

          data.newSubTotal = newAmount - returnTotalPrice;
          data.transactions = excessTransaction;
          data.excess_transaction_date = dayjs().toISOString;
          setExcessOpen(false);
          setSubmitting(false);

          // setTimeout(() => {
          //   setSucess('');
          // }, 3000)
        } else if (response.status == 500) {
          setError('REPORT ENCOUNTERED SOME ERRORS');
          setTimeout(() => {
            setError('');
          }, 3000);
          setSubmitting(false);
          setExcessOpen(false);
        }
      })
      .catch((error) => {
        setSubmitting(false);

        // Extract the error message from the server response
        if (error.response && error.response.data && error.response.data.message) {
          setError(error.response.data.message); // Use the message from the response
        } else {
          setError('An unexpected error occurred. Please try again.'); // Fallback error message
        }

        setExcessOpen(false);

        // Clear the error message after 10 seconds
        setTimeout(() => {
          setError('');
        }, 10000);

        console.error('Failed to post transaction:', error);
      });
  };

  const haulerInput = (
    <CustomTextField
      id="hauler"
      size="small"
      variant="standard"
      type="text"
      fullWidth
      label="Hauler:"
      value={hauler}
      onChange={(e: any) => setHauler(e.target.value)}
    />
  );
  return (
    <Box
      key={refreshKey}
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        p: 4,
        bgcolor: 'background.paper',
        borderRadius: 1,
        boxShadow: 3,
      }}
    >
      {contextHolder}

      <Grid container spacing={4}>
        <Grid item xs={12}>
          {sucess && (
            <Alert variant="filled" severity="success" sx={{ alignItems: 'center', mb: 1 }}>
              {sucess}
            </Alert>
          )}

          {error && (
            <Alert variant="filled" severity="error" sx={{ alignItems: 'center', mb: 1 }}>
              {error}
            </Alert>
          )}
        </Grid>
        <Grid item xs={12} md={9} ref={componentRef}>

          {data.status == 'floating' ?
            <Box>
              <GridItem
                data={data}
                copy={'PICK LIST'}
                isConfirmed={isConfirmed}
                userlevel={userlevel}
                handleNoticeSelect={handleNoticeSelect}
                selectedNotices={selectedNotices}
                handleNoteChange={handleNoteChange}
              />
            </Box> :
            <Box>
              <GridItem
                data={data}
                copy={'Receiver Copy'}
                isConfirmed={isConfirmed}
                userlevel={userlevel}
                handleNoticeSelect={handleNoticeSelect}
                selectedNotices={selectedNotices}
                handleNoteChange={handleNoteChange}
              />
            </Box>
          }

          {data.status != 'floating' ?

            userlevel != 'customer' && (
              <>
                <div className="pageBreak"></div>
                <Box sx={{ display: 'none', displayPrint: 'block' }}>
                  <GridItem
                    data={data}
                    copy={'Encoder Copy'}
                    isConfirmed={isConfirmed}
                    userlevel={userlevel}
                    handleNoticeSelect={handleNoticeSelect}
                    selectedNotices={selectedNotices}
                    handleNoteChange={handleNoteChange}
                  />
                </Box>
                <div className="pageBreak"></div>
                <Box sx={{ display: 'none', displayPrint: 'block' }}>
                  <GridItem
                    data={data}
                    copy={'Warehouse Copy'}
                    isConfirmed={isConfirmed}
                    userlevel={userlevel}
                    handleNoticeSelect={handleNoticeSelect}
                    selectedNotices={selectedNotices}
                    handleNoteChange={handleNoteChange}
                  />
                </Box>
                <div className="pageBreak"></div>
                <Box sx={{ display: 'none', displayPrint: 'block' }}>
                  <GridItem
                    data={data}
                    copy={'Checker Copy'}
                    userlevel={userlevel}
                    isConfirmed={isConfirmed}
                    handleNoticeSelect={handleNoticeSelect}
                    selectedNotices={selectedNotices}
                    handleNoteChange={handleNoteChange}
                  />
                </Box>
                <div className="pageBreak"></div>
                <Box sx={{ display: 'none', displayPrint: 'block' }}>
                  <GridItem
                    data={data}
                    copy={'Accounting Copy'}
                    isConfirmed={isConfirmed}
                    userlevel={userlevel}
                    handleNoticeSelect={handleNoticeSelect}
                    selectedNotices={selectedNotices}
                    handleNoteChange={handleNoteChange}
                  />
                </Box>
              </>
            ) : null

          }




        </Grid>

        <Grid item xs={12} md={3}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {data.status === 'floating' && data.released_at == null && userlevel === 'stockman' && (
              <Button
                variant="outlined"
                color="error"
                sx={{ py: 1.5, px: 3, borderRadius: 1, display: isConfirmed ? 'none' : 'flex' }}
                onClick={handleConfirmNotices}
              >
                Confirm Notice
              </Button>
            )}

            <Steps
              progressDot
              current={getCurrentStep(data)}
              direction="vertical"
              status={data.status != 'cancelled' ? 'process' : 'error'}
              items={[
                {
                  title: 'Issuance Created',
                  description: `Created by ${data.created_by.name} on ${dayjs(
                    data.created_at,
                  ).format('MMMM DD, YYYY HH:mm:ss')}`,
                },

                {
                  title: (
                    <>
                      <Popover
                        content={
                          data.released_note === '' || data.released_note == null
                            ? 'Notes will be here'
                            : data.released_note
                        }
                      >
                        Stockman Receipt
                      </Popover>
                    </>
                  ),
                  description:
                    data.released_by.name && data.released_at
                      ? `Released by ${data.released_by.name} on ${dayjs(data.released_at).format(
                        'MMMM DD, YYYY HH:mm:ss',
                      )}`
                      : 'Pending',
                },
                {
                  // title: 'Checker Review',
                  title: (
                    <>
                      <Popover
                        content={
                          data.checked_note === '' || data.checked_note == null
                            ? 'Notes will be here'
                            : data.checked_note
                        }
                      >
                        Checker Review
                      </Popover>
                    </>
                  ),
                  description:
                    data.checked_by.name && data.checked_at
                      ? `Cross-Checked by ${data.checked_by.name} on ${dayjs(
                        data.checked_at,
                      ).format('MMMM DD, YYYY HH:mm:ss')}`
                      : 'Pending',
                },
                {
                  title: (
                    <>
                      <Popover content={data.received_at === '' ? 'OK' : data.received_at}>
                        Receiving
                      </Popover>
                    </>
                  ),
                  description: data.received_at
                    ? `Received on ${dayjs(data.received_at).format('MMMM DD, YYYY HH:mm:ss')}`
                    : 'Pending',
                },
                {
                  title: 'Confirmation',
                  description: data.received_at ? 'Completed' : 'Pending',
                },
              ]}
            />

            {data.status != 'cancelled' && (
              <>
                {(userlevel === 'checker' ||
                  userlevel === 'stockman' ||
                  userlevel === 'customer' ||
                  userlevel === 'superadmin') && (
                    <Popconfirm
                      title="Acknowledge Issuance"
                      description={
                        <>
                          <p>
                            Are you sure you want to{' '}
                            {userlevel === 'checker'
                              ? 'RELEASE (SECOND LEVEL)'
                              : userlevel === 'stockman'
                                ? 'RELEASE (FIRST LEVEL)'
                                : userlevel === 'customer'
                                  ? 'RECEIVE'
                                  : ''}{' '}
                            this issuance?
                          </p>
                          <Form.Item label="Note" name="note">
                            <Input.TextArea
                              placeholder="Add a note (optional)"
                              value={note}
                              onChange={(e) => setNote(e.target.value)}
                              rows={4}
                            />
                          </Form.Item>

                          <Form.Item label="Issuance Hauler" name="haulerIssuance">
                            <Input
                              placeholder="Hauler of this Issuance"
                              value={haulerIssuance}
                              onChange={(e) => setHaulerIssuance(e.target.value)}
                            />
                          </Form.Item>
                        </>
                      }
                      onConfirm={acknowledge}
                      okText="Yes"
                      cancelText="No"
                      placement="right"
                    >
                      <Button
                        disabled={
                          isAcknowledged ||
                          (userlevel === 'checker' && data?.checked_by.id !== null) ||
                          (userlevel === 'stockman' && data?.released_by.id !== null) ||
                          (userlevel === 'customer' && data?.received_at !== null) ||
                          (userlevel === 'superadmin' &&
                            data?.checked_by.id !== null &&
                            data?.released_by.id !== null)
                        }
                      >
                        {(userlevel === 'checker' && data?.checked_by.id !== null) ||
                          (userlevel === 'stockman' && data?.released_by.id !== null) ||
                          (userlevel === 'customer' && data?.received_at !== null) ||
                          (userlevel === 'superadmin' &&
                            data?.checked_by.id !== null &&
                            data?.released_by.id !== null)
                          ? 'Acknowledged'
                          : 'Acknowledge'}
                      </Button>
                    </Popconfirm>
                  )}

                {data.checked_by.name &&
                  !data.received_at &&
                  (userlevel === 'customer' || userlevel === 'superadmin') ? (
                  <Button variant="text" color="error" onClick={handleClickOpen}>
                    Report Missing Items
                  </Button>
                ) : (
                  ''
                )}

                {!data.excess_transaction_date &&
                  data.checked_by.name &&
                  (userlevel === 'customer' || userlevel === 'superadmin') ? (
                  <Button variant="text" color="error" onClick={handleOpenExcess}>
                    Return Excess
                  </Button>
                ) : (
                  ''
                )}
              </>
            )}

            <ReactToPrint
              trigger={() => (
                <Button variant="contained" size="small" color="primary">
                  Print
                </Button>
              )}
              content={() => {
                return componentRef.current;
              }}
              pageStyle={`
                @media print {
                  body {
                    -webkit-print-color-adjust: exact;
                    margin: 20mm;
                    padding: 50px;
                  }
                  @page {
                    margin: 20mm;
                  }
                  .printTable th,
                  .printTable td {
                    padding: 2px;
                  }
                  header, footer {
                    display: none;
                  }
                  .pageBreak {
                    page-break-after: always;
                  }
                  .fontSize8pt {
                    font-size: 8pt !important;
                  }
                  tbody tr:not(:last-child) td {
                    border-bottom: 1px solid #acacac;
                  }
                  .printable-logo {
                    fill: #000000 !important; /* Ensure the logo prints in black */
                  }
                }
              `}
            />
          </Box>
        </Grid>
      </Grid>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        fullWidth
        maxWidth={'lg'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Missing Details'}</DialogTitle>
        <DialogContent>
          <Backdrop
            sx={(theme: any) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
            open={isSubmitting}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <ReportItems
            data={data}
            detail={'lost'}
            userlevel={userlevel}
            reportClick={reportTransaction}
            cancelClick={handleDialogClose}
            haulerInput={haulerInput}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={excessOpen}
        onClose={handleExcessClose}
        fullWidth
        maxWidth={'lg'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Excess Details'}</DialogTitle>
        <DialogContent>
          <Backdrop
            sx={(theme: any) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
            open={isSubmitting}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <ReportItems
            data={data}
            detail={'excess'}
            userlevel={userlevel}
            reportClick={excessTransaction}
            cancelClick={handleExcessClose}
            haulerInput={haulerInput}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Invoice;
