import React, { createContext, useState, useContext, ReactNode, useCallback } from 'react';
import { Dayjs } from 'dayjs';

// Define the shape of your context state
interface TransactionContextType {
  supplyCenterId: number | null;
  productId: number | null;
  dateRange: [Dayjs | null, Dayjs | null];
  setSupplyCenterId: (id: number | null) => void;
  setProductId: (id: number | null) => void;
  setDR: (range: [Dayjs | null, Dayjs | null]) => void;
}

// Create the context with a default value
const TransactionContext = createContext<TransactionContextType>({
  supplyCenterId: null,
  productId: null,
  dateRange: [null, null],
  setSupplyCenterId: (id) => {
    console.log('Default context: Supply Center ID received', id);
  },
  setProductId: (id) => {
    console.log('Default context: Product ID received', id);
  },
  setDR: (range) => {
    console.log('Default context: Date range received', range);
  },
});

interface ProductSalesProviderProps {
  children: ReactNode;
}

export const ProductSalesProvider = ({ children }: ProductSalesProviderProps) => {
  const [supplyCenterId, setSupplyCenterIdState] = useState<number | null>(() => {
    const stored = localStorage.getItem('supplyCenterId');
    return stored ? Number(stored) : null;
  });
  const [productId, setProductIdState] = useState<number | null>(() => {
    const stored = localStorage.getItem('productId');
    return stored ? Number(stored) : null;
  });
  const [dateRange, setDateRangeState] = useState<[Dayjs | null, Dayjs | null]>(() => {
    const stored = localStorage.getItem('dateRange');
    return stored ? JSON.parse(stored) : [null, null];
  });

  const setSupplyCenterId = useCallback((id: number | null) => {
    console.log('Product ID received:', id);
    setSupplyCenterIdState(id);
    if (id) {
      localStorage.setItem('supplyCenterId', id.toString());
    } else {
      localStorage.removeItem('supplyCenterId');
    }
  }, []);

  const setProductId = useCallback((id: number | null) => {
    console.log('Product ID received:', id);
    setProductIdState(id);
    if (id) {
      localStorage.setItem('productId', id.toString());
    } else {
      localStorage.removeItem('productId');
    }
  }, []);

  const setDR = useCallback((range: [Dayjs | null, Dayjs | null]) => {
    console.log('Date range received:', range);
    setDateRangeState(range);
    localStorage.setItem('dateRange', JSON.stringify(range));
  }, []);

  return (
    <TransactionContext.Provider
      value={{ supplyCenterId, productId, dateRange, setSupplyCenterId, setProductId, setDR }}
    >
      {children}
    </TransactionContext.Provider>
  );
};

export const useTransaction = () => {
  return useContext(TransactionContext);
};
