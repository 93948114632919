import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from 'src/components/shared/DashboardCard';
import SelectedCustomerSalesTable from './SelectedCustomerSalesTable';
import { useTransaction } from 'src/contexts/CustomerSalesContext';
import { useNavigate } from 'react-router';

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    title: 'Reports',
  },
  {
    title: 'Sales By Customer Page',
  },
];

const SelectedCustomerSales = () => {
  const { supplyCenterId, customerId, dateRange } = useTransaction();
  const navigate = useNavigate();

  console.log('CustomerID: XXX', customerId);
  console.log('dateRange: XXX', dateRange);
  console.log('supplyCenterId: XXX', supplyCenterId);

  useEffect(() => {
    if (!customerId) {
      console.warn('No transaction ID found, redirecting to purchase report');
      navigate('/report-sales-by-customer');
    }
  }, [customerId, navigate]);

  if (!customerId) {
    return null; // or show a loading spinner/message
  }

  if (!supplyCenterId) {
    return null; // or show a loading spinner/message
  }

  return (
    <PageContainer title="RKCWMS - Sales By Customer Page" description="this is Sample page">
      {/* breadcrumb */}
      <Breadcrumb title="Sales By Customer Page" items={BCrumb} />
      {/* end breadcrumb */}
      <Box>
        <SelectedCustomerSalesTable supplyCenterId={supplyCenterId} customerId={customerId} dateRange={dateRange} />
      </Box>
    </PageContainer>
  );
};

export default SelectedCustomerSales;
