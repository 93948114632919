import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
  Box,
  Alert,
  Autocomplete,
  TextField,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import CloudUpload from '@mui/icons-material/CloudUpload';
import axiosClient from 'src/axios_client';
import axios from 'axios';
import imageCompression from 'browser-image-compression';

interface ProductUpdateDialogProps {
  open: boolean;
  onClose: () => void;
  productId: number;
  refreshProductData: () => void;
  productData?: {
    brand?: any;
    category?: any;
    unit?: any;
    minimum_stock?: any;
  };
}

interface OptionType {
  id: number;
  name: string;
  description?: string | null;
}

interface PaginationType {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
  from: number;
  to: number;
}

const ProductUpdateDialog: React.FC<ProductUpdateDialogProps> = ({
  open,
  onClose,
  productId,
  refreshProductData,
  productData,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [brands, setBrands] = useState<OptionType[]>([]);
  const [categories, setCategories] = useState<OptionType[]>([]);
  const [selectedBrand, setSelectedBrand] = useState<OptionType | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<OptionType | null>(null);
  const [brandLoading, setBrandLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState<unitType | null>(null);
  const [isImageUploadEnabled, setIsImageUploadEnabled] = useState(false);
  const [minimumStock, setMinimumStock] = useState<string>('');

  console.log('Selected Product', productData);
  // Fetch initial brand and category data
  const fetchInitialData = async () => {
    if (productData?.brand) {
      setBrandLoading(true);
      try {
        const response = await axiosClient.get('/brands', {
          params: {
            globalFilter: productData.brand.name,
            per_page: 10,
          },
        });

        const brandData = response.data.data;
        setBrands(brandData);

        // Find and set the matching brand
        const matchingBrand = brandData.find((b: OptionType) => b.id === productData.brand?.id);
        if (matchingBrand) {
          setSelectedBrand(matchingBrand);
        }
      } catch (error) {
        console.error('Failed to fetch initial brand data', error);
        setUploadError('Failed to load initial brand data');
      } finally {
        setBrandLoading(false);
      }
    }

    if (productData?.category) {
      setCategoryLoading(true);
      try {
        const response = await axiosClient.get('/categories', {
          params: {
            globalFilter: productData.category.name,
            per_page: 10,
          },
        });

        const categoryData = response.data.data;
        setCategories(categoryData);

        // Find and set the matching category
        const matchingCategory = categoryData.find(
          (c: OptionType) => c.id === productData.category?.id,
        );
        if (matchingCategory) {
          setSelectedCategory(matchingCategory);
        }
      } catch (error) {
        console.error('Failed to fetch initial category data', error);
        setUploadError('Failed to load initial category data');
      } finally {
        setCategoryLoading(false);
      }
    }
  };

  // Initialize with product data when dialog opens
  useEffect(() => {
    if (open && productData) {
      fetchInitialData();

      // Set unit if exists
      if (productData.unit) {
        setSelectedUnit({
          unit: productData.unit,
          label: productData.unit.charAt(0).toUpperCase() + productData.unit.slice(1),
        });
      }
    }
  }, [open, productData]);

  // Debounce function to limit API calls
  const debounce = (func: (...args: any[]) => void, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  // Fetch options based on search input
  const fetchOptions = async (endpoint: string, searchTerm: string) => {
    setBrandLoading(true);
    setCategoryLoading(true);

    try {
      const response = await axiosClient.get(endpoint, {
        params: {
          globalFilter: searchTerm,
          per_page: 10,
        },
      });

      return {
        data: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (error) {
      console.error(`Failed to fetch data from ${endpoint}`, error);
      setUploadError(`Failed to load data from ${endpoint}`);
      return { data: [], pagination: null };
    } finally {
      setBrandLoading(false);
      setCategoryLoading(false);
    }
  };

  // Debounced search for brands
  const searchBrands = debounce(async (searchTerm: string) => {
    if (searchTerm.length < 2) return;

    const { data } = await fetchOptions('/brands', searchTerm);
    setBrands(data);
  }, 500);

  // Debounced search for categories
  const searchCategories = debounce(async (searchTerm: string) => {
    if (searchTerm.length < 2) return;

    const { data } = await fetchOptions('/categories', searchTerm);
    setCategories(data);
  }, 500);

  // Reset states when dialog opens
  useEffect(() => {
    if (open) {
      setBrands([]);
      setCategories([]);
      setSelectedBrand(null);
      setSelectedCategory(null);
      setSelectedFile(null);
      setIsImageUploadEnabled(false);
      setUploadError(null);
    }
  }, [open]);

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 800,
          useWebWorker: true,
        };

        const compressedFile = await imageCompression(file, options);
        const newFile = new File([compressedFile], file.name, {
          type: compressedFile.type,
          lastModified: new Date().getTime(),
        });

        setSelectedFile(newFile);
        setUploadError(null);
      } catch (error) {
        setUploadError('Failed to compress the image. Please try again.');
      }
    }
  };

  const handleUpload = async () => {
    setIsUploading(true);
    const formData = new FormData();

    if (selectedBrand) {
      formData.append('brand_id', selectedBrand.id.toString());
    }
    if (selectedCategory) {
      formData.append('category_id', selectedCategory.id.toString());
    }
    if (selectedUnit) {
      formData.append('unit', selectedUnit.unit);
    }
    if (minimumStock !== '') {
      formData.append('minimum_stock', minimumStock);
    }

    if (isImageUploadEnabled && selectedFile) {
      formData.append('image', selectedFile);
    }

    try {
      await axiosClient.post(`/products/${productId}/details`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      refreshProductData();
      onClose();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorMessage =
          (error.response?.data as { message?: string })?.message ||
          'Failed to update product. Please try again.';
        setUploadError(errorMessage);
      } else {
        setUploadError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setIsUploading(false);
      setSelectedFile(null);
    }
  };

  interface unitType {
    unit: string;
    label: string;
  }

  const units: unitType[] = [
    { unit: 'kg', label: 'Kilogram' },
    { unit: 'g', label: 'Gram' },
    { unit: 'ft', label: 'Foot' },
    { unit: 'l', label: 'Liter' },
    { unit: 'm', label: 'Meter' },
    { unit: 'pc', label: 'Piece' },
  ];

  useEffect(() => {
    if (open && productData) {
      // Safely handle brand data
      if (productData.brand && productData.brand.id && productData.brand.name) {
        setSelectedBrand({
          id: productData.brand.id,
          name: productData.brand.name,
        });
        setBrands((prev) =>
          prev.some((b) => b.id === productData.brand?.id)
            ? prev
            : [...prev, { id: productData.brand.id, name: productData.brand.name }],
        );
      } else {
        setSelectedBrand(null);
      }

      // Safely handle category data
      if (productData.category && productData.category.id && productData.category.name) {
        setSelectedCategory({
          id: productData.category.id,
          name: productData.category.name,
        });
        setCategories((prev) =>
          prev.some((c) => c.id === productData.category?.id)
            ? prev
            : [...prev, { id: productData.category.id, name: productData.category.name }],
        );
      } else {
        setSelectedCategory(null);
      }

      // Safely handle unit data
      if (productData.unit) {
        setSelectedUnit({
          unit: productData.unit,
          label: productData.unit.charAt(0).toUpperCase() + productData.unit.slice(1),
        });
      } else {
        setSelectedUnit(null);
      }

      // Safely handle minimum stock
      if (productData.minimum_stock !== undefined && productData.minimum_stock !== null) {
        setMinimumStock(productData.minimum_stock.toString());
      } else {
        setMinimumStock('');
      }
    }
  }, [open, productData]);

  const handleMinimumStockChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    // Allow only positive numbers and empty string
    if (value === '' || (/^\d*$/.test(value) && parseInt(value) >= 0)) {
      setMinimumStock(value);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        setUploadError(null);
        setSelectedFile(null);
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Update Product Details</DialogTitle>
      <DialogContent>
        <DialogContentText>Update product information. Image upload is optional.</DialogContentText>
        {uploadError && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {uploadError}
          </Alert>
        )}
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          {/* Optional Image Upload */}
          <FormControlLabel
            control={
              <Checkbox
                checked={isImageUploadEnabled}
                onChange={(e) => setIsImageUploadEnabled(e.target.checked)}
              />
            }
            label="Update Product Image"
          />

          {isImageUploadEnabled && (
            <>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="image-file-upload"
                type="file"
                onChange={handleFileSelect}
              />
              <label htmlFor="image-file-upload">
                <Button variant="outlined" component="span" startIcon={<CloudUpload />} fullWidth>
                  Select File
                </Button>
              </label>
              {selectedFile && (
                <Typography variant="body2">Selected file: {selectedFile.name}</Typography>
              )}
            </>
          )}

          {/* Brands Autocomplete with Advanced Search */}
          <Autocomplete
            options={brands}
            loading={brandLoading}
            getOptionLabel={(option) => option.name}
            value={selectedBrand}
            onChange={(event, newValue) => {
              setSelectedBrand(newValue);
            }}
            onInputChange={(event, newInputValue) => {
              searchBrands(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Brand"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {brandLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />

          {/* Categories Autocomplete with Advanced Search */}
          <Autocomplete
            options={categories}
            loading={categoryLoading}
            getOptionLabel={(option) => option.name}
            value={selectedCategory}
            onChange={(event, newValue) => {
              setSelectedCategory(newValue);
            }}
            onInputChange={(event, newInputValue) => {
              searchCategories(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Category"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {categoryLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />

          {/* Unit Autocomplete */}
          <Autocomplete
            options={units}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
            value={selectedUnit}
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                setSelectedUnit({ unit: newValue, label: newValue });
              } else if (newValue && newValue.unit) {
                setSelectedUnit(newValue);
              } else {
                setSelectedUnit(null);
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="Select or Type Product Unit" variant="outlined" />
            )}
            freeSolo
            onInputChange={(event, inputValue) => {
              if (inputValue && !units.some((unit) => unit.label === inputValue)) {
                setSelectedUnit({ unit: inputValue, label: inputValue });
              }
            }}
          />

          <TextField
            label="Minimum Stock"
            type="text"
            value={minimumStock}
            onChange={handleMinimumStockChange}
            variant="outlined"
            fullWidth
            placeholder="Enter minimum stock level"
            InputProps={{
              inputProps: { min: 0 },
            }}
            helperText="Minimum stock level for inventory management"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
            setUploadError(null);
            setSelectedFile(null);
          }}
          color="error"
        >
          Cancel
        </Button>
        <Button
          onClick={handleUpload}
          disabled={isUploading || (isImageUploadEnabled && !selectedFile)}
          color="primary"
        >
          {isUploading ? 'Updating...' : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductUpdateDialog;
