import React, { useRef, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from '../../components/shared/DashboardCard';
import PurchaseEntryForm from './components/PurchaseEntryForm';
import PurchaseEntryTransactionsTable from './components/PurchaseEntryTransactionsTable';
import { IconFile3d, IconTrash } from '@tabler/icons';
import { Divider } from 'antd';
import { useEssentialDataContext } from 'src/contexts/EssentialDataContext';

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    to: '/purchases',
    title: 'Sales',
  },
  {
    title: 'Purchase Entry',
  },
];

const Purchases = () => {
  const [selectedTransaction, setSelectedTransaction] = useState<any | null>(null);
  const [isPosting, setIsPosting] = useState(false);

  const purchaseEntryFormRef = useRef<{
    postTransaction: () => void;
    clearTransaction: () => void;
  }>(null);
  const transactionsTableRef = useRef<{ refetch: () => void }>(null);

  const handleRefreshTransactions = () => {
    transactionsTableRef.current?.refetch();
    setSelectedTransaction(null);
  };

  const handlePostTransaction = React.useCallback(() => {
    if (isPosting) return;

    setIsPosting(true);

    try {
      purchaseEntryFormRef.current?.postTransaction();

      setTimeout(() => {
        transactionsTableRef.current?.refetch();
        setIsPosting(false);
      }, 1000);
    } catch (error) {
      setIsPosting(false);
    }
  }, [isPosting]);

  const handleClearTransaction = () => {
    purchaseEntryFormRef.current?.clearTransaction();
    setSelectedTransaction(null);
  };

  const { userData } = useEssentialDataContext();

  const userlevel = userData?.userlevel;

  const handleOpenFloatingPurchase = (transaction: any) => {
    console.log('Floating transaction:', transaction);
    setSelectedTransaction(transaction);
    
    // Add any additional logic you need for handling floating transactions
  };

  return (
    <PageContainer title="RKCWMS - Purchases" description="this is Sample page">
      <Breadcrumb title="Purchases" items={BCrumb} />
      <DashboardCard
        title="Purchase Entry"
        action={
          <Box>
            <Grid container spacing={1}>
              <Button
                style={{ marginRight: '8px' }}
                onClick={handleClearTransaction}
                startIcon={<IconTrash />}
              >
                Clear Transaction
              </Button>
              <Button
                style={{ marginRight: '8px' }}
                onClick={handlePostTransaction}
                startIcon={<IconFile3d />}
              >
                {selectedTransaction ? 'Update Transaction' : 'Post Transaction'} 
              </Button>
            </Grid>
          </Box>
        }
      >
        <Box>
          <PurchaseEntryForm
            ref={purchaseEntryFormRef}
            onRefreshTransactions={handleRefreshTransactions}
            selectedTransaction={selectedTransaction}
            userlevel={userlevel}
          />
        </Box>
      </DashboardCard>

      <Divider />
      <DashboardCard title="Transaction History" subtitle="See history of purchase transactions">
        <PurchaseEntryTransactionsTable
          onRefetchRef={transactionsTableRef}
          onOpenFloatingPurchase={handleOpenFloatingPurchase}
          userlevel={userlevel}
        />
      </DashboardCard>
    </PageContainer>
  );
};

export default Purchases;
