import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from 'src/components/shared/DashboardCard';
import SelectedCustomerReturnsTable from './SelectedCustomerReturnsTable';
import { useTransaction } from 'src/contexts/CustomerReturnsContext';
import { useNavigate } from 'react-router';

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    title: 'Reports',
  },
  {
    title: 'Returns By Customer Page',
  },
];

const SelectedCustomerReturns = () => {
  const { supplyCenterId, customerId, dateRange } = useTransaction();
  const navigate = useNavigate();

  console.log('CustomerID: ', customerId);
  console.log('dateRange: ', dateRange);

  useEffect(() => {
    if (!customerId) {
      console.warn('No transaction ID found, redirecting to purchase report');
      navigate('/report-returns-by-customer');
    }
  }, [customerId, navigate]);

  if (!customerId) {
    return null; // or show a loading spinner/message
  }

  if (!supplyCenterId) {
    return null; // or show a loading spinner/message
  }

  return (
    <PageContainer title="RKCWMS - Returns By Customer Page" description="this is Sample page">
      {/* breadcrumb */}
      <Breadcrumb title="Returns By Customer Page" items={BCrumb} />
      {/* end breadcrumb */}
      <Box>
        <SelectedCustomerReturnsTable supplyCenterId={supplyCenterId} customerId={customerId} dateRange={dateRange} />
      </Box>
    </PageContainer>
  );
};

export default SelectedCustomerReturns;
