import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  DialogActions,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomTextField from 'src/components/theme-elements/CustomTextField';
import axiosClient from 'src/axios_client';
import StatusAlert from 'src/components/shared/AlertResponse'; // Adjust the import path as per your directory structure
import CustomSelect from 'src/components/theme-elements/CustomSelect';
import CloudUpload from '@mui/icons-material/CloudUpload';
import imageCompression from 'browser-image-compression';

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Invalid email format').required('Email is required'),
  username: yup.string().required('Username is required'),
  userlevel: yup.string().required('Userlevel is required'),
});

type DialogFormProps = {
  isOpen: boolean;
  isEditing: boolean;
  initialData: {
    id: number;
    name: string;
    email: string;
    username: string;
    userlevel: string;
    oldUsername: string;
    supply_centers: string;
  } | null;
  onClose: () => void;
  onSubmit: (payload: {
    id: number | null;
    name: string;
    email: string;
    username: string;
    userlevel: string;
    oldUsername: string;
    supply_centers: string;
  }) => void;
  refetch: () => void;
};

interface userType {
  value: string;
  label: string;
}

// 'superadmin','admin','purchasing','stockman','encoder','checker','accounting','control'

const countries: userType[] = [
  {
    value: 'checker',
    label: 'Checker',
  },
  {
    value: 'encoder',
    label: 'Encoder',
  },
  {
    value: 'stockman',
    label: 'Stockman',
  },
  {
    value: 'accounting',
    label: 'Accounting Officer',
  },
  {
    value: 'control',
    label: 'Control Officer',
  },
  {
    value: 'purchasing',
    label: 'Purchasing Officer',
  },
  {
    value: 'admin',
    label: 'Admin',
  },
  {
    value: 'superadmin',
    label: 'Superadmin',
  },
  {
    value: 'approver',
    label: 'Approver',
  },
  {
    value: 'customer',
    label: 'Customer',
  },
  {
    value: 'ipo_officer',
    label: 'IPO Monitoring Officer',
  },
  {
    value: 'viewer_l1',
    label: 'Viewer L1',
  },
  {
    value: 'viewer_l2',
    label: 'Viewer L2',
  },
  {
    value: 'viewer_l3',
    label: 'Viewer L3',
  },
];

const DialogForm: React.FC<DialogFormProps> = ({
  isOpen,
  isEditing,
  initialData,
  onClose,
  onSubmit,
  refetch,
}) => {
  const [statusCode, setStatusCode] = useState(0);
  const [statusData, setStatusData] = useState([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isImageUploadEnabled, setIsImageUploadEnabled] = useState(false);

  let apiResponse = 0;

  const formik = useFormik({
    initialValues: {
      id: initialData?.id || null,
      name: initialData?.name || '',
      email: initialData?.email || '',
      username: initialData?.username || '',
      oldUsername: initialData?.username || '',
      userlevel: initialData?.userlevel || '',
      supply_centers: initialData?.supply_centers || '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsUploading(true);

      try {
        if (isEditing) {
          // For update operations - use FormData to handle file upload
          const formData = new FormData();
          formData.append('name', values.name);
          formData.append('email', values.email);
          formData.append('username', values.username);
          formData.append('oldUsername', values.oldUsername);
          formData.append('userlevel', values.userlevel);
          formData.append('supply_centers', values.supply_centers);
          formData.append('_method', 'PUT');

          if (isImageUploadEnabled && selectedFile) {
            formData.append('specimen', selectedFile);
          }

          const response = await axiosClient.post(`/system_users/${values.id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          console.log('Server Response:', response.data);
          apiResponse = response.status;
        } else {
          // For create operations - regular JSON payload without file upload
          const response = await axiosClient.post('/system_users', {
            name: values.name,
            email: values.email,
            username: values.username,
            userlevel: values.userlevel,
            supply_centers: values.supply_centers,
          });
          console.log('Server Response:', response.data);
          apiResponse = response.status;
        }

        refetch();
        // formik.resetForm();
        setSelectedFile(null);
        setIsImageUploadEnabled(false);
      } catch (error: any) {
        if (error.response && error.response.status === 422) {
          const errorMessage = error.response.data;
          apiResponse = error.response.status;
          setStatusData(errorMessage);
        } else if (error.response && error.response.status) {
          apiResponse = error.response.status;
        }
        console.error('Error submitting form:', error);
      } finally {
        setIsUploading(false);
        setStatusCode(apiResponse);
        onSubmit(values);
      }
    },
  });

  useEffect(() => {
    if (initialData) {
      console.log('Initial data', initialData);
      formik.setValues({
        id: initialData.id || null,
        name: initialData.name || '',
        email: initialData.email || '',
        username: initialData.username || '',
        oldUsername: initialData.username || '',
        userlevel: initialData.userlevel || '',
        supply_centers: initialData.supply_centers || '',
      });
    }
  }, [initialData, formik.setValues]);

  const [supplyCenters, setSupplyCenters] = useState<any[]>([]);

  useEffect(() => {
    const fetchSupplyCenters = async () => {
      try {
        const response = await axiosClient.get('/supply_centers');
        const transformedData = response.data.data.map((center: any) => ({
          value: center.id,
          label: center.name,
        }));
        setSupplyCenters(transformedData);
      } catch (error) {
        console.error('Error fetching supply centers:', error);
      }
    };

    fetchSupplyCenters();
  }, []);

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.type === 'image/svg+xml') {
        // Handle SVG file directly
        setSelectedFile(file);
        setUploadError(null);
      } else {
        try {
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 800,
            useWebWorker: true,
          };

          const compressedFile = await imageCompression(file, options);
          const newFile = new File([compressedFile], file.name, {
            type: compressedFile.type,
            lastModified: new Date().getTime(),
          });

          setSelectedFile(newFile);
          setUploadError(null);
        } catch (error) {
          setUploadError('Failed to compress the image. Please try again.');
        }
      }
    }
  };

  return (
    <>
      {(statusCode || statusCode !== 0) && (
        <StatusAlert statusCode={statusCode} statusData={statusData} />
      )}
      <Dialog open={isOpen} onClose={onClose}>
        {/* Render StatusAlert if statusCode is set */}
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>
            {isEditing ? `Edit SystemUser [${initialData?.name}]` : 'Add SystemUser'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>You are editing the device setup.</DialogContentText>
            <Box mt={2}>
              <CustomTextField
                autoFocus
                margin="dense"
                id="name"
                name="name"
                label="Name"
                type="text"
                fullWidth
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <CustomTextField
                autoFocus
                margin="dense"
                id="username"
                name="username"
                label="Username"
                type="text"
                fullWidth
                value={formik.values.username}
                error={formik.touched.username && Boolean(formik.errors.username)}
                helperText={formik.touched.username && formik.errors.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <CustomTextField
                margin="dense"
                id="email"
                name="email"
                label="Email"
                type="text"
                fullWidth
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FormControl fullWidth variant="outlined">
                <InputLabel id="userlevel-label">User Level</InputLabel>
                <CustomSelect
                  labelId="userlevel-label"
                  id="userlevel"
                  name="userlevel"
                  value={formik.values.userlevel}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.userlevel && Boolean(formik.errors.userlevel)}
                  label="User Level"
                >
                  {countries.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </CustomSelect>
                {formik.touched.userlevel && formik.errors.userlevel && (
                  <Box component="span" sx={{ color: 'error.main' }}>
                    {formik.errors.userlevel}
                  </Box>
                )}
              </FormControl>

              <FormControl fullWidth variant="outlined">
                <InputLabel id="supply_centers-label">Supply Center</InputLabel>
                <CustomSelect
                  labelId="supply_centers-label"
                  id="supply_centers"
                  name="supply_centers"
                  value={formik.values.supply_centers}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.supply_centers && Boolean(formik.errors.supply_centers)}
                  label="Select Assigned Supply Center"
                >
                  {supplyCenters.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </CustomSelect>
                {formik.touched.supply_centers && formik.errors.supply_centers && (
                  <Box component="span" sx={{ color: 'error.main' }}>
                    {formik.errors.supply_centers}
                  </Box>
                )}
              </FormControl>

              {/* Optional Image Upload */}

              {isEditing && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isImageUploadEnabled}
                        onChange={(e) => setIsImageUploadEnabled(e.target.checked)}
                      />
                    }
                    label="Update User Specimen"
                  />
                  {isImageUploadEnabled && (
                    <>
                      <input
                        accept="image/*,.svg"
                        style={{ display: 'none' }}
                        id="image-file-upload"
                        type="file"
                        onChange={handleFileSelect}
                      />
                      <label htmlFor="image-file-upload">
                        <Button
                          variant="outlined"
                          component="span"
                          startIcon={<CloudUpload />}
                          fullWidth
                        >
                          Select File
                        </Button>
                      </label>
                      {selectedFile && (
                        <Typography variant="body2">Selected file: {selectedFile.name}</Typography>
                      )}
                    </>
                  )}
                </>
              )}
            </Box>
          </DialogContent>
          <DialogActions sx={{ mb: 2, mr: 2 }}>
            <Button color="error" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">{isEditing ? 'Update' : 'Add'}</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default React.memo(DialogForm);
