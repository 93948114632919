import React, { FC, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Grid,
  useTheme,
  Alert,
  Divider,
} from '@mui/material';
import dayjs from 'dayjs';
import useFormattedNumber from 'src/hooks/useFormattedNumber';
import ReactToPrint from 'react-to-print';
import './print.css'; // Import the CSS file
import { useSelector } from 'react-redux';
import { AppState } from 'src/store/Store';
import { ReactComponent as LogoDark } from 'src/assets/images/logos/dark-logo.svg';
import { ReactComponent as LogoLight } from 'src/assets/images/logos/light-logo.svg';
import { message } from 'antd';
import { formatDateWithTime } from 'src/globals/PublicFunctions';

type InvoiceData = {
  id: string;
  invoice_number: string;
  po_number: string;
  purchases_array: any;
  status: any;
  reference_number: string;
  purchase_date: string;
  supplier_code: string;
  supplier_name: string;
  supplier_address: string;
  total_amount: any;
  supplier_contact_person: string;
  transactions: {
    product_name: string;
    description: string;
    unit: string;
    cost: number;
    quantity: number;
    amount: number;
    model: any;
    sku: any;
    serial_numbers: any;
  }[];
  created_by_userid: string;
  created_by_name: string;
  subtotal: number;
};

interface GridItemProps {
  data: InvoiceData;
}

const GridItem: FC<GridItemProps> = ({ data }) => {
  const { formatWithPrefix } = useFormattedNumber();

  // const theme = useTheme();
  const customizer = useSelector((state: AppState) => state.customizer);
  console.log('Transaction Datas:', data);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        padding: { sm: '20px', md: '0px' },
      }}
    >

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mb: 1,
        }}
      >
        <Box>
          <Box sx={{ width: '250px', marginLeft: '-36px', marginBottom: '5px' }}>
            {customizer.activeMode === 'dark' ? (
              <LogoLight height={'auto'} />
            ) : (
              <LogoDark height={'auto'} />
            )}
          </Box>
          <Typography
            component="p"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            RAEL KITZ CORPORATION - WMS IS
          </Typography>

          <Typography
            variant="body2"
            sx={{ color: 'text.secondary', displayPrint: 'none' }}
            className="fontSize8pt"
          >
            RRF Building San Miguel Calasiao, Pangasinan
            <br />
            +1 (123) 456 7891, +44 (876) 543 2198
          </Typography>

          {/* <Typography variant="body2"
            sx={{ color: 'text.secondary' }}
          >
            Purchase Date: {dayjs(data.purchase_date).format('MMMM DD, YYYY')}
          </Typography> */}
          <Typography
            variant="body2"
            sx={{ mt: 1, fontWeight: 'bold', textTransform: 'uppercase' }}
            className="fontSize8pt"
          >
            {data.status == "floating" ? 'INITIAL RECEIVING (FLOATING)' : 'FINAL RECEIVING (FINALIZED)'}
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          <Typography
            variant="h6"
            component="p"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            {data.po_number}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            Reference Number: {data.reference_number} <br />
            Invoice Number: {data.invoice_number} <br />
            Purchase Date: {dayjs(data.purchase_date).format('MMMM DD, YYYY')}

            {/* Transaction ID: {data.id} */}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            Supplier: {data.supplier_name} ({data.supplier_code})
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: 'text.secondary', displayPrint: 'block' }}
            className="fontSize8pt"
          >
            Address: {data.supplier_address}
            <br />
            Contact Person: {data.supplier_contact_person}
            <br />
          </Typography>
        </Box>
      </Box>
      <Table className="printTable" sx={{ width: '100%', textAlign: 'left' }}>
        <TableHead sx={{ backgroundColor: 'grey.200' }}>
          <TableRow>
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">ITEM CODE</TableCell>
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">PRODUCT NAME</TableCell>
            {/* <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">MODEL</TableCell> */}
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">SERIAL NO.</TableCell>
            {/* <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">UNIT</TableCell> */}
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">QTY</TableCell>
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">COST</TableCell>
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">AMOUNT</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.status == 'floating' &&
            data.purchases_array.map((transaction: any, index: any) => {
              return (<TableRow key={index}>
                <TableCell sx={{ py: 1 }} className="fontSize8pt">{transaction.sku}</TableCell>
                <TableCell sx={{ py: 1 }} className="fontSize8pt">
                  {transaction.product_name}
                </TableCell>
                {/* <TableCell sx={{ py: 1 }} className="fontSize8pt">{transaction.model}</TableCell> */}
                <TableCell sx={{ py: 1 }} className="fontSize8pt">{transaction.serial_numbers}</TableCell>
                {/* <TableCell sx={{ py: 1 }} className="fontSize8pt">{transaction.unit}</TableCell> */}
                <TableCell sx={{ py: 1 }} className="fontSize8pt">{transaction.quantity} {transaction.unit}</TableCell>
                <TableCell sx={{ py: 1 }} className="fontSize8pt">{formatWithPrefix(parseFloat(transaction.purchase_price))}</TableCell>
                <TableCell sx={{ py: 1 }} className="fontSize8pt">{formatWithPrefix((parseFloat(transaction.purchase_price) * parseFloat(transaction.quantity)))}</TableCell>
              </TableRow>)
            })
          }
          {
            data.status != 'floating' &&
            data.transactions.map((transaction, index) => (
              <TableRow key={index}>
                <TableCell sx={{ py: 1 }} className="fontSize8pt">{transaction.sku}</TableCell>
                <TableCell sx={{ py: 1 }} className="fontSize8pt">
                  {transaction.product_name}
                </TableCell>
                {/* <TableCell sx={{ py: 1 }} className="fontSize8pt">{transaction.model}</TableCell> */}
                <TableCell sx={{ py: 1 }} className="fontSize8pt">{transaction.serial_numbers}</TableCell>
                {/* <TableCell sx={{ py: 1 }} className="fontSize8pt">{transaction.unit}</TableCell> */}
                <TableCell sx={{ py: 1 }} className="fontSize8pt">{transaction.quantity} {transaction.unit}</TableCell>
                <TableCell sx={{ py: 1 }} className="fontSize8pt">{formatWithPrefix(transaction.cost)}</TableCell>
                <TableCell sx={{ py: 1 }} className="fontSize8pt">{formatWithPrefix(transaction.amount)}</TableCell>
              </TableRow>
            ))
          }
          {/* {data.transactions.map((transaction, index) => (
            <TableRow key={index}>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">{transaction.sku}</TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.product_name}
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">{transaction.serial_numbers}</TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">{formatWithPrefix(transaction.cost)}</TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">{transaction.quantity} {transaction.unit}</TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">{formatWithPrefix(transaction.amount)}</TableCell>
            </TableRow>
          ))} */}


          <TableRow>
            <TableCell sx={{ py: 1, fontWeight: 'bold' }} className="fontSize8pt">
              Subtotal:
            </TableCell>
            <TableCell sx={{ py: 1, whiteSpace: 'pre-line' }}>
            </TableCell>
            {/* <TableCell sx={{ py: 1 }} className="fontSize8pt"></TableCell> */}
            {/* <TableCell sx={{ py: 1 }} className="fontSize8pt"></TableCell> */}
            <TableCell sx={{ py: 1 }} className="fontSize8pt"></TableCell>
            <TableCell sx={{ py: 1 }} className="fontSize8pt"></TableCell>
            <TableCell sx={{ py: 1 }} className="fontSize8pt"></TableCell>
            <TableCell sx={{ py: 1, fontWeight: 'bold' }}>
              {formatWithPrefix(data.status != 'floating' ? data.subtotal : data.total_amount)}
            </TableCell>
          </TableRow>

        </TableBody>
      </Table>

      <Divider sx={{ borderColor: '#acacac' }} />
      <Grid
        container
        columnSpacing={5}
        sx={{ fontSize: { md: '8pt', lg: '10pt' }, display: 'none', displayPrint: 'flex' }}
      >
        {/* <Grid item xs={2.5} className="fontSize8pt">
          Requested By:
          <Divider sx={{ borderColor: 'black', mt: { xs: 5 } }} />
        </Grid> */}
        <Grid item xs={5} className="fontSize8pt">
          Prepared By:
          <Divider sx={{ borderColor: 'black', mt: { xs: 5 } }} />
        </Grid>
        <Grid item xs={7}>
          <Grid container columnSpacing={1}>
            <Grid item className="fontSize8pt">
              Checked By:
            </Grid>
            <Grid item flexGrow={1}>
              <Grid container columnSpacing={2}>
                <Grid item className="fontSize8pt">
                  Stockman
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <Divider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
              <Grid container columnSpacing={2} mt={1}>
                <Grid item className="fontSize8pt">
                  Checker
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <Divider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
              {/* <Grid container columnSpacing={2} mt={1}>
                <Grid item className="fontSize8pt">
                  Hauler Representative
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <Divider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
              <Grid container columnSpacing={2} mt={1}>
                <Grid item className="fontSize8pt">
                  Customer Authorized Representative
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <Divider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} mt={1}>
          <Grid container columnSpacing={1} justifyContent={'between'}>
            <Grid item xs={8}>
              <Typography sx={{ fontWeight: 'bold' }} className="fontSize8pt">
                Received the above goods in full and good condition.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid item flexGrow={1}>
                <Grid container columnSpacing={2}>
                  <Grid item className="fontSize8pt">
                    Received By:
                  </Grid>
                  <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                    <Divider sx={{ borderColor: 'black' }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center' }} mt={1}>
        <Divider sx={{ borderColor: 'black' }} />
      </Grid>

      {/* <Grid item sx={{ textAlign: 'left' }}>
        <Typography
          variant="body1"
          sx={{ color: 'text.primary', fontWeight: 'bold' }}
          className="fontSize8pt"
        >
          Hauler: {data.hauler}
        </Typography>
      </Grid> */}

      <Grid item xs={12} sx={{ textAlign: 'center' }} mt={1}>
        <Grid container columnSpacing={1}>
          <Grid item>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
              WAREHOUSE MANAGEMENT SYSTEM - by: INFORMATION SYSTEMS{' '}
              {formatDateWithTime(data.purchase_date)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
              Processed By: {data.created_by_name}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        Processor: {data.created_by_name}
      </Typography> */}
    </Box>
  );
};

const Invoice: FC<{ data: InvoiceData }> = ({ data }) => {
  const componentRef = useRef(null);
  const [sucess, setSucess] = useState('');
  const [error, setError] = useState('');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        p: 4,
        bgcolor: 'background.paper',
        borderRadius: 1,
        boxShadow: 3,
      }}
    >

      <Grid container spacing={4}>
        <Grid item xs={12}>
          {sucess && (
            <Alert variant="filled" severity="success" sx={{ alignItems: 'center', mb: 1 }}>
              {sucess}
            </Alert>
          )}

          {error && (
            <Alert variant="filled" severity="error" sx={{ alignItems: 'center', mb: 1 }}>
              {error}
            </Alert>
          )}
        </Grid>
        <Grid item xs={12} md={9} ref={componentRef}>
          <GridItem data={data} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {/* <Button variant="contained" color="primary" sx={{ py: 1.5, px: 3, borderRadius: 1 }}>
              Send Transaction Record
            </Button>
            <Button variant="outlined" color="primary" sx={{ py: 1.5, px: 3, borderRadius: 1 }}>
              Download
            </Button> */}
            <ReactToPrint
              trigger={() => (
                <Button size='small' variant='contained' color="primary">
                  Print
                </Button>
              )}
              content={() => componentRef.current}
              pageStyle={`
                @media print {
                  body {
                    -webkit-print-color-adjust: exact;
                    margin: 20mm;
                    padding: 50px;
                  }
                  @page {
                    margin: 20mm;
                  }
                  .printTable {
                    font-size: 7px;
                  }
                  .printTable th,
                  .printTable td {
                    padding: 2px;
                  }
                  header, footer {
                    display: none;
                  }
                  .printable-logo {
                    fill: #000000 !important; /* Ensure the logo prints in black */
                  }
                }
              `}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Invoice;
