import { Check, LocalShipping, RefreshOutlined, ThumbUpAlt, Waves } from '@mui/icons-material';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers';
import { IconEdit, IconInfoCircle, IconPencil, IconTrash } from '@tabler/icons';
import {
  keepPreviousData,
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import { Image, message, Popover, Watermark } from 'antd';
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  useMaterialReactTable,
} from 'material-react-table';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { AnyIfEmpty, useSelector } from 'react-redux';
import axiosClient from 'src/axios_client';
import PageContainer from 'src/components/container/PageContainer';
import { useEssentialDataContext } from 'src/contexts/EssentialDataContext';
import { formatDate, formatDateWithTime } from 'src/globals/PublicFunctions';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import { AppState } from 'src/store/Store';
import { IpoEditForm, IpoRequestForm } from './IpoRequest';
import { LogoDark, LogoLight } from 'src/globals/LogoExports';
import useFormattedNumber from 'src/hooks/useFormattedNumber';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';
import ReactToPrint from 'react-to-print';
import ConfirmationButton from './components/ConfirmationButton';
import { toast, ToastContainer } from 'react-toastify';
import { uniq } from 'lodash';
import { InfoCircleOutlined } from '@ant-design/icons';

type Props = {};

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    title: 'IPO Transactions',
  },
];
type PurposeOption = {
  label: any;
  value: any;
};
const queryClient = new QueryClient();

function IpoTransactions({ }: Props) {
  const customizer = useSelector((state: AppState) => state.customizer);
  const theme = useTheme();
  const borderColor = theme.palette.grey[100];
  const isSmallScreen = useMediaQuery('(max-width:375px)');

  const [purpose, setPurpose] = useState<PurposeOption[]>([]);

  const [isFetching, setIsFetching] = useState(true);
  useEffect(() => {
    async function fetchMyAPI() {
      axiosClient
        .get('/ipo_purposes?per_page=all', {})
        .then((response) => {
          const dataResponse = response.data.data;
          console.log(dataResponse);
          const transformedData = dataResponse.map((item: any) => ({
            value: item.id,
            label: item.name,
          }));
          setPurpose(transformedData);
          setIsFetching(false);
        })
        .catch((err) => {
          setIsFetching(false);
          message.error('Something went wrong');
          console.log(err);
        });
    }

    fetchMyAPI();
  }, []);
  return (
    <PageContainer title="RKCWMS - IPO Master Page" description="This is the IPO Page">
      {/* breadcrumb */}
      <Breadcrumb title="IPO Master Page" items={BCrumb} />
      {/* end breadcrumb */}
      <Card
        sx={{
          padding: 0,
          border: !customizer.isCardShadow ? `1px solid ${borderColor}` : 'none',
          maxWidth: isSmallScreen ? '345px' : 'auto',
        }}
        style={{ height: '100%', position: 'relative' }}
      >
        {isFetching == false ? (
          purpose.length > 1 ? (
            <QueryClientProvider client={queryClient}>
              <IPOTableList purpose={purpose} />
            </QueryClientProvider>
          ) : (
            <QueryClientProvider client={queryClient}>
              <IPOTableList purpose={[]} />
            </QueryClientProvider>
          )
        ) : null}
      </Card>
    </PageContainer>
  );
}

type IPO = {
  id: number;
  user_id: string;
  ipo_number: string;
  destination: string;
  department: any;
  project_site: any;
  priority_level: any;
  purpose: any;
  requested_by: any;
  approved_by: any;
  image_name: any;
  image_url: any;
  status: any;
  created_at: any;
  approver_specimen: any;
  final_approver_name: any;
  final_approver_id: any;
  ipo_requests: any[];
  issuance_requests: any;
  approved_at: any;
};

type IPOApiResponse = {
  data: Array<IPO>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

interface DataType {
  key: string;
  id: number;
  name: string;
  description: string;
  unit: string;
  quantity: number;
  unit_price: number;
  price: any;
  amount: number;
  serial_numbers: any;
  takenQuantity: any;
  notice: any;
}

interface Customer {
  id: string;
  code: string;
  name: string;
  address: string;
  contact_person: string;
}

// status == 0 ? 'Pending' : status == 1 ? 'Approved' : status == 2 ? 'Rejected' : status == 3 ? 'Incomplete' : 'Completed'
const allStatusSelect = [
  { label: 'All Pending', value: '0' },
  { label: 'Approved', value: '1' },
  { label: 'Rejected', value: '2' },
  { label: 'Incomplete', value: '3' },
  { label: 'Completed', value: '4' },
  { label: 'Processing', value: '5' },
  { label: 'Pending On You', value: '6' },
  { label: 'Approved By You', value: '7' },
];

interface SVGWrapperProps {
  src: string;
  srcText: any;
  width?: number;
  text?: string;
  color?: string;
  finalApprover?: string;
}

const SVGWrapper: React.FC<SVGWrapperProps> = ({
  src,
  srcText,
  width = 80,
  text = 'sample',
  color = 'red',
  finalApprover = 'sample',
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const loadSVG = async () => {
      try {
        const response = srcText != null ? srcText : await fetch(src);
        const svgText = srcText != null ? srcText : await response.text();





        if (containerRef.current) {
          containerRef.current.innerHTML = svgText;

          const svg = containerRef.current.querySelector('svg');
          if (svg) {
            // Update the color of all paths
            const paths = svg.querySelectorAll('path');
            paths.forEach((path) => {
              path.setAttribute('fill', color); // Change the fill color
            });

            // Add text element
            const dateApprovedTxt = document.createElementNS('http://www.w3.org/2000/svg', 'text');
            dateApprovedTxt.setAttribute('x', '5%');
            dateApprovedTxt.setAttribute('y', '25%');
            dateApprovedTxt.setAttribute('text-anchor', 'start');
            dateApprovedTxt.setAttribute('dominant-baseline', 'middle');
            dateApprovedTxt.setAttribute('fill', 'black');
            dateApprovedTxt.setAttribute('font-size', '30');
            dateApprovedTxt.setAttribute('font-weight', 'bold');
            dateApprovedTxt.textContent = text;

            const finalApproverTxt = document.createElementNS('http://www.w3.org/2000/svg', 'text');
            finalApproverTxt.setAttribute('x', '5%');
            finalApproverTxt.setAttribute('y', '35%');
            finalApproverTxt.setAttribute('text-anchor', 'start');
            finalApproverTxt.setAttribute('dominant-baseline', 'middle');
            finalApproverTxt.setAttribute('fill', 'black');
            finalApproverTxt.setAttribute('font-size', '30');
            finalApproverTxt.setAttribute('font-weight', 'bold');
            finalApproverTxt.textContent = finalApprover;

            svg.appendChild(dateApprovedTxt);
            svg.appendChild(finalApproverTxt);
          }
        }
      } catch (error) {
        console.error('Error loading SVG:', error);
      }
    };

    loadSVG();
  }, [src, color, text]);

  return (
    <div
      ref={containerRef}
      style={{
        width: width,
        position: 'relative',
      }}
    />
  );
};

const IPOTableList = ({ purpose }: any) => {
  const { userData } = useEssentialDataContext();
  console.log(userData);

  if (userData) {
  }
  const statusSelect = allStatusSelect.filter((option) => {
    if (userData) {
      if (userData['userlevel'] === 'customer' || userData['userlevel'] === 'encoder') {
        return option.value !== '6' && option.value !== '7';
      }
    } else {
      return true;
    }

    return true;
  });

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([
    {
      id: 'status',
      value: userData
        ? userData['userlevel'] === 'customer' || userData['userlevel'] === 'encoder'
          ? 0
          : 6
        : 0,
    },
  ]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [editData, setEditData] = useState<any[]>([]);
  const componentRef = useRef(null);
  const [printAuto, setPrintAuto] = useState(false);

  const [printData, setPrintData] = useState<IPO[]>([]);
  const [viewIpoData, setViewIpoData] = useState<IPO>();
  const {
    data: { data = [], pagination: meta } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<IPOApiResponse>({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      try {
        const params = {
          page: `${pagination.pageIndex + 1}`,
          per_page: `${pagination.pageSize}`,
          filters: JSON.stringify(columnFilters ?? []),
          globalFilter: globalFilter ?? '',
          sorting: JSON.stringify(sorting ?? []),
        };
        const response = await axiosClient.get<IPOApiResponse>('/ipo_transaction', { params });
        return response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    },
    placeholderData: keepPreviousData,
  });
  const columns = useMemo<MRT_ColumnDef<IPO>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Transaction Number',
        Cell: ({ row }) => {
          return (
            <>
              <Image
                width={50}
                alt=""
                src={row.original.image_url}
                preview={{
                  width: 'auto',
                  src: row.original.image_url,
                }}
              />
              <Typography
                component={'span'}
                onClick={() => {
                  console.log(row.original);
                  setOpenView(true);
                  setViewIpoData(row.original);
                }}
                sx={{ fontWeight: 'bold', textDecoration: 'underline', marginLeft: 2 }}
                color={'info.main'}
              >
                IPO-{row.original.id.toString().padStart(5, '0')}
              </Typography>
            </>
          );
        },
      },
      // {
      //   accessorKey: 'image_url',
      //   header: 'Receipt',
      //   enableColumnFilter: false,
      //   enableColumnActions: false,
      //   enableSorting: false,
      //   Cell: ({ row }) => {
      //     return (
      //       <Image
      //         width={50}
      //         alt=""
      //         src={row.original.image_url}
      //         preview={{
      //           width: 'auto',
      //           src: row.original.image_url,
      //         }}
      //       />
      //     );
      //   },
      // },
      {
        accessorKey: 'purpose',
        header: 'Purposes',
        filterVariant: 'autocomplete',
        filterSelectOptions: purpose,
        muiFilterAutocompleteProps: ({ column, table }) => ({
          freeSolo: true,
          options: purpose,
          renderInput: (params) => {
            console.log(params);
            return 'Test';
          },
        }),
        // muiTableFilterPopoverProps: {
        //   sx: {
        //     '& .MuiList-root': {
        //       maxHeight: '200px', // Set max height for the dropdown
        //       overflowY: 'auto', // Enable vertical scrolling
        //     },
        //   },
        // },
        Cell: ({ row }) => row.original.purpose,
      },
      {
        accessorKey: 'department',
        header: 'Department Name',
        // enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: 'project_site',
        header: 'Project Site Name',
        // enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: 'requested_by',
        header: 'Requested By',
      },
      {
        accessorKey: 'priority_level',
        header: 'Priority',
        Cell: ({ row }) => {
          const status = row.original.priority_level ?? 'UNKOWN';

          return (
            <Chip
              label={status.toUpperCase()}
              color={
                status == 'low'
                  ? 'info'
                  : status == 'medium'
                    ? 'warning'
                    : 'error'
              }
              size="small"
              sx={{
                ml: 1,
                fontSize: '0.8rem',
                height: '22px',
                minWidth: '50px',
              }}
            />
          );
        },
      },
      {
        accessorKey: 'status',
        header: 'Status',
        filterVariant: 'select',
        filterSelectOptions: statusSelect,
        Cell: ({ row }) => {
          const status = row.original.status;
          const statusText =
            status == 0
              ? 'Pending'
              : status == 1
                ? 'Approved'
                : status == 2
                  ? 'Rejected'
                  : status == 3
                    ? 'Incomplete'
                    : status == 4
                      ? 'Completed'
                      : status == 5
                        ? 'Processing'
                        : 'Unkown';

          return (
            <Chip
              label={statusText}
              color={
                status == 0
                  ? 'info'
                  : status == 1
                    ? 'success'
                    : status == 2
                      ? 'error'
                      : status == 3
                        ? 'warning'
                        : status == 4
                          ? 'success'
                          : status == 5
                            ? 'info'
                            : 'error'
              }
              size="small"
              sx={{
                ml: 1,
                fontSize: '0.8rem',
                height: '22px',
                minWidth: '50px',
              }}
            />
          );
        },
      },
      {
        accessorKey: 'created_at',
        header: 'Date Created',
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: true,
        Cell: ({ row }) => {
          return <>{formatDate(row.original.created_at)}</>;
        },
      },

      {
        accessorKey: 'action',
        header: 'Action',
        Cell: ({ row }) => {
          return (
            <Box>
              <Grid container spacing={1} display={
                row.original.status == 0 || userData?.userlevel == 'superadmin' ? 'flex' : 'none'
              }>
                <Grid item sx={{ display: userData?.userlevel == 'customer' ? 'none' : 'flex' }}>
                  <IconButton
                    sx={{
                      borderRadius: '5px',
                      backgroundColor: '#13DEB9',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#02b3a9',
                        boxShadow: '0 0 1px 0 rgba(0, 0, 0, 0.31), 0 3px 4px -2px rgba(0, 0, 0, 0.25)'
                      }
                    }}
                    // color='success'
                    onClick={() => approvedClick(row.original.id)}
                    aria-label="reset password"
                    size="small"
                  >
                    <Check />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    sx={{
                      borderRadius: '5px',
                      backgroundColor: '#539BFF',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#ae8e59',
                        boxShadow: '0 0 1px 0 rgba(0, 0, 0, 0.31), 0 3px 4px -2px rgba(0, 0, 0, 0.25)'
                      }
                    }}

                    onClick={() => editClick(row.original)}
                    aria-label="reset password"
                    size="small"
                  >
                    <IconPencil />
                  </IconButton>
                </Grid>
                <Grid item sx={{ display: userData?.userlevel == 'customer' ? 'none' : 'flex' }}>
                  <ConfirmationButton
                    onConfirm={() => declineClick(row.original.id)}
                    title="Confirm Decline"
                    description="Are you sure you want to decline this request? This action cannot be undone."
                    buttonText="Decline"
                    startIcon={<ClearIcon />} // Add the start icon
                    buttonProps={{
                      variant: 'outlined', /* color: 'error', */ sx: {
                        borderRadius: '5px',
                        backgroundColor: '#FA896B',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: '#f3704d',
                          boxShadow: '0 0 1px 0 rgba(0, 0, 0, 0.31), 0 3px 4px -2px rgba(0, 0, 0, 0.25)'
                        },
                      }
                    }}
                    confirmButtonText="Yes, Decline"
                    cancelButtonText="No"
                  />
                </Grid>
              </Grid>

            </Box>
          );
        },
      },
    ],
    [],
  );

  const approvedClick = (passedResult: any) => {
    console.log(passedResult);
    setIsSubmitting(true);
    axiosClient
      .put(`/ipo_transaction/${passedResult}`, {
        approved: true,
      })
      .then((response) => {
        setOpenView(false);
        setIsSubmitting(false);
        refetch();
      })
      .catch((error) => {
        setIsSubmitting(false);
        message.error(error.response.data.message, 3);
        console.error('Failed to update IPO Transaction:', error);
      });
  };
  const declineClick = (passedResult: any) => {
    console.log(passedResult);

    axiosClient
      .put(`/ipo_transaction/${passedResult}`, {
        approved: false,
      })
      .then((response) => {
        setOpenView(false);
        refetch();
      })
      .catch((error) => {
        message.error(error.response.data.message, 3);
        console.error('Failed to update IPO Transaction:', error);
      });
  };

  const editClick = (passedResult: any) => {
    setEditData(passedResult);
    setOpenEdit(true);
    // console.log(passedResult);
  }
  const table = useMaterialReactTable({
    columns,
    data,
    initialState: {
      showColumnFilters: true,
      columnPinning: {
        left: ['mrt-row-expand'],
      },
      columnVisibility: {
        action:
          userData?.userlevel == 'customer' || userData?.userlevel == 'approver' || userData?.userlevel == 'superadmin' ? true : false,
      },
    },
    layoutMode: 'grid',
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiTablePaperProps: {
      sx: {
        maxWidth: 'min(100vw, 2200px)'
      }
    },
    muiToolbarAlertBannerProps: isError
      ? {
        color: 'error',
        children: 'Error loading data',
      }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableColumnOrdering: true,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        console.info(row);
      },
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
    }),
    enableRowSelection: true,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px', alignItems: 'center' }}>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => refetch()}>
            <RefreshOutlined />
          </IconButton>
        </Tooltip>
        <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
          Add IPO
        </Button>
        <Button
          sx={{
            display:
              userData?.userlevel == 'approver' || userData?.userlevel == 'superadmin'
                ? 'block'
                : 'none',
          }}
          onClick={() => {
            // const rowSelection = table.getState().rowSelection;
            // const totalRows = table.getPrePaginationRowModel().rows.length;
            // console.log(rowSelection);
            // console.log(totalRows);

            // const selectedRows = table.getSelectedRowModel().rows;

            // const testVariable = selectedRows.filter((values) => values.original.status == 0 ).map((values) => {
            //   return {
            //     id: values.original.id,
            //     user_id: values.original.user_id,
            //   };
            // });

            // console.log(testVariable);

            setOpenApproveAll(true);
          }}
          variant="contained"
          color="success"
        >
          Approve Selected IPO
        </Button>
        <Button
          onClick={async () => {
            const rowSelection = table.getState().rowSelection; //read state
            const selectedRows = table.getSelectedRowModel().rows; //or read entire rows



            const specimenText: any = [];

            const uniqueIds = await Promise.all(
              selectedRows
                .map((row) => row.original.approved_by)
                .filter((id, index, self) => self.indexOf(id) === index)
                .map(async (id, index) => {
                  const details = table.getSelectedRowModel().rows.find(
                    (row) => row.original.approver_specimen != null && row.original.final_approver_name == id
                  );


                  if (details) {
                    console.log(details.original);

                    const response = await fetch(details.original.approver_specimen);

                    return { specimen_id: details.original.final_approver_id, app_specimen: await response.text() };
                  }
                  else {
                    return { specimen_id: null, app_specimen: null };;

                  }


                  // const responseData = await Promise.all(
                  //   details.map(async (row) => {
                  //     const response = await fetch(row.original.approver_specimen);
                  //     return { specimen_id: row.original.final_approver_id, app_specimen: response };
                  //   })
                  // );

                  // const transformedObject = responseData.reduce((acc: any, item: any) => {
                  //   acc['specimen_id'] = item.specimen_id;
                  //   acc['app_specimen'] = item.app_specimen;
                  //   return acc;
                  // }, {});

                  // return transformedObject;
                })
            );

            console.log(uniqueIds);

            const testVariable = selectedRows.map((values) => {
              const matchingData = uniqueIds.find((obj) => obj.specimen_id === values.original.final_approver_id);
              console.log(matchingData);

              return {
                ...values.original,
                specimen_text: matchingData?.app_specimen
              };
            });


            // const response = await fetch(src);



            setPrintAuto(true);

            setPrintData(testVariable);
          }}
        >
          Print Selected IPO
        </Button>
      </Box >
    ),
    renderDetailPanel: ({ row }) => {
      const transactionRequests = row.original.issuance_requests;

      const issuanceRequests: any = [];

      transactionRequests.forEach((transactionReq: any) => {
        const setThisData: any = [];
        const customer = transactionReq.customer;

        const uniqueProductIdsInSerial = Object.values(
          transactionReq.transactions.serial_numbers.reduce((acc: any, product: any) => {
            acc[product.product_id] = product;
            return acc;
          }, {}),
        );
        uniqueProductIdsInSerial.forEach((transaction: any) => {
          // const stockLoc = transactionReq.transactions.stsock_location.filter(
          //   (opt: any) => opt.product_id == transaction.product_id,
          // );

          const serialNumbers = transactionReq.transactions.serial_numbers.filter(
            (opt: any) => opt.product_id === transaction.product_id,
          );

          let locationStocks = transactionReq.transactions.initial_location.filter(
            (filter: any) => filter.product_id == transaction.product_id,
          );

          if (transaction.status == 'posted') {
            locationStocks = transactionReq.transactions.initial_location
              .filter((filter: any) => filter.product_id === transaction.product_id)
              .map((item: any) => item)
              .flat();
          }

          const stockLoc = locationStocks ? locationStocks : [];

          let remainingQuantity = serialNumbers.length;
          let serialIndex = 0;
          const result = [];

          for (const item of stockLoc) {
            if (remainingQuantity <= 0) break;
            const serialsTaken = [];
            let takenQuantity;

            if (item.quantity >= remainingQuantity) {
              takenQuantity = remainingQuantity;
              serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenQuantity));
              result.push({
                ...item,
                takenQuantity,
                serial_numbers: serialsTaken,
              });
              setThisData.push({
                ...item,
                key: serialNumbers[serialIndex].id,
                product_id: transaction.product_id,
                product_name: transaction.product_name,
                product_model: transaction.product_model,
                price: transaction.price,
                notice: transaction.notice,
                product_unit: transaction.product_unit,
                takenQuantity,
                serial_numbers: serialsTaken,
              });
              remainingQuantity = 0;
            } else {
              takenQuantity = item.quantity;
              serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenQuantity));
              result.push({ ...item, takenQuantity, serial_numbers: serialsTaken });
              setThisData.push({
                ...item,
                key: serialNumbers[serialIndex].id,
                product_id: transaction.product_id,
                product_name: transaction.product_name,
                product_model: transaction.product_model,
                price: transaction.price,
                notice: transaction.notice,
                product_unit: transaction.product_unit,
                takenQuantity,
                serial_numbers: serialsTaken,
              });
              remainingQuantity -= takenQuantity;
            }
            serialIndex += takenQuantity;
          }
        });
        transactionReq.transactions.stocks.map((transaction: any, index: any) => {
          let locationStocks = transactionReq.transactions.initial_location.filter(
            (filter: any) => filter.product_id == transaction.product_id && filter.quantity != 0,
          );

          if (transaction.status == 'posted') {
            locationStocks = transactionReq.transactions.stock_location.filter(
              (filter: any) => filter.product_id == transaction.product_id && filter.quantity != 0,
            );
          }

          const stockLoc = locationStocks ? locationStocks : [];
          let remainingQuantity = transaction.quantity;
          const result = [];

          for (const item of stockLoc) {
            if (remainingQuantity <= 0) break;

            if (item.quantity >= remainingQuantity) {
              result.push({
                ...item,
                serial_numbers: [],
                product_id: transaction.product_id,
                product_name: transaction.product_name,
                product_model: transaction.product_model,
                price: item.takenUsed > 0 ? item.value : transaction.price,
                notice: transaction.notice,
                product_unit: transaction.product_unit,
                takenQuantity: remainingQuantity,
              });
              setThisData.push({
                ...item,
                serial_numbers: [],
                key: transaction.id,
                product_name: transaction.product_name,
                product_model: transaction.product_model,
                price: item.takenUsed > 0 ? item.value : transaction.price,
                notice: transaction.notice,
                product_unit: transaction.product_unit,
                takenQuantity: remainingQuantity,
              });
              remainingQuantity = 0;
            } else {
              result.push({
                ...item,
                product_id: transaction.product_id,
                product_name: transaction.product_name,
                product_model: transaction.product_model,
                price: item.takenUsed > 0 ? item.value : transaction.price,
                notice: transaction.notice,
                product_unit: transaction.product_unit,
                takenQuantity: item.quantity,
              });
              setThisData.push({
                ...item,
                serial_numbers: [],
                key: transaction.id,
                product_id: transaction.product_id,
                product_name: transaction.product_name,
                product_model: transaction.product_model,
                price: item.takenUsed > 0 ? item.value : transaction.price,
                notice: transaction.notice,
                product_unit: transaction.product_unit,
                takenQuantity: item.quantity,
              });
              remainingQuantity -= item.quantity;
            }
          }

          if (remainingQuantity > 0) {
            setThisData.push({
              ...transaction,
              serial_numbers: [],
              key: transaction.id,
              product_name: transaction.product_name,
              product_model: transaction.product_model,
              price: transaction.price,
              notice: transaction.notice,
              product_unit: transaction.product_unit,
              takenQuantity: remainingQuantity,
            });
          }
        });
        const invoiceData = {
          id: transactionReq?.id || '',
          invoice_number: transactionReq?.invoice_number || '',
          reference_number: transactionReq?.reference_number || '',
          date_of_issuance: transactionReq?.date_of_issuance || '',
          created_by_userid: transactionReq?.created_by.id || '',
          created_by_name: transactionReq?.created_by.name || '',
          slip_number: transactionReq.slip_number || '',
          customer_code: customer?.code || '',
          customer_name: customer?.name || '',
          customer_address: customer?.address || '',
          status: transactionReq.status || '',
          stock_location_record: transactionReq.stock_location_record || '',
          customer_contact_person: customer?.contact_person || '',
          transactions: setThisData.map((item: any) => ({
            ...item,
            notice: item.notice,
            keys: Array.from(new Set([item.key, ...item.serial_numbers.map((sn: any) => sn.id)])),
          })),
          subtotal: setThisData.reduce((sum: any, product: any) => {
            return (sum += product.takenQuantity * product.price);
          }, 0),
          newSubTotal:
            setThisData.reduce((sum: any, product: any) => {
              return (sum += product.takenQuantity * product.price);
            }, 0) - transactionReq.total_lost_amount - transactionReq.total_excess_amount,
          total_lost_amount: transactionReq.total_lost_amount,
          created_by: transactionReq.created_by,
          created_at: transactionReq.created_at,
          checked_at: transactionReq.checked_at,
          checked_by: transactionReq.checked_by,
          checked_note: transactionReq.checked_note,
          released_by: transactionReq.released_by,
          released_at: transactionReq.released_at,
          released_note: transactionReq.released_note,
          received_at: transactionReq.received_at,
        };

        issuanceRequests.push(invoiceData);
      });

      return (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Card elevation={6}>
              <>
                <CardHeader
                  title={
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography variant="h3">RAEL KITZ CORPORATION</Typography>
                      <Typography variant="subtitle2">
                        Centrum Fuel Statio, Brgy. Talibaew, Calasiao, Pangasinan
                      </Typography>
                      <Typography variant="subtitle2">{`Telefax: (075) 653-0008`}</Typography>
                    </Box>
                  }
                />
                <Divider />
              </>
              <CardContent>
                <Grid container spacing={0.5}>
                  <Grid item xs={12}>
                    <Grid container sx={{ alignItems: 'center' }}>
                      <Grid item xs={8}>
                        <Typography variant="h6">INTERNAL PURCHASE ORDER FORM</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'right' }}>
                          IPO-{row.original.id.toString().padStart(5, '0')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={8}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Typography>TO:</Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography>{row.original.destination}</Typography>
                        <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: 'right' }}>
                    <Chip
                      label={formatDate(row.original.created_at)}
                      color={'info'}
                      variant="outlined"
                      size="small"
                      sx={{
                        ml: 1,
                        fontSize: '0.8rem',
                        height: '22px',
                        minWidth: '50px',
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Typography>Department:</Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography>{row.original.department}</Typography>
                        <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Typography>Project Site:</Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography>{row.original.project_site}</Typography>
                        <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Typography>Purposes:</Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography>{row.original.purpose}</Typography>
                        <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} mt={3}>
                    <TableContainer component={Paper} elevation={12}>
                      <Table size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell>QTY</TableCell>
                            <TableCell align="right">Item Name</TableCell>
                            <TableCell align="right">Unit Price</TableCell>
                            <TableCell align="right">Amount</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {row.original.ipo_requests.map((ipoRow) => {
                            return (
                              <TableRow
                                key={ipoRow.id}
                                sx={{
                                  '&:last-child td, &:last-child th': { border: 0 },
                                  '& .MuiTableCell-body': {
                                    color:
                                      row.original.status == 3 || row.original.status == 5
                                        ? ipoRow.remaining_quantity > 0
                                          ? 'rgb(255, 174, 31)'
                                          : 'black'
                                        : 'black',
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {ipoRow.quantity}
                                </TableCell>
                                <TableCell align="right">
                                  {ipoRow.product_name}

                                  {ipoRow.note &&

                                    <Popover content={<>
                                      {ipoRow.note}
                                    </>} title="Note">
                                      <IconButton sx={{ padding: 0, marginLeft: 1 }}>
                                        <IconInfoCircle size={18} color='#539BFF' />
                                      </IconButton>
                                    </Popover>
                                  }
                                </TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  <Grid item xs={12} mt={8}>
                    <Grid container spacing={{ sm: 1, md: 5 }}>
                      <Grid item xs={6} sx={{ textAlign: 'center' }}>
                        <Typography>{row.original.requested_by}</Typography>
                        <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
                        <Typography variant="h6">Requested By</Typography>
                      </Grid>

                      <Grid item xs={6} sx={{ textAlign: 'center', position: 'relative' }}>
                        <Box
                          sx={{ position: 'absolute', top: -70, width: '-webkit-fill-available' }}
                        >
                          {row.original.approver_specimen && (
                            <SVGWrapper
                              src={row.original.approver_specimen}
                              srcText={null}
                              width={200}
                              color="red"
                              text={row.original.approved_at}
                              finalApprover={row.original.final_approver_name}
                            />
                          )}
                        </Box>

                        <Typography>{row.original.approved_by}</Typography>
                        <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
                        <Typography variant="h6">Approved By</Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* <Grid item xs={12}>
                    <Typography variant='body2' sx={{ fontWeight: 'bold', fontStyle: 'italic', color: 'red' }}>
                      Note: The empty item fields or the quantity 0 won't be saved in request
                    </Typography>
                  </Grid> */}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={8}>
            {issuanceRequests.map((issuanceData: any, index: any) => {
              return (
                <Card elevation={6} key={index}>
                  <IpoIssuances data={issuanceData} />
                </Card>
              );
            })}
          </Grid>
        </Grid>
      );
    },
    rowCount: meta?.total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });



  useEffect(() => {
    if (printData.length > 0 && printAuto == true) {
      // Trigger the print button click only after printData has been set
      const printButton = document.getElementById('print-button');
      if (printButton) {
        printButton.click();
        setPrintAuto(false);
      }
    }
  }, [printData]);

  const handleClose = (event: any, reason: any) => {

    console.log(reason);
    if (reason != 'backdropClick') {
      setOpen(false);
    }
    // 
  };
  const [openView, setOpenView] = useState(false);
  const handleCloseView = () => {
    setOpenView(false);
  };

  const [openApproveAll, setOpenApproveAll] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const handleCloseApproveAll = () => {
    setOpenApproveAll(false);
  };
  const [isSubmitting, setIsSubmitting] = useState(false);

  const cancelClick = () => {
    setOpen(false);
    setOpenEdit(false);

  }
  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        disableEscapeKeyDown
        maxWidth={'sm'}
        sx={{ zIndex: 999 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IpoRequestForm refetch={refetch} cancelCLick={cancelClick} />
      </Dialog>

      <Dialog
        open={openEdit}
        onClose={(event, reason) => {
          if (reason != 'backdropClick') {
            setOpenEdit(false);
          }
        }}
        fullWidth
        disableEscapeKeyDown
        maxWidth={'sm'}
        sx={{ zIndex: 999 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IpoEditForm data={editData} refetch={refetch} cancelCLick={cancelClick} />
      </Dialog>
      <Backdrop
        sx={(theme: any) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={isSubmitting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={openView}
        onClose={handleCloseView}
        fullWidth
        maxWidth={'sm'}
        sx={{ zIndex: 999 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ViewIpoRequest data={viewIpoData} approve={approvedClick} decline={declineClick} />
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={openApproveAll}
        onClose={handleCloseApproveAll}
        aria-labelledby="responsive-dialog-title"
      >
        <Backdrop
          sx={(theme: any) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
          open={isSubmitting}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle id="responsive-dialog-title">{'Bulk Approval of IPO?'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are approving IPO's sent on:
            {table.getSelectedRowModel().rows && (
              (() => {

                const rowSelection = table.getState().rowSelection;
                const totalRows = table.getPrePaginationRowModel().rows.length;

                const uniqueIds = table
                  .getSelectedRowModel()
                  .rows.filter((row) => Object.keys(rowSelection).length == totalRows ? row.original.final_approver_id == userData?.id && row.original.status === 0 : row.original.status === 0) // Filter rows with `status === 0`
                  .map((row) => row.original.approved_by) // Extract IDs
                  .filter((id, index, self) => self.indexOf(id) === index); // Remove duplicates


                return (
                  <Box sx={{ mt: 2 }}>
                    {uniqueIds.length > 0 ? (
                      <>
                        {uniqueIds.map((id) => {
                          // Find rows matching the uniqueId
                          const details = table.getSelectedRowModel().rows.filter(
                            (row) => row.original.approved_by === id
                          );

                          return (
                            <Box key={id}>
                              <Typography sx={{ fontWeight: 'bold' }}>{id}</Typography>
                              {details.map((row, index) => (
                                <Typography key={index} sx={{ marginLeft: 2 }}>IPO-{row.original.id.toString().padStart(5, '0')}</Typography>
                              ))}
                              <hr /> {/* Divider between unique IDs */}
                            </Box>
                          );
                        })}
                      </>
                    ) : (
                      <p>No unique IDs found.</p>
                    )}
                  </Box>
                );
              })()
            )}


          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseApproveAll}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              setIsSubmitting(true);

              const rowSelection = table.getState().rowSelection;
              const totalRows = table.getPrePaginationRowModel().rows.length;
              const uniqueIds = table
                .getSelectedRowModel()
                .rows.filter((row) => Object.keys(rowSelection).length == totalRows ? row.original.final_approver_id == userData?.id && row.original.status === 0 : row.original.status === 0) // Filter rows with `status === 0`
                .map((row) => row.original.approved_by) // Extract IDs
                .filter((id, index, self) => self.indexOf(id) === index);

              const testVariable = uniqueIds.flatMap((id) => {
                return table.getSelectedRowModel().rows
                  .filter((row) => row.original.approved_by === id)
                  .map((row) => ({
                    id: row.original.id,
                    user_id: row.original.user_id,
                  }));
              });


              if (testVariable.length > 0) {
                axiosClient
                  .post(`/ipo_transaction/bulk`, testVariable)
                  .then((response: any) => {
                    if (response.status == 201) {
                      refetch();
                      setIsSubmitting(false);
                      setOpenApproveAll(false);
                    } else {
                      message.error('Something went wrong', 3);
                    }
                  })
                  .catch((error) => {
                    setIsSubmitting(false);
                    message.error(error.response.data.message, 3);
                    console.log(error);
                  });
              } else {
                setIsSubmitting(false);

                message.error('The Selected IPO is not yours / is not pending');
              }
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <ReactToPrint
        trigger={() => (
          <button id="print-button" style={{ display: 'none' }}>
            Print
          </button>
        )}
        content={() => componentRef.current}
        pageStyle={`
                @media print {
                  body {
                    -webkit-print-color-adjust: exact;
                  }
                  .printTable th,
                  .printTable td {
                    padding: 2px;
                  }
                  header, footer {
                    display: none;
                  }
                  .pageBreak {
                    page-break-after: always;
                  }
                  .fontSize8pt {
                    font-size: 8pt !important;
                  }
                  tbody tr:not(:last-child) td {
                    border-bottom: 1px solid #acacac;
                  }
                  .printable-logo {
                    fill: #000000 !important; /* Ensure the logo prints in black */
                  }
                }
              `}
      />

      <Grid
        item
        xs={12}
        md={9}
        ref={componentRef}
        sx={{ /* paddingTop:'50px', */ display: 'none', displayPrint: 'block' }}
      >
        {printData.length > 0 &&
          printData
            .reduce((acc: any, _: any, index: any, array: any) => {
              if (index % 2 === 0) {
                acc.push(array.slice(index, index + 2));
              }
              return acc;
            }, [])
            .map((chunk: any, chunkIndex: any) => (
              <div className="pageBreak" key={chunkIndex}>
                <Grid
                  container
                  spacing={2}
                  key={chunkIndex}
                  sx={{
                    justifyContent: 'start',
                    margin: '0px',
                    marginTop: '150px',
                    transform: 'rotate(90deg)',
                    '& .MuiTypography-h4': {
                      fontSize: '0.73rem',
                      lineHeight: '1rem',
                    },
                    '& .MuiTypography-h6': {
                      fontSize: '0.68rem',
                    },
                    '& .MuiTypography-body2': {
                      fontSize: '0.63rem',
                    },
                    '& .MuiTypography-caption': {
                      fontSize: '0.58rem',
                    },
                  }}
                >
                  {chunk.map((data: any, index: any) => (
                    <>
                      <Grid item xs={5.8}>
                        <PrintIPOData data={data} />
                      </Grid>

                      {index !== chunk.length - 1 && (
                        <Grid item>
                          <Divider sx={{ borderColor: 'black' }} orientation="vertical" />
                        </Grid>
                      )}
                    </>
                    // <Grid item xs={6} key={index}>
                    //   <PrintIPOData data={data} />

                    //   <Divider sx={{ borderColor: 'black', }} orientation="vertical" />
                    // </Grid>
                  ))}
                </Grid>
              </div>
            ))}

        {/* <div className="pageBreak"></div>
        {printData.length > 0 &&
          printData.map((data, index) => {
            return (
              <PrintIPOData data={data} key={index} />
            )
          })} */}
      </Grid>

      <MaterialReactTable table={table} />
    </Box>
  );
};

interface IssuanceProps {
  data: any;
}

const PrintIPOData = ({ data }: any) => {
  const transactionRequests = data.issuance_requests;

  const issuanceRequests: any = [];

  transactionRequests.forEach((transactionReq: any) => {
    const setThisData: any = [];
    const customer = transactionReq.customer;

    const uniqueProductIdsInSerial = Object.values(
      transactionReq.transactions.serial_numbers.reduce((acc: any, product: any) => {
        acc[product.product_id] = product;
        return acc;
      }, {}),
    );
    uniqueProductIdsInSerial.forEach((transaction: any) => {
      // const stockLoc = transactionReq.transactions.stsock_location.filter(
      //   (opt: any) => opt.product_id == transaction.product_id,
      // );

      const serialNumbers = transactionReq.transactions.serial_numbers.filter(
        (opt: any) => opt.product_id === transaction.product_id,
      );

      let locationStocks = transactionReq.transactions.initial_location.filter(
        (filter: any) => filter.product_id == transaction.product_id,
      );

      if (transaction.status == 'posted') {
        locationStocks = transactionReq.transactions.initial_location
          .filter((filter: any) => filter.product_id === transaction.product_id)
          .map((item: any) => item)
          .flat();
      }

      const stockLoc = locationStocks ? locationStocks : [];

      let remainingQuantity = serialNumbers.length;
      let serialIndex = 0;
      const result = [];

      for (const item of stockLoc) {
        if (remainingQuantity <= 0) break;
        const serialsTaken = [];
        let takenQuantity;

        if (item.quantity >= remainingQuantity) {
          takenQuantity = remainingQuantity;
          serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenQuantity));
          result.push({
            ...item,
            takenQuantity,
            serial_numbers: serialsTaken,
          });
          setThisData.push({
            ...item,
            key: serialNumbers[serialIndex].id,
            product_id: transaction.product_id,
            product_name: transaction.product_name,
            product_model: transaction.product_model,
            price: transaction.price,
            notice: transaction.notice,
            product_unit: transaction.product_unit,
            takenQuantity,
            serial_numbers: serialsTaken,
          });
          remainingQuantity = 0;
        } else {
          takenQuantity = item.quantity;
          serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenQuantity));
          result.push({ ...item, takenQuantity, serial_numbers: serialsTaken });
          setThisData.push({
            ...item,
            key: serialNumbers[serialIndex].id,
            product_id: transaction.product_id,
            product_name: transaction.product_name,
            product_model: transaction.product_model,
            price: transaction.price,
            notice: transaction.notice,
            product_unit: transaction.product_unit,
            takenQuantity,
            serial_numbers: serialsTaken,
          });
          remainingQuantity -= takenQuantity;
        }
        serialIndex += takenQuantity;
      }
    });
    transactionReq.transactions.stocks.map((transaction: any, index: any) => {
      let locationStocks = transactionReq.transactions.initial_location.filter(
        (filter: any) => filter.product_id == transaction.product_id && filter.quantity != 0,
      );

      if (transaction.status == 'posted') {
        locationStocks = transactionReq.transactions.stock_location.filter(
          (filter: any) => filter.product_id == transaction.product_id && filter.quantity != 0,
        );
      }

      const stockLoc = locationStocks ? locationStocks : [];
      let remainingQuantity = transaction.quantity;
      const result = [];

      for (const item of stockLoc) {
        if (remainingQuantity <= 0) break;

        if (item.quantity >= remainingQuantity) {
          result.push({
            ...item,
            serial_numbers: [],
            product_id: transaction.product_id,
            product_name: transaction.product_name,
            product_model: transaction.product_model,
            price: item.takenUsed > 0 ? item.value : transaction.price,
            notice: transaction.notice,
            product_unit: transaction.product_unit,
            takenQuantity: remainingQuantity,
          });
          setThisData.push({
            ...item,
            serial_numbers: [],
            key: transaction.id,
            product_name: transaction.product_name,
            product_model: transaction.product_model,
            price: item.takenUsed > 0 ? item.value : transaction.price,
            notice: transaction.notice,
            product_unit: transaction.product_unit,
            takenQuantity: remainingQuantity,
          });
          remainingQuantity = 0;
        } else {
          result.push({
            ...item,
            product_id: transaction.product_id,
            product_name: transaction.product_name,
            product_model: transaction.product_model,
            price: item.takenUsed > 0 ? item.value : transaction.price,
            notice: transaction.notice,
            product_unit: transaction.product_unit,
            takenQuantity: item.quantity,
          });
          setThisData.push({
            ...item,
            serial_numbers: [],
            key: transaction.id,
            product_id: transaction.product_id,
            product_name: transaction.product_name,
            product_model: transaction.product_model,
            price: item.takenUsed > 0 ? item.value : transaction.price,
            notice: transaction.notice,
            product_unit: transaction.product_unit,
            takenQuantity: item.quantity,
          });
          remainingQuantity -= item.quantity;
        }
      }

      if (remainingQuantity > 0) {
        setThisData.push({
          ...transaction,
          serial_numbers: [],
          key: transaction.id,
          product_name: transaction.product_name,
          product_model: transaction.product_model,
          price: transaction.price,
          notice: transaction.notice,
          product_unit: transaction.product_unit,
          takenQuantity: remainingQuantity,
        });
      }
    });
    const invoiceData = {
      id: transactionReq?.id || '',
      invoice_number: transactionReq?.invoice_number || '',
      reference_number: transactionReq?.reference_number || '',
      date_of_issuance: transactionReq?.date_of_issuance || '',
      created_by_userid: transactionReq?.created_by.id || '',
      created_by_name: transactionReq?.created_by.name || '',
      slip_number: transactionReq.slip_number || '',
      customer_code: customer?.code || '',
      customer_name: customer?.name || '',
      customer_address: customer?.address || '',
      status: transactionReq.status || '',
      stock_location_record: transactionReq.stock_location_record || '',
      customer_contact_person: customer?.contact_person || '',
      transactions: setThisData.map((item: any) => ({
        ...item,
        notice: item.notice,
        keys: Array.from(new Set([item.key, ...item.serial_numbers.map((sn: any) => sn.id)])),
      })),
      subtotal: setThisData.reduce((sum: any, product: any) => {
        return (sum += product.takenQuantity * product.price);
      }, 0),
      newSubTotal:
        setThisData.reduce((sum: any, product: any) => {
          return (sum += product.takenQuantity * product.price);
        }, 0) - transactionReq.total_lost_amount,
      total_lost_amount: transactionReq.total_lost_amount,
      created_by: transactionReq.created_by,
      created_at: transactionReq.created_at,
      checked_at: transactionReq.checked_at,
      checked_by: transactionReq.checked_by,
      checked_note: transactionReq.checked_note,
      released_by: transactionReq.released_by,
      released_at: transactionReq.released_at,
      released_note: transactionReq.released_note,
      received_at: transactionReq.received_at,
    };

    issuanceRequests.push(invoiceData);
  });

  return (
    // <Grid container spacing={2} sx={{
    //   justifyContent: 'center',
    //   marginTop: '5px',
    //   // transform: 'rotate(90deg)',
    //   '& .MuiTypography-h4': {
    //     fontSize: '0.75rem',
    //     lineHeight: '1rem'
    //   },
    //   '& .MuiTypography-h6': {
    //     fontSize: '0.65rem',
    //     // lineHeight: '1rem'
    //   },
    //   '& .MuiTypography-body2': {
    //     fontSize: '0.50rem',
    //     // lineHeight: '1rem'
    //   },
    //   '& .MuiTypography-caption': {
    //     fontSize: '0.45rem',
    //     // lineHeight: '1rem'
    //   },

    // }}>
    //   <Grid item xs={6}>
    <Card
      elevation={6}
      sx={{
        padding: 0,
      }}
    >
      <>
        <CardHeader
          title={
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h4">RAEL KITZ CORPORATION</Typography>
              <Typography variant="body2">
                Centrum Fuel Station, Brgy. Talibaew, Calasiao, Pangasinan
              </Typography>
              <Typography variant="body2">{`Telefax: (075) 653-0008`}</Typography>
            </Box>
          }
          sx={{
            padding: '8px 12px',
          }}
        />
        <Divider />
      </>
      <CardContent
        sx={{
          padding: '8px 16px 16px 16px',
        }}
      >
        <Grid container columnSpacing={0.5}>
          <Grid item xs={12}>
            <Grid container sx={{ alignItems: 'center' }}>
              <Grid item xs={8}>
                <Typography variant="h6">INTERNAL PURCHASE ORDER FORM</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'right' }}>
                  IPO-{data.id.toString().padStart(5, '0')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={8}>
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="caption">TO:</Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="caption">{data.destination}</Typography>
                <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: 'right' }}>
            <Chip
              label={formatDate(data.created_at)}
              color={'info'}
              variant="outlined"
              size="small"
              sx={{
                ml: 1,
                fontSize: '0.65rem',
                height: '22px',
                minWidth: '50px',
              }}
            />
          </Grid>
          <Grid item xs={5.5}>
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="caption">Department:</Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="caption">{data.department}</Typography>
                <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6.5}>
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="caption">Purposes:</Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="caption">{data.purpose}</Typography>
                <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="caption">Project Site:</Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="caption">{data.project_site}</Typography>
                <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} mt={3}>
            <TableContainer component={Paper} elevation={12}>
              <Table
                size="small"
                aria-label="a dense table"
                sx={{
                  '& .MuiTableCell-body': {
                    fontSize: '0.5rem',
                  },
                  '& .MuiTableCell-root': {
                    padding: '3px 8px',
                  },
                  '& .MuiTableCell-head': {
                    fontSize: '0.6rem',
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>QTY</TableCell>
                    <TableCell align="right">Item Name</TableCell>
                    <TableCell align="right">Unit Price</TableCell>
                    <TableCell align="right">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.ipo_requests.map((row: any) => (
                    <TableRow
                      key={row.id}
                    // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell
                        sx={{
                          borderRight: '1px solid',
                        }}
                      >
                        {row.quantity}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          borderRight: '1px solid',
                        }}
                      >
                        {row.product_name}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          borderRight: '1px solid',
                        }}
                      ></TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  ))}

                  {Array.from({ length: Math.max(15 - data.ipo_requests.length, 0) }).map(
                    (_, index) => (
                      <TableRow key={`empty-${index}`}>
                        <TableCell
                          sx={{
                            borderRight: '1px solid',
                          }}
                        >
                          &nbsp;
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            borderRight: '1px solid',
                          }}
                        >
                          &nbsp;
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            borderRight: '1px solid',
                          }}
                        >
                          &nbsp;
                        </TableCell>
                        <TableCell align="right">&nbsp;</TableCell>
                      </TableRow>
                    ),
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} mt={8}>
            <Grid container spacing={{ sm: 1, md: 5 }}>
              <Grid item xs={6} sx={{ textAlign: 'center' }}>
                <Typography variant="h6">{data.requested_by}</Typography>
                <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
                <Typography variant="h6">Requested By</Typography>
              </Grid>

              {/* <Grid item xs={6} sx={{ textAlign: 'center', position: 'relative' }}>
                <Box sx={{ position: 'absolute', top: -20, width: '-webkit-fill-available' }}>
                  {data.approver_specimen && (
                    <Watermark
                      content={'E-SIGN'}
                      font={{
                        // fontSize: '5pt',
                        color: '#ff4d4fb8',
                      }}
                    >
                      <Image width={80} src={data.approver_specimen} />
                    </Watermark>
                  )}
                </Box>

                <Typography>{data.approved_by}</Typography>
                <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
                <Typography variant="h6">Approved By</Typography>
              </Grid> */}

              <Grid item xs={6} sx={{ textAlign: 'center', position: 'relative' }}>
                <Box sx={{ position: 'absolute', top: -60, width: '-webkit-fill-available', textAlign: '-webkit-center' }}>
                  {data.approver_specimen && (
                    <SVGWrapper
                      src={data.approver_specimen}
                      width={130}
                      srcText={data.specimen_text}
                      color="red"
                      text={data.approved_at}
                      finalApprover={data.final_approver_name}
                    />
                  )}
                </Box>

                <Typography>{data.approved_by}</Typography>
                <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
                <Typography variant="h6">Approved By</Typography>
              </Grid>

              {/* <Grid item xs={6} sx={{ textAlign: 'center' }}>
                <Typography variant='h6'>{data.approved_by}</Typography>
                <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
                <Typography variant="h6">Approved By</Typography>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    //   </Grid>
    // </Grid>
  );
};

const ViewIpoRequest = ({
  data,
  approve,
  decline,
}: {
  data: any;
  approve: (passedResult: any) => void;
  decline: (passedResult: any) => void;
}) => {
  const { userData } = useEssentialDataContext();

  const approvedClick = (approvedData: any) => {
    approve(approvedData);
  };
  const declineClick = (declinedData: any) => {
    decline(declinedData);
  };
  return (
    <Card elevation={6} sx={{ overflow: 'auto' }}>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <CardHeader
          title={
            <Box sx={{ textAlign: 'center' }}>
              <Typography sx={{ typography: { sm: 'h6', md: 'h3' } }}>
                RAEL KITZ CORPORATION
              </Typography>
              <Typography sx={{ typography: { sm: 'subtitle1', md: 'subtitle2' } }}>
                Centrum Fuel Statio, Brgy. Talibaew, Calasiao, Pangasinan
              </Typography>
              <Typography variant="subtitle2">{`Telefax: (075) 653-0008`}</Typography>
            </Box>
          }
        />
        <Divider />
      </Box>
      <CardContent sx={{ padding: { xs: '0', md: 3 } }}>
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <Grid container sx={{ alignItems: 'center' }}>
              <Grid item xs={12} md={8}>
                <Typography sx={{ typography: { sm: 'caption', md: 'h6' } }}>
                  INTERNAL PURCHASE ORDER FORM
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    textAlign: { sm: 'left', md: 'right' },
                    typography: { sm: 'caption', md: 'h6' },
                  }}
                >
                  IPO-{data.id.toString().padStart(5, '0')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={8}>
            <Grid container spacing={1}>
              <Grid item>
                <Typography>TO:</Typography>
              </Grid>
              <Grid item xs>
                <Typography>{data.destination}</Typography>
                <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} sx={{ textAlign: 'right' }}>
            <Chip
              label={formatDate(data.created_at)}
              color={'info'}
              variant="outlined"
              size="small"
              sx={{
                ml: 1,
                fontSize: '0.8rem',
                height: '22px',
                minWidth: '50px',
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item>
                <Typography>Department:</Typography>
              </Grid>
              <Grid item xs>
                <Typography>{data.department}</Typography>
                <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item>
                <Typography>Project Site:</Typography>
              </Grid>
              <Grid item xs>
                <Typography>{data.project_site}</Typography>
                <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item>
                <Typography>Purposes:</Typography>
              </Grid>
              <Grid item xs>
                <Typography>{data.purpose}</Typography>
                <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} mt={3}>
            <TableContainer component={Paper} elevation={12}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>QTY</TableCell>
                    <TableCell align="right">Item Name</TableCell>
                    {/* <TableCell align="right">Unit Price</TableCell>
                    <TableCell align="right">Amount</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.ipo_requests.map((row: any) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.quantity}
                      </TableCell>
                      <TableCell align="right">{row.product_name}</TableCell>
                      {/* <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} mt={8}>
            <Grid container spacing={{ sm: 1, md: 5 }}>
              <Grid item xs={6} sx={{ textAlign: 'center' }}>
                <Typography>{data.requested_by}</Typography>
                <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
                <Typography variant="h6">Requested By</Typography>
              </Grid>

              {/* <Grid item xs={6} sx={{ textAlign: 'center' }}>
                <Typography>{data.approved_by}</Typography>
                <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
                <Typography variant="h6">Approved By</Typography>
              </Grid> */}

              <Grid item xs={6} sx={{ textAlign: 'center', position: 'relative' }}>
                <Box sx={{ position: 'absolute', top: -70, width: '-webkit-fill-available' }}>
                  {data.approver_specimen && (
                    <SVGWrapper
                      src={data.approver_specimen}
                      width={200}
                      srcText={null}
                      color="red"
                      text={data.approved_at}
                      finalApprover={data.final_approver_name}
                    />
                  )}
                </Box>

                <Typography>{data.approved_by}</Typography>
                <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
                <Typography variant="h6">Approved By</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} mt={3}>
            <Grid
              container
              spacing={2}
              display={
                data.status == 0
                  ? userData?.userlevel == 'superadmin' || userData?.userlevel == 'approver'
                    ? 'flex'
                    : 'none'
                  : 'none'
              }
            >
              <Grid
                item
                xs={6} /* display={userData?.userlevel == 'superadmin' ? 'none' : 'flex'} */
              >
                <Button
                  onClick={() => approvedClick(data.id)}
                  size="small"
                  fullWidth
                  variant="contained"
                  color="success"
                >
                  APPROVE
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  onClick={() => declineClick(data.id)}
                  size="small"
                  fullWidth
                  variant="contained"
                  color="error"
                >
                  DECLINE
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};


const IpoIssuances: FC<IssuanceProps> = ({ data }) => {
  const { formatWithPrefix } = useFormattedNumber();

  const theme = useTheme();

  const validUserLevels = ['stockman', 'encoder', 'superadmin', 'customer'];

  const isValidUserLevel = (currentUserLevel: any) => validUserLevels.includes(currentUserLevel);

  const customizer = useSelector((state: AppState) => state.customizer);

  const { userData } = useEssentialDataContext();

  const lostQuantityArrays = data.transactions.filter((data: any) => data.lostQuantity > 0);
  const excessQuantityArrays = data.transactions.filter((data: any) => data.excessQuantity > 0);



  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        padding: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mb: 1,
        }}
      >
        <Box>
          <Box sx={{ width: '250px', marginLeft: '-36px', marginBottom: '5px' }}>
            {customizer.activeMode === 'dark' ? (
              <LogoLight height={'auto'} />
            ) : (
              <LogoDark height={'auto'} />
            )}

            {/* <RECEIPT
              style={{
                fill: theme.palette.mode === 'dark' ? '#ffffff' : '#000000', // Change fill color based on theme mode
              }}
              className="printable-logo"
            /> */}
          </Box>
          <Typography
            component="p"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            RAEL KITZ CORPORATION - WMS IS
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            RRF Building San Miguel Calasiao, Pangasinan
            <br />
            +1 (123) 456 7891, +44 (876) 543 2198
          </Typography>

          <Typography
            variant="body2"
            sx={{ mt: 1, fontWeight: 'bold', textTransform: 'uppercase' }}
            className="fontSize8pt"
          >
            {data.status === 'posted' || data.status === 'checked' ?
              <Chip icon={<Check />} size='small' label={data.status} color="success" />
              : data.status === 'floating' ?
                <Chip icon={<Waves />} size='small' label={'Floating'} color="warning" />
                : data.status === 'in_transit' ?
                  <Chip icon={<LocalShipping />} size='small' label={'In transit'} color="success" />
                  : data.status === 'finalized' ?
                    <Chip icon={<ThumbUpAlt />} size='small' label={'Finalized'} color="info" />
                    :
                    <Chip label={data.status} size='small' color="error" />

            }
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          <Typography
            variant="h6"
            component="p"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            INVOICE #{data.slip_number}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            Reference Number: {data.reference_number} <br />
            Issuance Date: {data.date_of_issuance}
            {/* Transaction ID: {data.id} */}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            Customer: {data.customer_name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            Customer Code: {data.customer_code}
            <br />
            Address: {data.customer_address}
            <br />
            Contact Person: {data.customer_contact_person}
            <br />
          </Typography>
        </Box>
      </Box>
      <Table className="printTable" sx={{ width: '100%', textAlign: 'left' }}>
        <TableHead sx={{ backgroundColor: 'grey.200' }}>
          <TableRow>
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              PRODUCT NAME
            </TableCell>
            {/* <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              MODEL
            </TableCell> */}
            {isValidUserLevel(userData?.userlevel) && (
              <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
                LOCATION
              </TableCell>
            )}
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              SERIAL NO.
            </TableCell>
            <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
              QTY
            </TableCell>
            {isValidUserLevel(userData?.userlevel) && (
              <>
                <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
                  COST
                </TableCell>
                <TableCell sx={{ py: 0.5, color: 'text.secondary' }} className="fontSize8pt">
                  AMOUNT
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.transactions.map((transaction: any, index: any) => (
            <TableRow key={index}>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.product_name}
              </TableCell>
              {/* <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.product_model}
              </TableCell> */}
              {isValidUserLevel(userData?.userlevel) && (
                <TableCell sx={{ py: 1 }} className="fontSize8pt">
                  {transaction.location?.name ? (
                    <>
                      {transaction.location.name}
                      {transaction.divider} Floor {transaction.floor}
                    </>
                  ) : (
                    'No stock location record'
                  )}
                </TableCell>
              )}
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.serial_numbers.length > 0 &&
                  transaction.serial_numbers.map((serial: any) => serial.serial_number).join(', ')}
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.takenQuantity} {transaction.product_unit}
              </TableCell>
              {isValidUserLevel(userData?.userlevel) && (
                <>
                  <TableCell sx={{ py: 1 }} className="fontSize8pt">
                    {formatWithPrefix(transaction.price)}
                  </TableCell>
                  <TableCell sx={{ py: 1 }} className="fontSize8pt">
                    {formatWithPrefix(transaction.takenQuantity * transaction.price)}
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
          {lostQuantityArrays.map((transaction: any, index: any) => (
            <TableRow
              key={index}
              sx={{ '.MuiTableCell-root': { color: 'red', fontWeight: 'bold' } }}
            >
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.product_name}
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.product_model}
              </TableCell>
              {/* <TableCell sx={{ py: 1 }} className="fontSize8pt">
                LOST IN TRANSIT
              </TableCell> */}
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.serial_numbers.length > 0 &&
                  transaction.serial_numbers.map((serial: any) => serial.serial_number).join(', ')}
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.lostQuantity} {transaction.product_unit} LOST
              </TableCell>
              {/* <TableCell sx={{ py: 1 }} className="fontSize8pt">
               {transaction.product_unit}
             </TableCell> */}
              {isValidUserLevel(userData?.userlevel) && (
                <>
                  <TableCell sx={{ py: 1 }} className="fontSize8pt">
                    -{formatWithPrefix(transaction.price)}
                  </TableCell>
                  <TableCell sx={{ py: 1 }} className="fontSize8pt">
                    -{formatWithPrefix(transaction.lostQuantity * transaction.price)}
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}

          {excessQuantityArrays.map((transaction: any, index: any) => (
            <TableRow
              key={index}
              sx={{ '.MuiTableCell-root': { color: 'green', fontWeight: 'bold' } }}
            >
              {/* <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.product_sku}
              </TableCell> */}
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.product_name}
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.product_model}
              </TableCell>
              {/* {isValidUserLevel(userData?.userlevel) && (
                <TableCell sx={{ py: 1 }} className="fontSize8pt">
                  Excess
                </TableCell>
              )} */}

              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.serial_numbers.length > 0 &&
                  transaction.excess_serial_numbers
                    .map((serial: any) => serial.serial_number)
                    .join(', ')}
              </TableCell>
              <TableCell sx={{ py: 1 }} className="fontSize8pt">
                {transaction.excessQuantity} {transaction.product_unit} EXCESS
              </TableCell>
              {/* <TableCell sx={{ py: 1 }} className="fontSize8pt">
                         {transaction.product_unit}
                       </TableCell> */}
              {isValidUserLevel(userData?.userlevel) && (
                <>
                  <TableCell sx={{ py: 1 }} className="fontSize8pt">
                    -{formatWithPrefix(transaction.price)}
                  </TableCell>
                  <TableCell sx={{ py: 1 }} className="fontSize8pt">
                    -{formatWithPrefix(transaction.excessQuantity * transaction.price)}
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
          <TableRow>
            <TableCell sx={{ py: 1 }}>
              <Typography sx={{ fontWeight: 'bold' }} className="fontSize8pt">
                Total: {data.transactions.length}
              </Typography>
            </TableCell>
            <TableCell sx={{ py: 1, whiteSpace: 'pre-line' }}></TableCell>
            {isValidUserLevel(userData?.userlevel) && <TableCell sx={{ py: 1 }}></TableCell>}
            {/* <TableCell sx={{ py: 1 }}></TableCell> */}
            <TableCell sx={{ py: 1 }}></TableCell>
            {isValidUserLevel(userData?.userlevel) && (
              <>
                <TableCell sx={{ py: 1 }}></TableCell>
                <TableCell sx={{ py: 1, fontWeight: 'bold' }} className="fontSize8pt">
                  {formatWithPrefix(data.newSubTotal)}
                </TableCell>
              </>
            )}
          </TableRow>
        </TableBody>
      </Table>

      <Divider sx={{ borderColor: '#acacac' }} />
      <Grid
        container
        columnSpacing={5}
        sx={{ fontSize: { md: '8pt', lg: '10pt' }, display: 'none', displayPrint: 'flex' }}
      >
        {/* <Grid item xs={2.5} className="fontSize8pt">
          Requested By:
          <Divider sx={{ borderColor: 'black', mt: { xs: 5 } }} />
        </Grid> */}
        <Grid item xs={5} className="fontSize8pt">
          Prepared By:
          <Divider sx={{ borderColor: 'black', mt: { xs: 5 } }} />
        </Grid>
        <Grid item xs={7}>
          <Grid container columnSpacing={1}>
            <Grid item className="fontSize8pt">
              Checked By:
            </Grid>
            <Grid item flexGrow={1}>
              <Grid container columnSpacing={2}>
                <Grid item className="fontSize8pt">
                  Checker
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <Divider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
              <Grid container columnSpacing={2} mt={1}>
                <Grid item className="fontSize8pt">
                  Hauler Representative
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <Divider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
              <Grid container columnSpacing={2} mt={1}>
                <Grid item className="fontSize8pt">
                  Customer Authorized Representative
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <Divider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} mt={1}>
          <Grid container columnSpacing={1} justifyContent={'between'}>
            <Grid item xs={8}>
              <Typography sx={{ fontWeight: 'bold' }} className="fontSize8pt">
                Received the above goods in full and good condition.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid item flexGrow={1}>
                <Grid container columnSpacing={2}>
                  <Grid item className="fontSize8pt">
                    Received By:
                  </Grid>
                  <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                    <Divider sx={{ borderColor: 'black' }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center' }} mt={1}>
        <Divider sx={{ borderColor: 'black' }} />
      </Grid>

      <Grid item xs={12} sx={{ textAlign: 'center' }} mt={1}>
        <Grid container columnSpacing={1}>
          <Grid item>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
              WAREHOUSE MANAGEMENT SYSTEM - by: INFORMATION SYSTEMS{' '}
              {formatDateWithTime(data.created_at)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
              User: {data.created_by.name}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default IpoTransactions;
