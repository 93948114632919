import React, {
  FC,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Divider,
  Table,
  Space,
  Button,
  AutoComplete,
  Tag,
  message,
  ConfigProvider,
  DatePicker,
  Flex,
  Modal,
  Upload,
  Image,
  InputNumber,
  Card,
} from 'antd';
import type { GetProp, TableProps } from 'antd';
import axiosClient from 'src/axios_client';
import CustomMultiInput, { TagItem } from './CustomMultiInput';
import locale from 'antd/locale/en_US';
import dayjs from 'dayjs';
import 'dayjs/locale/tl-ph';
import { Add, DeleteOutline, EditOutlined, PlusOneOutlined } from '@mui/icons-material';
import {
  Box,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Table as TableMUI,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputAdornment,
  useTheme,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Backdrop,
  CircularProgress,
  FormControlLabel,
} from '@mui/material';

import { Select as AntDSelect } from 'antd';
import useFormattedNumber from 'src/hooks/useFormattedNumber';
import _ from 'lodash';
import { UploadFile, UploadProps } from 'antd/lib';
import type { RcFile } from 'antd/es/upload';
import { useSelector, useDispatch } from 'src/store/Store';
import { AppState } from 'src/store/Store';
import { theme } from 'antd/lib';
import { ReactComponent as RECEIPT } from 'src/assets/images/svgs/rkc_wms_dark_receiptS.svg';
import { ReactComponent as LogoDark } from 'src/assets/images/logos/dark-logo.svg';
import { ReactComponent as LogoLight } from 'src/assets/images/logos/light-logo.svg';
import { formatCurrency } from 'src/globals/NumericFormat';
import imageCompression from 'browser-image-compression';
import CustomCheckbox from 'src/components/theme-elements/CustomCheckbox';

interface CustomFormLabelProps {
  children: React.ReactNode;
  htmlFor?: string;
}

interface DataType {
  key: string;
  id: string;
  name: string;
  location: any;
  description: string;
  unit: string;
  quantity: number;
  unit_price: number;
  amount: number;
  product_image?: any;
  stock_warranty: any;
  warranty_period: any;
  product_location: any;
  product_text: any;
  divider: any;
  floor: any;
  serial_numbers: TagItem[];
}

interface Supplier {
  id: number;
  code: string;
  name: string;
  address: string;
}

interface SupplierOption {
  value: string;
  label: string;
  supplier: Supplier;
}

const initialData: DataType[] = [];

const CustomFormLabel: FC<CustomFormLabelProps> = ({ children, htmlFor }) => (
  <label htmlFor={htmlFor} style={{ display: 'block', fontSize: '13px', color: '#414042' }}>
    {children}
  </label>
);

interface PurchaseEntryFormHandles {
  postTransaction: () => void;
  clearTransaction: () => void;
}

type InvoiceData = {
  id: string;
  invoice_number: string;
  po_number: number;
  reference_number: string;
  purchase_date: string;
  supplier_code: string;
  supplier_name: string;
  supplier_address: string;
  supplier_contact_person: string;
  transactions: {
    product_name: string;
    description: string;
    unit: string;
    product_location: any;
    product_text: any;
    divider: any;
    floor: any;
    cost: number;
    serial_number: any;
    quantity: number;
    amount: number;
  }[];
  processor: string;
  subtotal: number;
};

interface GridItemProps {
  data: InvoiceData;
}

const { confirm } = Modal;

const GridItem: FC<GridItemProps> = ({ data }) => {
  const { formatWithPrefix } = useFormattedNumber();
  console.log(data);

  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mb: 4,
        }}
      >
        <Box>
          <Box sx={{ width: '250px', marginLeft: '-34px', marginBottom: '5px' }}>
            <>
              {theme.palette.mode === 'dark' ? (
                <LogoLight height={'auto'} />
              ) : (
                <LogoDark height={'auto'} />
              )}
            </>
          </Box>
          <Typography variant="h6" component="p" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
            RAEL KITZ CORPORATION - WMS IS
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            RRF Building San Miguel Calasiao, Pangasinan
            <br />
            +1 (123) 456 7891, +44 (876) 543 2198
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          <Typography variant="h6" component="p" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
            INVOICE #{data.invoice_number}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Reference Number: {data.reference_number} <br />
            Purchase Date: {dayjs(data.purchase_date).format('MMMM DD, YYYY')}
          </Typography>
          <Typography variant="body1" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
            Supplier: {data.supplier_name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Supplier Code: {data.supplier_code}
            <br />
            Address: {data.supplier_address}
            <br />
            Contact Person: {data.supplier_contact_person}
            <br />
          </Typography>
        </Box>
      </Box>
      <TableMUI className="printTable" sx={{ width: '100%', textAlign: 'left', mb: 4 }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>PRODUCT NAME</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>DESCRIPTION</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>LOCATION</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>UNIT</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>COST</TableCell>
            {/* <TableCell sx={{ py: 1, color: 'text.secondary' }}>QTY</TableCell> */}
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>AMOUNT</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.transactions.map((transaction, index) => (
            <TableRow key={index}>
              <TableCell sx={{ py: 1 }}>{transaction.product_name}</TableCell>
              <TableCell sx={{ py: 1 }}>
                {transaction.description}
                {transaction.serial_number.length > 0 && (
                  <>
                    <br />
                    SN:
                    {transaction.serial_number.map((serial: any) => {
                      return serial.value + ', ';
                    })}
                  </>
                )}
              </TableCell>
              <TableCell sx={{ py: 1 }}>
                {transaction.product_text}
                {transaction.divider}, Floor {transaction.floor}
              </TableCell>
              <TableCell sx={{ py: 1 }}>
                {transaction.quantity} {transaction.unit}
              </TableCell>
              <TableCell sx={{ py: 1 }}>{formatWithPrefix(transaction.cost)}</TableCell>
              {/* <TableCell sx={{ py: 1 }}>{transaction.quantity}</TableCell> */}
              <TableCell sx={{ py: 1 }}>{formatWithPrefix(transaction.amount)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableMUI>

      <Typography
        variant="body1"
        sx={{ textAlign: 'right', color: 'text.primary', fontWeight: 'bold' }}
      >
        Subtotal: {formatWithPrefix(data.subtotal)}
      </Typography>
    </Box>
  );
};

interface PurchaseEntryFormProps {
  selectedTransaction?: any;
  onRefreshTransactions: () => void;
  userlevel: any;
}

let isConfirmOpen = false;

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const PurchaseEntryForm = forwardRef<PurchaseEntryFormHandles, PurchaseEntryFormProps>(
  ({ selectedTransaction, onRefreshTransactions, userlevel }, ref) => {
    useImperativeHandle(ref, () => ({
      postTransaction,
      clearTransaction,
    }));

    const [form] = Form.useForm();

    const [data, setData] = useState<DataType[]>(initialData);
    const [productOptions, setProductOptions] = useState<
      { value: string; id: string; product: any }[]
    >([]);
    const [locationOptions, setLocationOptions] = useState<
      { value: string; id: string; location: any }[]
    >([]);
    const [productId, setProductId] = useState<string>('');
    const [serialNumberTextDisabled, setSerialNumberTextDisabled] = useState(false);
    const [maximumTags, setMaximumTags] = useState(5);
    const [editKey, setEditKey] = useState<string | null>(null);
    const selectedProductRef = useRef<any>(null);
    const [selectedTransactions, setSelectedTransaction] = useState(null);
    const [supplierId, setSupplierId] = useState<any>(0);
    const [supplierDisplay, setSupplierDisplay] = useState<string>('');

    useEffect(() => {
      if (selectedTransaction) {
        setSelectedTransaction(selectedTransaction);
        populateFormWithSelectedTransaction(selectedTransaction);
      }
    }, [selectedTransaction]);

    const populateFormWithSelectedTransaction = async (
      transaction: NonNullable<PurchaseEntryFormProps['selectedTransaction']>,
    ) => {
      console.log('Populating form with transaction:', transaction);

      // Reset form and states
      // form.resetFields();

      const suppliers = [
        {
          value: transaction.supplier_id,
          label: `SUP-${transaction.supplier.id.toString().padStart(4, '0')} - ${transaction.supplier.name
            }`,
          supplier: transaction.supplier,
        },
      ];
      setSupplierOptions(suppliers);

      setData([]);
      setEditKey(null);

      // Set supplier information
      setSupplierId(transaction.supplier.id);
      setSupplierDisplay(
        `SUP-${transaction.supplier.id.toString().padStart(4, '0')} - ${transaction.supplier.name}`,
      );

      // Populate form fields
      form.setFieldsValue({
        reference_number: transaction.plain_reference_number,
        invoice_number: transaction.invoice_number,
        po_number: transaction.plain_po_number,
        purchase_date: dayjs(transaction.date_of_purchase),
        supplier_code: `SUP-${transaction.supplier.id.toString().padStart(4, '0')} - ${transaction.supplier.name
          }`,
        supplier_name: transaction.supplier.name,
        supplier_address: transaction.supplier.address,
      });

      // Prepare data for the table
      const tableData: DataType[] = [];

      // Map for storing product rows by SKU and unique attributes (location, floor, divider)

      console.log(transaction.purchases_array);
      const arrayOfPurchases = transaction.purchases_array.map((purchases: any, index: any) => {
        const serial_number_array = purchases.serial_numbers ? JSON.parse(purchases.serial_numbers) : [];

        return {
          key: index + 1,
          id: purchases.product_id,
          name: purchases.product_name,
          description: purchases.product_description,
          unit: purchases.product_unit,
          product_image: purchases.product_image,
          quantity: purchases.quantity,
          unit_price: purchases.purchase_price,
          product_location: purchases.location_id,
          product_text: purchases.location.name,
          divider: purchases.divider,
          floor: purchases.floor,
          location: purchases.location,
          stock_warranty: purchases.warranty_number,
          warranty_period: purchases.warranty_type,
          amount: purchases.quantity * purchases.purchase_price,
          serial_numbers: serial_number_array.map((serial: any) => {
            return {
              value: serial,
              exist: false,
            };
          }),
        };
      },
      );

      console.log(arrayOfPurchases);


      const productMap = new Map();

      // Process serialized products with serial numbers
      if (transaction.transactions?.serial_numbers) {
        transaction.transactions.serial_numbers.forEach((serial: any) => {
          const key = `${serial.product_sku}-${serial.product_location || ''}-${serial.floor || ''
            }-${serial.divider || ''}`;

          if (productMap.has(key)) {
            // If a product with the same SKU and attributes already exists, add the serial number
            productMap.get(key).serial_numbers.push({ value: serial.serial_number });
            productMap.get(key).quantity += 1; // Increment quantity by 1 for each serialized item
          } else {
            // Create a new row entry for a serialized product
            productMap.set(key, {
              key: `${serial.id}-${Date.now()}`,
              id: serial.id,
              name: serial.product_name,
              description: serial.product_description,
              unit: 'PCS',
              quantity: 1, // Each serialized item is treated as a single unit initially
              unit_price: serial.price || 0,
              amount: serial.price || 0,
              product_location: serial.product_location || '',
              product_text: serial.product_text || '',
              divider: serial.divider || 'A',
              floor: serial.floor || '1',
              serial_numbers: [{ value: serial.serial_number }],
              stock_warranty: serial.stock_warranty || 0,
              warranty_period: serial.warranty_period || 'month',
            });
          }
        });
      }

      // Process non-serialized stock items
      if (transaction.transactions?.stocks) {
        transaction.transactions.stocks.forEach((stock: any) => {
          const key = `${stock.product_sku}-${stock.product_location || ''}-${stock.floor || ''}-${stock.divider || ''
            }`;

          if (productMap.has(key)) {
            // If a product with the same SKU and attributes already exists, add to quantity
            const existingProduct = productMap.get(key);
            existingProduct.quantity += stock.quantity;
            existingProduct.amount += stock.quantity * (stock.price || 0);
          } else {
            // Add a new entry for non-serialized items
            productMap.set(key, {
              key: `${stock.id}-${Date.now()}`,
              id: stock.product_id,
              name: stock.product_name,
              description: stock.product_description,
              unit: stock.unit || 'PCS',
              quantity: stock.quantity,
              unit_price: stock.price || 0,
              amount: stock.quantity * (stock.price || 0),
              product_location: stock.product_location || '',
              product_text: stock.product_text || '',
              divider: stock.divider || 'A',
              floor: stock.floor || '1',
              serial_numbers: [], // Non-serialized items have no serial numbers
              stock_warranty: stock.stock_warranty || 0,
              warranty_period: stock.warranty_period || 'month',
            });
          }
        });
      }


      setData(arrayOfPurchases);

      setSelectedImage('');
      setFileList([]);
      setTransactionImage([]);

      console.log(transaction);

      if (transaction.transaction_receipt) {
        setTransactionImage([
          {
            uid: '-1',
            name: 'transaction-receipt',
            status: 'done',
            url: transaction.transaction_receipt,
          },
        ]);
      }
    };

    useEffect(() => {
      if (productId) {
        const selectedProduct = productOptions.find((option) => option.id === productId);
        if (selectedProduct) {
          selectedProductRef.current = selectedProduct.product;
          form.setFieldsValue({
            product_name: selectedProduct.product.name,
            product_description: selectedProduct.product.description,
            product_unit: selectedProduct.product.unit,
            product_unit_price: selectedProduct.product.selling_price,
          });
          setProductDisplay(selectedProduct.product.name);
          setSerialNumberTextDisabled(selectedProduct.product.has_serial === 0);
        }
      }
      if (form) {
        form.setFieldsValue({
          divider: 'A',
          floor: '1',
          purchase_date: dayjs(),
        });
      }
    }, [productId, form, productOptions]);

    const fetchProductOptions = async (searchText: string) => {
      try {
        const response = await axiosClient.get('/products', {
          params: { globalFilter: searchText, per_page: 10 },
        });
        const products = response.data.data.map((product: any) => ({
          value: product.name,
          id: product.id,
          product,
        }));

        console.log('Products: ', products);

        setProductOptions(products);
      } catch (error) {
        console.error('Failed to fetch product options:', error);
      }
    };

    const debouncedFetchProductOptions = useCallback(
      _.debounce((searchText: string) => {
        fetchProductOptions(searchText);
      }, 300), // Adjust the delay (in milliseconds) as needed
      [],
    );
    const debounceTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

    const onProductCodeChange = (searchText: string) => {
      setProductDisplay(searchText);
      debouncedFetchProductOptions(searchText);
    };
    const onLocationsChange = (searchText: any) => {
      // setIsSearching(true);
      // setIsEmptySearch(false);
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        const value = searchText;
        fetchOptions(value, 'locations');
      }, 500);
    };
    const fetchOptions = async (searchText: string, apiText: string) => {
      try {
        const { status, data } = await axiosClient.get(`/${apiText}`, {
          params: { globalFilter: searchText, per_page: 10 },
        });
        if (status == 200) {
          // setIsSearching(false);
          if (data.data.length == 0) {
            // setIsEmptySearch(true);
          }
          const fetchedData = data.data.map((innerData: any) => ({
            id: innerData.id,
            name: apiText == 'suppliers' ? `${innerData.code} - ${innerData.name}` : innerData.name,
            location: innerData,
          }));
          if (apiText == 'locations') {
            setLocationOptions(fetchedData);
          }
        }
      } catch (error) {
        console.error('Failed to fetch product options:', error);
      }
    };
    const getExistingSerialNumbers = (currentKey: string | null) => {
      return data
        .filter((item) => item.key !== currentKey)
        .flatMap((item) => item.serial_numbers.map((serial) => serial.value));
    };

    const showConfirmation = (
      action: () => void,
      title: string,
      content: ReactNode,
      width: number,
    ) => {
      confirm({
        title,
        icon: null,
        content,
        onOk() {
          action();
          isConfirmOpen = false;
        },
        onCancel() {
          isConfirmOpen = false;
        },
        width,
      });
    };

    const addOrUpdateProduct = () => {
      form
        .validateFields()
        .then((values) => {
          console.log(values);
          console.log(data);

          const quantity = Number(values.product_quantity);
          const unit_price = Number(values.product_unit_price);
          const serialNumbers = multiInputRef.current?.getTags() || [];
          if (quantity <= 0) {
            message.error(`Insert valid quantity`);
            return;
          }

          console.log(serialNumbers);

          if (selectedProductRef.current && selectedProductRef.current.has_serial === 1) {
            if (serialNumbers.length !== quantity) {
              message.error(
                `The number of serial numbers (${serialNumbers.length}) does not match the quantity (${quantity}).`,
              );
              return;
            }
          }
          const existingSerialNumbers = getExistingSerialNumbers(editKey);

          const duplicateSerials = serialNumbers.filter((serial) =>
            existingSerialNumbers.includes(serial.value),
          );
          if (duplicateSerials.length > 0) {
            message.error(
              `Serial number(s) ${duplicateSerials.map((s) => s.value).join(', ')} already exist.`,
            );
            return;
          }

          const addOrUpdate = () => {
            if (editKey) {
              const existingProductIndex = data.findIndex((item) => item.key === editKey);
              if (existingProductIndex !== -1) {
                const existingProduct = data[existingProductIndex];
                const updatedProduct: DataType = {
                  ...existingProduct,
                  quantity,
                  unit_price,
                  product_location: locationDisplay,
                  product_text: locationText,
                  divider: values.divider,
                  floor: values.floor,
                  product_image: data[existingProductIndex].product_image,
                  amount: quantity * unit_price,
                  serial_numbers: serialNumbers,
                  stock_warranty: values.stock_warranty,
                  warranty_period: timeSpan,
                };

                const updatedData = [...data];
                updatedData[existingProductIndex] = updatedProduct;
                setSelectedImage(data[existingProductIndex].product_image);
                setData(updatedData);
              }
            } else {
              const existingProductIndex = data.findIndex(
                (item) =>
                  item.id === selectedProductRef.current.id &&
                  item.floor == values.floor &&
                  item.product_location == values.product_location &&
                  item.divider == values.divider,
              );

              const selectedLocation = locationOptions.find(
                (opt) => opt.location.id === locationDisplay,
              )?.location;

              if (existingProductIndex !== -1) {
                const existingProduct = data[existingProductIndex];
                const updatedProduct: DataType = {
                  ...existingProduct,
                  quantity: existingProduct.quantity + quantity,
                  unit_price,
                  product_location: locationDisplay,
                  product_text: locationText,
                  divider: values.divider,
                  floor: values.floor,
                  stock_warranty: values.stock_warranty,
                  warranty_period: timeSpan,
                  amount: (existingProduct.quantity + quantity) * unit_price,
                  serial_numbers: [...existingProduct.serial_numbers, ...serialNumbers],
                };

                const updatedData = [...data];
                updatedData[existingProductIndex] = updatedProduct;
                setData(updatedData);
              } else {
                console.log(values);
                const newProduct: DataType = {
                  key: `${data.length + 1}`,
                  id: selectedProductRef.current.id,
                  name: selectedProductRef.current.name,
                  description: selectedProductRef.current.description,
                  unit: selectedProductRef.current.unit,
                  product_image: selectedImage,
                  quantity,
                  unit_price,
                  location: selectedLocation,
                  product_location: locationDisplay,
                  product_text: locationText,
                  divider: values.divider,
                  floor: values.floor,
                  stock_warranty: values.stock_warranty,
                  warranty_period: timeSpan,
                  amount: quantity * unit_price,
                  serial_numbers: serialNumbers,
                };
                setData([...data, newProduct]);
              }
            }
            setProductDisplay('');
            form.resetFields([
              'product_name',
              'product_unit',
              'product_quantity',
              'product_unit_price',
              'product_amount',
              'product_description',
              'product_location',
              'serial_number',
              'stock_warranty',
            ]);

            multiInputRef.current?.clearTags();
            setEditKey(null);
            setSelectedImage('');
            setProductId('');
            selectedProductRef.current = null;
          };

          showConfirmation(
            addOrUpdate,
            editKey ? 'Update Product' : 'Add Product',
            `Are you sure you want to ${editKey ? 'update' : 'add'} this product?`,
            400,
          );
        })
        .catch((errorInfo) => {
          // Show each form validation error as a separate message
          errorInfo.errorFields.forEach((err: any) => {
            err.errors.forEach((error: any) => {
              if (error && error.trim() !== '') {
                // Check if the error message is not empty or null
                message.error(error);
                console.log('Error: ', error);
              }
            });
          });
        });
    };

    const editProduct = (key: string) => {
      const product = data.find((item) => item.key === key);
      if (product) {
        console.log(product);
        setProductOptions([]);
        setLocationOptions([]);
        setLocationText('');

        const prodList = [{
          value: product.name,
          id: product.id,
          product
        }];

        // const fetchedData = data.data.map((innerData: any) => ({
        //   id: innerData.id,
        //   name: apiText == 'suppliers' ? `${innerData.code} - ${innerData.name}` : innerData.name,
        //   location: innerData,
        // }));

        const locList = [{
          id: product.location.id,
          value: product.location.name,
          name: product.location.name,
          location: product.location,
        }];


        setLocationDisplay(product.location.id);
        setLocationText(product.location.name);
        setLocationOptions(locList);


        setProductDisplay(product.name);
        setProductOptions(prodList);

        // response.data.data.map((product: any) => ({
        //   value: product.name,
        //   id: product.id,
        //   product,
        // }));


        setSerialNumberTextDisabled(product.serial_numbers.length > 0 ? false : true);
        setHasSerial(product.serial_numbers.length > 0 ? true : false);
        form.setFieldsValue({
          product_name: product.name,
          product_description: product.description,
          product_unit: product.unit,
          product_quantity: product.quantity,
          product_unit_price: product.unit_price,
          product_amount: product.amount,
        });

        const tags: TagItem[] = product.serial_numbers.map((serial) => ({
          value: serial.value,
          exists: serial.exists,
        }));
        multiInputRef.current?.setTags(tags);

        setEditKey(key);
        setMaximumTags(product.quantity);
      }
    };

    const deleteProduct = (key: string) => {
      setData(data.filter((item) => item.key !== key));
    };

    const onValuesChange = (changedValues: any, allValues: any) => {
      const { product_quantity, product_unit_price } = allValues;
      if (product_quantity !== undefined && product_unit_price !== undefined) {
        const quantity = Number(product_quantity);
        const unit_price = Number(product_unit_price);
        const amount = quantity * unit_price;
        form.setFieldsValue({ product_amount: amount });
        setMaximumTags(quantity);
      }
    };

    const calculateTotals = () => {
      const totalAmount = data.reduce((sum, item) => sum + item.amount, 0);

      const formattedTotalAmount = totalAmount.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      return { totalAmount: formattedTotalAmount };
    };

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isCheckedFinalize, setIsCheckedFinalize] = useState(false);

    const postTransaction = async () => {
      if (isSubmitting) {
        message.error('Please wait... The transaction is already submitted');
        return;
      }
      console.log(selectedImage);
      console.log(fileList);
      try {
        form
          .validateFields([
            'invoice_number',
            'po_number',
            'purchase_date',
            'supplier_code',
            'supplier_name',
            'supplier_address',
            'transaction_image',
          ])
          .then(async (values: any) => {
            if (data.length === 0) {
              message.error('The Temporary Table must have data before posting.');
              setIsSubmitting(false);
              return;
            }
            console.log(values);
            console.log(data);

            const formData = new FormData();

            formData.append('invoice_number', values.invoice_number);
            formData.append('po_number', values.po_number);
            formData.append('date_of_purchase', values.purchase_date.format('YYYY-MM-DD'));
            formData.append('supplier_id', supplierId);
            formData.append('isFinalized', isCheckedFinalize.toString());
            formData.append('supplier_name', values.supplier_name);
            formData.append('supplier_address', values.supplier_address);

            if (values.transaction_image && values.transaction_image.length > 0) {
              const file = values.transaction_image[0].originFileObj;
              if (file.type.startsWith('image/')) {
                try {
                  const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true,
                  };
                  const compressedFile = await imageCompression(file, options);
                  formData.append('transaction_receipt', compressedFile, file.name);
                } catch (error) {
                  console.error('Error compressing image:', error);
                  message.error('Error compressing image. Using original file.');
                  formData.append('transaction_receipt', file);
                }
              } else {
                formData.append('transaction_receipt', file);
              }
            }

            if (selectedTransaction) {
              formData.append('_method', 'PUT');
            }
            data.forEach((item: any, index) => {
              formData.append(`products[${index}][id]`, item.id);
              formData.append(`products[${index}][name]`, item.name);
              formData.append(`products[${index}][quantity]`, item.quantity);
              formData.append(`products[${index}][unit_price]`, item.unit_price);
              formData.append(`products[${index}][amount]`, item.amount);
              formData.append(`products[${index}][product_location]`, item.product_location);
              formData.append(`products[${index}][divider]`, item.divider);
              formData.append(`products[${index}][floor]`, item.floor);
              formData.append(`products[${index}][location][id]`, item.location.id);
              formData.append(`products[${index}][location][name]`, item.location.name);
              formData.append(`products[${index}][location][description]`, item.location.description);

              formData.append(`products[${index}][stock_warranty]`, item.stock_warranty ?? '');
              formData.append(`products[${index}][warranty_period]`, item.warranty_period ?? '');
              item.serial_numbers.forEach((serial: any, serialIndex: any) => {
                formData.append(`products[${index}][serial_numbers][${serialIndex}]`, serial.value);
              });
            });

            formData.append(
              'total_amount',
              data.reduce((sum, item) => sum + item.amount, 0).toString(),
            );

            const invoiceData: InvoiceData = {
              id: '',
              reference_number: values.reference_number,
              invoice_number: values.invoice_number,
              po_number: values.po_number,
              purchase_date: values.purchase_date.format('YYYY-MM-DD'),
              supplier_code: values.supplier_code,
              supplier_name: values.supplier_name,
              supplier_address: values.supplier_address,
              supplier_contact_person: '',
              transactions: data.map((item) => {
                return {
                  product_name: item.name,
                  description: item.description,
                  stock_warranty: item.stock_warranty,
                  warranty_period: item.warranty_period,
                  product_location: item.product_location,
                  product_text: item.product_text,
                  serial_number: item.serial_numbers,
                  divider: item.divider,
                  floor: item.floor,
                  unit: item.unit,
                  cost: item.unit_price,
                  quantity: item.quantity,
                  amount: item.amount,
                };
              }),
              processor: '',
              subtotal: data.reduce((sum, item) => sum + item.amount, 0),
            };

            const post = () => {
              setIsSubmitting(true);
              axiosClient
                .post('/pe_transactions', formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                })
                .then((response) => {
                  console.log(response);
                  message.success('Transaction posted successfully!');

                  form.resetFields();
                  setData(initialData);
                  setSupplierId(0);
                  setFileList([]);
                  setTransactionImage([]);
                  setSupplierDisplay('');
                  setEditKey(null);
                  setSelectedTransaction(null);
                  onRefreshTransactions();
                  selectedProductRef.current = null;
                })
                .catch((error) => {
                  message.error(error.response.data.message, 3);
                })
                .finally(() => {
                  setIsSubmitting(false);
                });
            };

            const update = () => {
              setIsSubmitting(true);
              axiosClient
                .post(`/pe_transactions/${selectedTransaction.id}`, formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                })
                .then((response) => {
                  console.log(response);
                  message.success('Transaction posted successfully!');

                  form.resetFields();
                  setData(initialData);
                  setSupplierId(0);
                  setFileList([]);
                  setTransactionImage([]);
                  setSupplierDisplay('');
                  setEditKey(null);
                  onRefreshTransactions();

                  setSelectedTransaction(null);
                  selectedProductRef.current = null;
                })
                .catch((error) => {
                  message.error(error.response.data.message, 3);
                })
                .finally(() => {
                  setIsSubmitting(false);
                });
            };

            showConfirmation(
              selectedTransaction ? update : post,
              selectedTransaction ? 'Update Transaciton' : 'Post Transaction',
              <>
                <GridItem data={invoiceData} />
              </>,
              1200,
            );
          })
          .catch((error) => {
            console.error('Validation failed:', error);
            message.error('Please fill in all required fields.');
            setIsSubmitting(false);
          });
      } catch (error) {
        console.error('Validation failed:', error);
        message.error('Please fill in all required fields.');
        setIsSubmitting(false);
      }
    };

    const clearTransaction = () => {
      showConfirmation(
        () => {
          form.resetFields();
          setData(initialData);
          setSupplierId(0);
          setSupplierDisplay('');
          setEditKey(null);
          selectedProductRef.current = null;
        },
        'Clear Transaction',
        'Are you sure you want to clear this transaction?',
        400,
      );
    };

    const columns: TableProps<DataType>['columns'] = [
      {
        title: 'Location',
        dataIndex: 'product_location',
        key: 'product_location',
        render: (text, data) => {
          return (
            <Typography>
              {data.product_text}, Divider {data.divider}, Floor {data.floor}
            </Typography>
          );
        },
      },
      {
        title: 'Product Code',
        dataIndex: 'name',
        key: 'name',
        render: (text) => <a>{text}</a>,
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Unit',
        dataIndex: 'unit',
        key: 'unit',
      },
      {
        title: 'Quantity',
        key: 'quantity',
        dataIndex: 'quantity',
      },
      {
        title: 'Unit Price',
        dataIndex: 'unit_price',
        key: 'unit_price',
        render: (_, record) => <>{formatCurrency(record.unit_price)}</>,
      },
      {
        title: 'Amount',
        key: 'amount',
        dataIndex: 'amount',
        render: (_, record) => <>{formatCurrency(record.amount)}</>,
      },
      {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
          <>
            <Flex gap="small" wrap>
              <Button
                type="primary"
                icon={<EditOutlined />}
                onClick={() => editProduct(record.key)}
              >
                Edit
              </Button>
              <Button
                type="primary"
                icon={<DeleteOutline />}
                onClick={() => deleteProduct(record.key)}
                danger
              >
                Delete
              </Button>
            </Flex>
          </>
        ),
      },
    ];

    const expandedRowRender = (record: DataType) => (
      <Table
        columns={[
          {
            title: 'Serial Numbers',
            dataIndex: 'serial_numbers',
            key: 'serial_numbers',
            render: (_, { serial_numbers }) => (
              <>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {serial_numbers.map((number, index) => (
                    <Tag key={index} style={{ margin: '4px', minWidth: '50px', maxWidth: '300px' }}>
                      {number.value}
                    </Tag>
                  ))}
                </div>
              </>
            ),
          },
        ]}
        dataSource={[record]}
        pagination={false}
        rowKey="key"
      />
    );

    const multiInputRef = useRef<{
      getTags: () => TagItem[];
      setTags: (tags: TagItem[]) => void;
      clearTags: () => void;
    }>(null);

    const { totalAmount } = calculateTotals();

    const [supplierOptions, setSupplierOptions] = useState<SupplierOption[]>([]);

    const fetchSupplierOptions = async (searchText: string) => {
      try {
        const response = await axiosClient.get('/suppliers', {
          params: { globalFilter: searchText, per_page: 10 },
        });
        const suppliers = response.data.data.map((supplier: Supplier) => ({
          value: supplier.id,
          label: `${supplier.code} - ${supplier.name}`,
          supplier,
        }));
        setSupplierOptions(suppliers);
      } catch (error) {
        console.error('Failed to fetch supplier options:', error);
      }
    };

    const debouncedFetchSupplierOptions = useCallback(
      _.debounce((searchText: string) => {
        fetchSupplierOptions(searchText);
      }, 300), // Adjust the delay (in milliseconds) as needed
      [],
    );

    const onSupplierCodeChange = (searchText: string) => {
      setSupplierDisplay(searchText);
      debouncedFetchSupplierOptions(searchText);
    };

    const [productDisplay, setProductDisplay] = useState<string>('');
    const [locationDisplay, setLocationDisplay] = useState<string>('');
    const [locationText, setLocationText] = useState<string>('');
    const validateProduct = (_: any, value: any) => {
      if (!productOptions.find((option) => option.product.name === value)) {
        return Promise.reject();
      }
      return Promise.resolve();
    };
    const validateLocation = (_: any, value: any) => {
      if (!locationOptions.find((option) => option.location.name === value)) {
        return Promise.reject();
      }
      return Promise.resolve();
    };
    const validateSupplier = (_: any, value: any) => {
      if (!supplierOptions.find((option) => option.label === value)) {
        return Promise.reject();
      }
      return Promise.resolve();
    };

    const onDateChange = (date: any, dateString: string | string[]) => {
      form.setFieldsValue({ purchase_date: date });
    };

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [selectedImage, setSelectedImage] = useState('');
    const [transactionImage, setTransactionImage] = useState<UploadFile[]>([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    const getBase64 = (file: File): Promise<string> =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
      });

    const handlePreview = async (file: UploadFile) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as File);
      }
      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
    };

    const handleProductImage: UploadProps['onChange'] = ({ fileList: newFileList }) => {
      console.log(newFileList);
      setFileList(newFileList);
    };

    const handleTransactionFile: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
      const compressedFileList: UploadFile[] = await Promise.all(
        newFileList.map(async (file) => {
          if (file.originFileObj && file.originFileObj.type.startsWith('image/')) {
            try {
              const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
              };
              const compressedFile = await imageCompression(file.originFileObj, options);
              const newFile = new File([compressedFile], file.name, {
                type: compressedFile.type,
                lastModified: new Date().getTime(),
              }) as RcFile;

              return {
                ...file,
                originFileObj: newFile,
              };
            } catch (error) {
              console.error('Error compressing image:', error);
              message.error('Error compressing image. Please try again.');
              return file;
            }
          }
          return file;
        }),
      );
      setTransactionImage(compressedFileList);
    };

    const uploadButton = (
      <button style={{ border: 0, background: 'none' }} type="button">
        <Add />
        <div style={{ marginTop: 8 }}>Upload</div>
      </button>
    );
    const [timeSpan, setTimeSpan] = React.useState('month');
    const [floor, setFloor] = React.useState('1');
    const [hasSerial, setHasSerial] = useState(false);
    const handleTimeSpanChange = (event: SelectChangeEvent) => {
      setTimeSpan(event.target.value as string);
    };

    const customizer = useSelector((state: AppState) => state.customizer);
    const currentTheme = customizer.activeMode;

    const [isDarkMode, setIsDarkMode] = useState(false);
    const { defaultAlgorithm, darkAlgorithm } = theme;
    useEffect(() => {
      setIsDarkMode(currentTheme === 'dark');
    }, [currentTheme]);

    const [activeStep, setActiveStep] = useState<number>(0);

    const handleStepClick = (index: number) => () => {
      setActiveStep(index);
    };

    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
      setActiveStep(0);
    };

    const steps = ['Transaction Details', 'Product Details'];

    function onChangeFinalize(e: any) {
      const checked = e.target.checked;
      setIsCheckedFinalize(checked);
    }

    return (
      <ConfigProvider
        theme={{
          algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
        }}
      >
        <Backdrop
          sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
          open={isSubmitting}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Form layout="vertical" form={form} onValuesChange={onValuesChange}>
          <Row gutter={16}>
            <Col sm={24} md={12} lg={8}>
              <Box sx={{ width: '100%' }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label, index) => (
                    <Step key={label} onClick={handleStepClick(index)} sx={{ cursor: 'pointer' }}>
                      <StepLabel optional={<Typography variant="caption"></Typography>}>
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>

                <Paper
                  elevation={3}
                  sx={{
                    padding: 3,
                    marginTop: 3,
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    border: 'none',
                    backgroundColor: isDarkMode ? '#1e1e1e' : '#ffffff',
                    color: isDarkMode ? '#ffffff' : '#000000',
                  }}
                >
                  <Row gutter={8} style={{ display: activeStep === 1 ? 'none' : 'flex' }}>
                    <Col span={24}>
                      <h4
                        style={{
                          borderBottom: '1px solid #e8e8e8',
                          paddingBottom: '8px',
                          marginBottom: '16px',
                          fontWeight: 'normal',
                          fontSize: '18px',
                          color: isDarkMode ? '#ffffff' : '#606060',
                        }}
                      >
                        Transaction Details
                      </h4>
                    </Col>

                    <Col span={18}>
                      <Row gutter={8}>
                        <Col xs={24} sm={24} lg={12}>
                          <Form.Item
                            label={
                              <CustomFormLabel htmlFor="reference_number">
                                <span style={{ color: isDarkMode ? '#ffffff' : '#000000' }}>
                                  Reference Number
                                </span>
                              </CustomFormLabel>
                            }
                            name="reference_number"
                            rules={[
                              { required: false, message: 'Please enter the reference number' },
                            ]}
                          >
                            <Input
                              id="reference_number"
                              placeholder="Reference Number is system generated"
                              disabled
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} lg={12}>
                          <Form.Item
                            label={
                              <CustomFormLabel htmlFor="purchase_date">
                                <span style={{ color: isDarkMode ? '#ffffff' : '#000000' }}>
                                  Date of Purchase
                                </span>
                              </CustomFormLabel>
                            }
                            name="purchase_date"
                            rules={[
                              { required: true, message: 'Please enter the date of purchase' },
                            ]}
                          >
                            <ConfigProvider locale={locale}>
                              <DatePicker
                                disabled
                                format="YYYY-MM-DD"
                                onChange={onDateChange}
                                defaultValue={dayjs()}
                              />
                            </ConfigProvider>
                          </Form.Item>
                        </Col>

                        <Col xs={12} sm={12} lg={12}>
                          <Form.Item
                            label={
                              <CustomFormLabel htmlFor="invoice_number">
                                <span style={{ color: isDarkMode ? '#ffffff' : '#000000' }}>
                                  Invoice Number
                                </span>
                              </CustomFormLabel>
                            }
                            name="invoice_number"
                            rules={[
                              { required: true, message: 'Please enter the invoice number' },
                              {
                                pattern: /^[a-zA-Z0-9-]*$/,
                                message:
                                  'Invoice Number: Only alphanumeric characters and hyphen (-) are allowed',
                              },
                            ]}
                          >
                            <Input id="invoice_number" placeholder="Enter Invoice Number" />
                          </Form.Item>
                        </Col>

                        <Col xs={12} sm={12} lg={12}>
                          <Form.Item
                            label={
                              <CustomFormLabel htmlFor="po_number">
                                <span style={{ color: isDarkMode ? '#ffffff' : '#000000' }}>
                                  PO[Purchase Order] Number
                                </span>
                              </CustomFormLabel>
                            }
                            name="po_number"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter the PO[Purchase Order] number',
                              },
                              {
                                pattern: /^[a-zA-Z0-9-]*$/,
                                message:
                                  'PO Number: Only alphanumeric characters and hyphen (-) are allowed',
                              },
                            ]}
                          >
                            <Input id="po_number" placeholder="Enter PO[Purchase Order] Number" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={24} sm={24} lg={6}>
                      <Form.Item
                        name="transaction_image"
                        rules={[
                          {
                            required: selectedTransaction ? false : true,
                            message: 'Transaction Verification: Please upload at least one image',
                          },
                        ]}
                        valuePropName="transactionImage"
                        style={{ marginBottom: 0 }}
                        getValueFromEvent={(e) => {
                          if (Array.isArray(e)) {
                            return e;
                          }
                          return e && e.fileList;
                        }}
                      >
                        <Upload
                          beforeUpload={() => false}
                          listType="picture-card"
                          fileList={transactionImage}
                          onPreview={handlePreview}
                          onChange={handleTransactionFile}
                        >
                          {transactionImage.length > 0 ? null : uploadButton}
                        </Upload>
                      </Form.Item>

                      {previewImage && (
                        <Image
                          alt=""
                          wrapperStyle={{ display: 'none' }}
                          preview={{
                            visible: previewOpen,
                            onVisibleChange: (visible) => setPreviewOpen(visible),
                            afterOpenChange: (visible) => !visible && setPreviewImage(''),
                          }}
                          src={previewImage}
                        />
                      )}
                    </Col>

                    <Col xs={24} sm={24} lg={24}>
                      <CustomFormLabel>
                        <span style={{ color: isDarkMode ? '#ffffff' : '#000000' }}>
                          Supplier Details
                        </span>
                      </CustomFormLabel>
                      <Row gutter={16} style={{ paddingTop: '8px' }}>
                        <Col xs={24} sm={12} lg={9}>
                          <Form.Item
                            name="supplier_code"
                            rules={[
                              { required: true, message: 'Please enter the supplier code' },
                              { validator: validateSupplier },
                            ]}
                          >
                            <AutoComplete
                              id="supplier_code"
                              value={supplierDisplay}
                              options={supplierOptions.map((option) => ({
                                value: option.label,
                                label: option.label,
                              }))}
                              onSearch={onSupplierCodeChange}
                              onSelect={(label: string, option: any) => {
                                const selectedSupplier = supplierOptions.find(
                                  (opt) => opt.label === label,
                                )?.supplier;
                                if (selectedSupplier) {
                                  setSupplierId(selectedSupplier.id);
                                  form.setFieldsValue({
                                    supplier_name: selectedSupplier.name,
                                    supplier_address: selectedSupplier.address,
                                  });
                                  setSupplierDisplay(label);
                                }
                              }}
                              onChange={(value: string) => {
                                setSupplierDisplay(value);
                                setSupplierId(0);
                              }}
                              placeholder="Search Supplier [code/name]"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={7}>
                          <Form.Item name="supplier_name">
                            <Input id="supplier_name" placeholder="Supplier Name Here" disabled />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={8}>
                          <Form.Item name="supplier_address">
                            <Input
                              id="supplier_address"
                              placeholder="Supplier Address Here"
                              disabled
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row gutter={8} style={{ display: activeStep === 0 ? 'none' : 'flex' }}>
                    <Col span={24}>
                      <div
                        style={{
                          borderBottom: '1px solid #e8e8e8',
                          paddingBottom: '8px',
                          marginBottom: '16px',
                          fontWeight: 'normal',
                          fontSize: '18px',
                          color: isDarkMode ? '#ffffff' : '#606060',
                        }}
                      >
                        <span>Product Details</span>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '-30px',
                          }}
                        >
                          <Button style={{ marginRight: '8px' }} onClick={addOrUpdateProduct}>
                            {editKey ? 'Update Product' : 'Add Product'}
                          </Button>
                          <Button
                            onClick={() =>
                              showConfirmation(
                                () => {
                                  form.resetFields([
                                    'product_name',
                                    'product_unit',
                                    'product_quantity',
                                    'product_unit_price',
                                    'product_amount',
                                    'product_description',
                                    'serial_number',
                                    'upload',
                                    'product_location',
                                    'stock_warranty',
                                    'transaction',
                                  ]);
                                  setSelectedImage('');
                                  multiInputRef.current?.clearTags();
                                  setData([]);
                                  setFileList([]);
                                  setEditKey(null);
                                },
                                'Clear Table',
                                'Are you sure you want to clear the table?',
                                400,
                              )
                            }
                            danger
                          >
                            Clear Table
                          </Button>
                        </div>
                      </div>
                    </Col>

                    <Col xs={24} sm={12} lg={12}>
                      <Form.Item
                        label={
                          <CustomFormLabel htmlFor="product_name">
                            <span style={{ color: isDarkMode ? '#ffffff' : '#000000' }}>
                              Product Name
                            </span>
                          </CustomFormLabel>
                        }
                        name="product_name"
                        rules={[
                          { required: true, message: 'Please enter the product code' },
                          { validator: validateProduct },
                        ]}
                      >
                        <ConfigProvider
                          theme={{
                            components: {
                              Select: {
                                multipleItemHeight: 100,
                                optionLineHeight: 2,
                              },
                            },
                          }}
                        >
                          <AutoComplete
                            value={productDisplay}
                            options={productOptions.map((option) => ({
                              value: option.product.name,
                              label: (
                                <div
                                  style={{
                                    whiteSpace: 'normal', // Allow text wrapping
                                    wordBreak: 'break-word', // Break long words
                                  }}
                                >
                                  {`${option.product.name} [Model: ${option.product.model}] [SKU: ${option.product.sku}] [Brand: ${option.product.brand_name}]`}
                                </div>
                              ),
                            }))}
                            dropdownStyle={{
                              maxWidth: 'none', // Remove width restriction
                              minWidth: '700px', // Set a minimum width
                            }}
                            onSelect={(value: string, option: any) => {
                              const selectedProduct = productOptions.find(
                                (opt) => opt.product.name === value,
                              )?.product;
                              setSelectedImage(selectedProduct.image);
                              setHasSerial(selectedProduct.has_serial);

                              if (selectedProduct) {
                                setProductId(selectedProduct.id);
                                form.setFieldsValue({
                                  product_name: selectedProduct.name,
                                  // product_location: `${selectedProduct.location}, ${selectedProduct.floor}`,
                                  product_description: selectedProduct.description,
                                  product_unit: selectedProduct.unit,
                                  product_unit_price: selectedProduct.selling_price,
                                });
                                multiInputRef.current?.clearTags();
                                form.resetFields(['serial_numbers', 'stock_warranty']);
                                setSerialNumberTextDisabled(selectedProduct.has_serial === 0);
                                setProductDisplay(selectedProduct.name);
                              }
                            }}
                            onChange={(value: string) => {
                              setProductDisplay(value);
                              setProductId('');
                            }}
                            onSearch={onProductCodeChange}
                            placeholder="Search Product [name/description/sku/part number]"
                            popupMatchSelectWidth={true}
                          />
                        </ConfigProvider>
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} lg={6}>
                      <Form.Item
                        label={
                          <CustomFormLabel htmlFor="product_unit">
                            <span style={{ color: isDarkMode ? '#ffffff' : '#000000' }}>Unit</span>
                          </CustomFormLabel>
                        }
                        name="product_unit"
                        rules={[{ required: true, message: 'Please enter the unit' }]}
                      >
                        <Input id="product_unit" placeholder="Unit [KG/PCS/M]" disabled />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} lg={6} style={{ alignSelf: 'center' }}>
                      <Upload
                        beforeUpload={() => false}
                        listType="picture-card"
                        disabled
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleProductImage}
                      >
                        <Image
                          width={'auto'}
                          height={'100%'}
                          alt=""
                          style={{ borderRadius: '8px' }}
                          src={selectedImage}
                          preview={{
                            width: 'auto',
                            src: selectedImage,
                          }}
                        />
                      </Upload>
                    </Col>

                    <Col xs={24} sm={12} lg={12}>
                      <Form.Item
                        label={
                          <CustomFormLabel htmlFor="product_quantity">
                            <span style={{ color: isDarkMode ? '#ffffff' : '#000000' }}>
                              Quantity
                            </span>
                          </CustomFormLabel>
                        }
                        name="product_quantity"
                        rules={[{ required: true, message: 'Please enter the quantity' }]}
                      >
                        <InputNumber
                          id="product_quantity"
                          placeholder="0"
                          formatter={(value) => String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => Number(value?.replace(/\$\s?|(,*)/g, ''))}
                          controls={false}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} lg={6}>
                      <Form.Item
                        label={
                          <CustomFormLabel htmlFor="product_unit_price">
                            <span style={{ color: isDarkMode ? '#ffffff' : '#000000' }}>
                              Unit Price
                            </span>
                          </CustomFormLabel>
                        }
                        name="product_unit_price"
                        rules={[{ required: true, message: 'Please enter the unit price' }]}
                      >
                        <Input id="product_unit_price" placeholder="0" />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} lg={6}>
                      <Form.Item
                        label={
                          <CustomFormLabel htmlFor="product_amount">
                            <span style={{ color: isDarkMode ? '#ffffff' : '#000000' }}>
                              Amount
                            </span>
                          </CustomFormLabel>
                        }
                        name="product_amount"
                        rules={[{ required: false, message: 'Please enter the amount' }]}
                      >
                        <Input id="product_amount" placeholder="0" disabled />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} lg={24}>
                      <Form.Item
                        label={
                          <CustomFormLabel htmlFor="serial_number">
                            <span style={{ color: isDarkMode ? '#ffffff' : '#000000' }}>
                              Serial Number
                            </span>
                          </CustomFormLabel>
                        }
                        name="serial_number"
                        rules={[{ required: false, message: 'Please enter the serial number' }]}
                      >
                        <CustomMultiInput
                          maximumTags={maximumTags}
                          disabled={serialNumberTextDisabled}
                          ref={multiInputRef}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} lg={10}>
                      <Form.Item
                        label={
                          <CustomFormLabel htmlFor="stock_warranty">
                            <span style={{ color: isDarkMode ? '#ffffff' : '#000000' }}>
                              Warranty
                            </span>
                          </CustomFormLabel>
                        }
                        rules={[{ required: hasSerial ? true : false, message: 'Please warranty' }]}
                        name="stock_warranty"
                      >
                        <TextField
                          label="Warranty"
                          id="outlined-start-adornment"
                          fullWidth
                          disabled={hasSerial ? false : true}
                          size="small"
                          sx={{
                            '& .MuiInputBase-root': {
                              paddingRight: 0,
                            },
                          }}
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={timeSpan}
                                  size="small"
                                  label="Age"
                                  sx={{ backgroundColor: 'grey.300' }}
                                  onChange={handleTimeSpanChange}
                                >
                                  <MenuItem value={'day'}>Days</MenuItem>
                                  <MenuItem value={'month'}>Months</MenuItem>
                                  <MenuItem value={'year'}>Years</MenuItem>
                                </Select>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} lg={14}>
                      <Form.Item
                        label={
                          <CustomFormLabel htmlFor="product_description">
                            <span style={{ color: isDarkMode ? '#ffffff' : '#000000' }}>
                              Product Description
                            </span>
                          </CustomFormLabel>
                        }
                        name="product_description"
                        rules={[
                          { required: true, message: 'Please enter the product description' },
                        ]}
                      >
                        <Input
                          id="product_description"
                          placeholder="Product description will be displayed here..."
                          disabled
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} lg={12}>
                      <Form.Item
                        label={
                          <CustomFormLabel htmlFor="product_location">
                            <span style={{ color: isDarkMode ? '#ffffff' : '#000000' }}>
                              Location
                            </span>
                          </CustomFormLabel>
                        }
                        rules={[
                          { required: true, message: 'Please enter the product location' },
                          { validator: validateLocation },
                        ]}
                        name="product_location"
                      >
                        <AutoComplete
                          value={locationDisplay}
                          options={locationOptions.map((option) => ({
                            key: option.location.id,
                            value: option.location.name,
                            label: option.location.name,
                          }))}
                          onSelect={(value: string, option: any) => {
                            const selectedLocation = locationOptions.find(
                              (opt) => opt.location.name === value,
                            )?.location;

                            if (selectedLocation) {
                              console.log(option.value);
                              console.log(option);

                              setLocationText(option.value);
                              setLocationDisplay(option.key);
                            }
                          }}
                          onChange={(value: string, option: any) => {
                            console.log(option.value);
                            setLocationText(option.value);
                            setLocationDisplay(option.key);
                          }}
                          onSearch={onLocationsChange}
                          placeholder="Search Location"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} lg={6}>
                      <Form.Item
                        name={'divider'}
                        label={
                          <CustomFormLabel htmlFor="divider">
                            <span style={{ color: isDarkMode ? '#ffffff' : '#000000' }}>
                              Divider
                            </span>
                          </CustomFormLabel>
                        }
                        rules={[{ required: true, message: 'Input Divider' }]}
                      >
                        <AntDSelect placeholder="Select Divider" defaultValue={'A'}>
                          <AntDSelect.Option value="A">A</AntDSelect.Option>
                          <AntDSelect.Option value="B">B</AntDSelect.Option>
                          <AntDSelect.Option value="C">C</AntDSelect.Option>
                          <AntDSelect.Option value="D">D</AntDSelect.Option>
                          <AntDSelect.Option value="E">E</AntDSelect.Option>
                        </AntDSelect>
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} lg={6}>
                      <Form.Item
                        name={'floor'}
                        label={
                          <CustomFormLabel htmlFor="floor">
                            <span style={{ color: isDarkMode ? '#ffffff' : '#000000' }}>Floor</span>
                          </CustomFormLabel>
                        }
                        rules={[{ required: true, message: 'Input Floor' }]}
                      >
                        <AntDSelect placeholder="Select Floor" defaultValue={'1'}>
                          {Array.from({ length: 5 }).map((_, index) => (
                            <AntDSelect.Option value={index + 1} key={index}>
                              {index + 1}
                            </AntDSelect.Option>
                          ))}
                        </AntDSelect>
                      </Form.Item>
                    </Col>
                  </Row>

                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        onChange={onChangeFinalize}
                        checked={isCheckedFinalize}
                        style={{ fontSize: '16px', fontWeight: 'bold' }}
                        color="primary"
                        inputProps={{ 'aria-label': 'checkbox with default color' }}
                      />
                    }
                    label="Is Finalized"
                  />


                </Paper>
              </Box>
            </Col>

            <Col sm={24} md={12} lg={16}>
              <Table
                style={{
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  border: 'none',
                }}
                columns={columns}
                dataSource={data}
                pagination={false}
                expandable={{
                  expandedRowRender,
                  rowExpandable: (record) => record.serial_numbers.length > 0,
                }}
                footer={() => (
                  <div style={{ textAlign: 'right', paddingRight: '16px' }}>
                    <b>Total Amount:</b> ₱ {totalAmount}
                  </div>
                )}
              />
            </Col>
          </Row>
        </Form>
      </ConfigProvider>
    );
  },
);

export default PurchaseEntryForm;
